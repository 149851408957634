import { Typography, notification } from "antd";
import { ApiErrorResponse } from "../domain/valueObjects/ApiErrorResponse";

type NotiType = "success" | "info" | "warning" | "error";

interface NotificationOptions {
  duration?: number;
}

export function showNotifications(
  message: React.ReactNode,
  type: NotiType = "success",
  options: NotificationOptions = {}
): void {
  const { duration } = options;

  notification.destroy();
  notification[type]({
    message,
    placement: "bottomLeft",
    duration
  });
}

export function showApiErrorNotification(error: ApiErrorResponse) {
  showNotifications(
    <>
      <Typography.Title level={5}>Error in creating attribute</Typography.Title>
      <i>Code: {error.code ?? error.statusCode}</i>
      <p>
        {!!error.message && (
          <>
            <>Message: </>
            <>{error.message}</>
          </>
        )}
      </p>
      <p>
        {!!error.reason && (
          <>
            <>Reason: </>
            <>{error.reason}</>
          </>
        )}
      </p>
      <p>
        {!!error.errors && (
          <>
            <>Errors: </>
            <ul>
              {Object.entries(error.errors).map(([key, error], i) => (
                <li key={i}>
                  {key}: {error as string}
                </li>
              ))}
            </ul>
          </>
        )}
      </p>
      <p>
        {!!error.note && (
          <>
            <>Note: </>
            <>{error.note}</>
          </>
        )}
      </p>
    </>,
    "error"
  );
}
