import { Switch } from "antd";
import { useEffect } from "react";
import EditorViewMode from "@pm/ui/atoms/EditorViewMode";
import EditorEditMode from "@pm/ui/atoms/EditorEditMode";
import { t, tMap } from "../../../../../features/i18n/translation";
import BaseEditorProps from "@pm/models/BaseEditorProps";
import getEditerName from "@pm/hooks/getEditerName";

// Use when: Input type = Toggle

interface Props extends BaseEditorProps {
  value?: boolean;
  defaultValue?: boolean;
}

export const ToggleEditor: React.FC<Props> = (props): JSX.Element => {
  const { isEdit, value, defaultValue, isRequired, form, formItemName } = props;
  const translatedName = getEditerName(props);

  useEffect(() => {
    let formValue = value ?? defaultValue;
    if (isRequired && !formValue) {
      formValue = false;
    }

    form.setFieldValue(formItemName, formValue);
  }, [value, isEdit]);

  if (!isEdit) {
    return (
      <EditorViewMode>
        <div className="name">{translatedName}</div>
        <div>{value ? t(tMap["common.yes"]) : t(tMap["common.no"])}</div>
      </EditorViewMode>
    );
  }

  return (
    <EditorEditMode
      name={formItemName}
      label={translatedName}
      labelCol={{ span: 24 }}
    >
      <Switch />
    </EditorEditMode>
  );
};

export default ToggleEditor;
