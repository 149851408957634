import { SetStateAction, atom, useAtom } from "jotai";
import { attributesDebugFlags } from "../../common/helpers/debugging/attributesDebugFlags";

const isEditingAtom = atom(attributesDebugFlags.edition.isEditMode);
/** When turning tree into flat, keyPath is used for mapping back to tree */
const selectedKeyPathAtom = atom("");

type AttributeButtonKinds = "publish" | "saveDraft" | "deleteDraft" | null;
const lastButtonClickAtom = atom<AttributeButtonKinds>(null);

type SetAtom<Args extends any[], Result> = (...args: Args) => Result;
type UiStoreReturn = {
  isEditing: boolean;
  setIsEditing: SetAtom<[SetStateAction<boolean>], void>;
  selectedKeyPath: string;
  setSelectedKeyPath: SetAtom<[SetStateAction<string>], void>;
  lastButtonClick: AttributeButtonKinds;
  setLastButtonClick: SetAtom<[SetStateAction<AttributeButtonKinds>], void>;
};

export const useAttributesUiStore = (): UiStoreReturn => {
  const [isEditing, setIsEditing] = useAtom(isEditingAtom);
  const [selectedKeyPath, setSelectedKeyPath] = useAtom(selectedKeyPathAtom);
  const [lastButtonClick, setLastButtonClick] = useAtom(lastButtonClickAtom);

  const result: UiStoreReturn = {
    isEditing,
    setIsEditing,
    selectedKeyPath,
    setSelectedKeyPath,
    lastButtonClick,
    setLastButtonClick
  };
  return result;
};
