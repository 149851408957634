import { t, tMap } from "../../../../features/i18n/translation";

export const settingsFields = () => ({
  isSystemAttribute: t(tMap["common.system"]),
  isMasterAttribute: t(tMap["common.attributeType"]),
  isLocaleDependent: t(tMap["attributeTable.header.valueByLocales"]),
  isChannelDependent: t(tMap["attributeTable.header.valueByChannels"]),
  isNullable: t(tMap["common.required"]),

  // first
  inputType: t(tMap["attributeDetails.inputType"]),
  dataType: t(tMap["common.dataType"]),
  isMultipleValues: t(
    tMap["attributeDetails.inputTypes.simpleField.isMultipleValues"]
  ),

  // second
  description: t(tMap["common.description"]),

  // third
  validation: t(tMap["common.validation"]),
  default: t(tMap["attributeDetails.settings.editConfig.defaultValue"]),
  isUnique: t(tMap["attributeDetails.inputTypes.simpleField.isUnique"]),
  searchable: t(tMap["common.searchable"]),
  selectionType: t(tMap["attributeDetails.inputTypes.selection.selectionType"]),
  selectionLook: t(tMap["attributeDetails.inputTypes.selection.selectionLook"]),
  childInputLayout: t(
    tMap["attributeDetails.inputTypes.form.childInputLayout"]
  ),

  // forth
  minCharacter: t(tMap["common.minCharacter"]),
  maxCharacter: t(tMap["common.maxCharacter"]),
  validFrom: t(tMap["common.validFrom"]),
  validTo: t(tMap["common.validTo"]),
  minValue: t(tMap["common.minValue"]),
  maxValue: t(tMap["common.maxValue"]),
  numberType: t(tMap["common.numberType"]),
  minSize: t(tMap["common.minSize"]),
  maxSize: t(tMap["common.maxSize"]),
  allowedFileTypes: t(tMap["common.validFileType"]),

  // text
  minLength: t(tMap["common.minCharacter"]),
  maxLength: t(tMap["common.maxCharacter"]),
  pattern: t(tMap["attributeDetails.settings.editConfig.textRegex"])
});

export type SettingFieldKeys = keyof ReturnType<typeof settingsFields>;

/**
 * Conventiont function to get autocompletions for settings fields.
 * Ensures field is always correctly spelled
 *
 * @example
 * getSettingField("inputType") // returns "inputType"
 */
export function getSettingField(fieldName: SettingFieldKeys): string {
  return fieldName;
}
