import { RouteObject } from "react-router-dom";
import { ProductsListController } from "@pm/ui/pages/productsListPage/ProductsListPageController";
import { ProductDetailPage } from "@pm/ui/pages/productDetailsPage";

export const productRouteConstants = {
  productList: "products",
  productDetails: "products/:id"
};
// https://reactrouter.com/en/main/route/route
// ONLY use "Component" in RouteObject if using "RouterProvider". Otherwise, use "element"
// Here we are using "RouterProvider" in src/pim-frontend/src/main.tsx
// We are not using element because we dont want this file to be a .tsx file so we know at a glance that it doesnt contain an Component
export const productRoutes: RouteObject[] = [
  {
    path: productRouteConstants.productList,
    Component: ProductsListController
  },
  {
    path: productRouteConstants.productDetails,
    Component: ProductDetailPage
  }
];
