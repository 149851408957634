import { InputNumber, Select } from "antd";
import { settingsFields } from "../../../../../common/businessLogic/settingsFields";
import { AttributeEntity } from "../../../../../models/entities/attributeEntity";
import { AttributeDataTypeEnum } from "../../../../../models/enums/attributeDataTypeEnum";
import { AttributeTemplate } from "../../../../../models/valueObjects/attributeTemplate";
import { t, tMap } from "../../../../../../../features/i18n/translation";
import { FormColumn } from "../formColumnType";
import { resolveDateTimeInput } from "./resolveDateTimeInput";
import { AttributeInputTypeEnum } from "../../../../../models/enums/attributeInputTypeEnum";
import { numberTypeUiMapping } from "../../../../../common/helpers/attributeUiMappings";
import { numberTypeSelectRender } from "../inputTypeRenders/numberTypeSelectRender";

export const resolveSimpleTypeLastRows = (
  attribute: AttributeTemplate | AttributeEntity
) => {
  const last2Row: FormColumn[] = [];
  let lastRow: FormColumn[] = [
    {
      key: "default",
      label: settingsFields().default,
      value: attribute.default,
      hide: !!attribute.dataType
    }
  ];

  if (attribute.inputType === AttributeInputTypeEnum.selection) {
    lastRow = [];
  }

  switch (attribute.dataType) {
    case AttributeDataTypeEnum.text:
      if (attribute.inputType !== AttributeInputTypeEnum.selection) {
        last2Row.push(
          {
            key: ["dataSettings", "pattern"],
            label: settingsFields().pattern,
            value: attribute.dataSettings?.pattern,
            // span: 12,
            hide: !!attribute.dataType
          }
          // {
          //   key: ["dataSettings", "minLength"],
          //   label: settingsFields().minLength,
          //   value: attribute.dataSettings?.minLength ?? t(tMap["common.na"]),
          //   span: 6,
          //   hide: !!attribute.dataType,
          //   render: () => {
          //     return <InputNumber />;
          //   },
          // },
          // {
          //   key: ["dataSettings", "maxLength"],
          //   label: settingsFields().maxLength,
          //   value: attribute.dataSettings?.maxLength ?? t(tMap["common.na"]),
          //   span: 6,
          //   hide: !!attribute.dataType,
          //   render: () => {
          //     return <InputNumber />;
          //   },
          // }
        );
      }
      break;

    case AttributeDataTypeEnum.number:
      last2Row.push(
        {
          key: ["dataSettings", "numberType"],
          label: settingsFields().numberType,
          hide: !!attribute,
          value:
            numberTypeUiMapping()[
              attribute.dataSettings?.numberType ?? "rounded"
            ]?.label,
          ...numberTypeSelectRender
        }
        // {
        //   key: "minValue",
        //   label: settingsFields().minValue,
        //   value: null,
        //   hide: !!attribute.dataType,
        //   span: 6,
        // },
        // {
        //   key: "maxValue",
        //   label: settingsFields().maxValue,
        //   value: null,
        //   hide: !!attribute.dataType,
        //   span: 6,
        // }
      );
      lastRow = [];
      break;

    case AttributeDataTypeEnum.dateTime:
    case AttributeDataTypeEnum.date:
    case AttributeDataTypeEnum.time:
      lastRow = [
        {
          key: "default",
          label: settingsFields().default,
          value: null,
          hide: !!attribute.dataType,
          span: 12,
          render: (value) => {
            return resolveDateTimeInput(attribute.dataType, value);
          }
        },
        {
          key: "validFrom",
          label: settingsFields().validFrom,
          value: null,
          hide: !!attribute.dataType,
          span: 6,
          render: (value) => {
            return resolveDateTimeInput(attribute.dataType, value);
          }
        },
        {
          key: "validTo",
          label: settingsFields().validTo,
          value: null,
          hide: !!attribute.dataType,
          span: 6,
          render: (value) => {
            return resolveDateTimeInput(attribute.dataType, value);
          }
        }
      ];
      break;

    case AttributeDataTypeEnum.file: {
      const createOptions = (items: string[] | undefined | null) => {
        const fileTypeOptions =
          items?.map((type) => ({
            value: type,
            label: type
          })) ?? [];
        return fileTypeOptions;
      };

      last2Row.push({
        key: ["inputSettings", "allowedFileTypes"],
        label: settingsFields().allowedFileTypes,
        value:
          attribute.inputSettings?.allowedFileTypes &&
          attribute.inputSettings.allowedFileTypes.length
            ? attribute.inputSettings.allowedFileTypes.join(", ")
            : t(tMap["common.na"]),
        hide: !!attribute.dataType,
        span: 24,
        render: (value) => (
          <Select
            mode="tags"
            placeholder={t(
              tMap["attributeDetails.inputSettings.fileTypes.placeholder"]
            )}
            options={createOptions(attribute?.inputSettings?.allowedFileTypes)}
            tokenSeparators={[","]}
            defaultValue={
              attribute?.inputSettings?.allowedFileTypes ?? undefined
            }
          ></Select>
        )
      });
      lastRow = [
        // {
        //   key: "minSize",
        //   label: settingsFields().minSize,
        //   value: null,
        //   hide: !!attribute.dataType,
        //   span: 12,
        //   render: () => {
        //     return <InputNumber />;
        //   },
        // },
        // {
        //   key: "maxSize",
        //   label: settingsFields().maxSize,
        //   value: null,
        //   hide: !!attribute.dataType,
        //   span: 12,
        //   render: () => {
        //     return <InputNumber />;
        //   },
        // },
      ];
      break;
    }
    default:
      break;
  }

  return last2Row.length ? [last2Row, lastRow] : [lastRow];
};
