import { AttributeTemplate } from "@am/models/valueObjects/attributeTemplate";
import getEditerName from "@pm/hooks/getEditerName";
import { InputLocaleDependent } from "@pm/ui/organisms/dataTypeEditors/InputLocaleDependent";
import { FormInstance, Form } from "antd";
import React from "react";
import { t, tMap } from "../../../../features/i18n/translation";
import { AinIcon } from "../../atoms/AinIcon";
import { getSettingType } from "./buildFormStructure";
import {
  StyledNestedObjectFormItem,
  StyledFormItemContainer,
  StyledDeleteIcon,
  StyledTypographyTitle,
  StyledArrayFormItem,
  StyledObjectFormItem
} from "./NestedForm.styles";
import { getJsType } from "./getJsType";
import { AttributeDataTypeEnum } from "@am/models/enums/attributeDataTypeEnum";
import { AttributeInputTypeEnum } from "@am/models/enums/attributeInputTypeEnum";
import DropdownSelection from "@pm/ui/organisms/dataTypeEditors/DropdownSelection";
import FileUploader from "@pm/ui/organisms/dataTypeEditors/FileUploader";
import SingleNumber from "@pm/ui/organisms/dataTypeEditors/SingleNumber";
import SingleText from "@pm/ui/organisms/dataTypeEditors/SingleText";
import SingleTextarea from "@pm/ui/organisms/dataTypeEditors/SingleTextarea";
import ToggleEditor from "@pm/ui/organisms/dataTypeEditors/ToggleEditor";
import {
  isLabelGuard,
  isLabelGuardArray
} from "../../../domain/valueObjects/Label";
import BaseEditorProps from "@pm/models/BaseEditorProps";
import { isMultipleValuesTemplate } from "@am/common/businessLogic/isMultipleValuesTemplate";
import { CategoryAttribute } from "@cm/domain/valueObjects/categoryAttribute";
import { StyledAddButton } from "./NestedFormItem.styles";

type Obj = Record<string, any>;
type DataType = string | number | boolean | Obj | Array<Obj>;
type NestedFormItemProps = {
  form: FormInstance<any>;
  name: string;
  parentNames: string[];
  locale: string | undefined;
  data: DataType | null;
  path: string[];
  onAdd: (...args: any[]) => void;
  template: AttributeTemplate | null;
  setting: CategoryAttribute | null;
  /** Pass down info to child, if the recursive parent was an array */
  renderAsFormItem?: boolean;
  templateForItem?: AttributeTemplate | null;
  /**
   * Templates can behave differently, based on their parents.
   * Eg. if parent=object, then required setting comes from template.isNullable, and not from category settings.
   */
  parentType?: "object" | "array";
  initialFormItemName?: BaseEditorProps["initialFormItemName"];
  isEdit: boolean;
  after?: React.ReactNode;
};

function renderControlBasedOnSetting(
  name: string,
  finalName: string[],
  options: {
    form: FormInstance<any>;
    template: AttributeTemplate | null;
    setting: CategoryAttribute | null;
    locale: string | undefined;
    isEdit: boolean;
    data: any;
    props: any;
    parentType?: NestedFormItemProps["parentType"];
    hideLabel?: boolean;
  }
) {
  const {
    template,
    setting,
    form,
    locale,
    isEdit,
    data,
    parentType,
    hideLabel,
    props
  } = options;

  let finalValue = data;
  if (Array.isArray(data)) {
    let labelValue = data;
    data.forEach((item) => {
      if (!isLabelGuard(item)) return;
      if (item.locale !== locale) return;
      // @ts-expect-error can be array or string
      labelValue = item.value;
    });
    finalValue = labelValue;
  }

  let isRequired = setting?.isRequired ?? false;
  if (parentType === "object") {
    isRequired = !template?.isNullable ?? false;
  }

  const finalProps = {
    locale,
    isEdit: isEdit,
    name: hideLabel ? [] : template?.labels ?? [],
    attributeName: hideLabel ? "" : name || template?.name,
    options: template?.options ?? [],
    form: form,
    formItemName: finalName,
    value: finalValue,
    data,
    isRequired,
    isMultipleValues: template?.isMultipleValues,
    ...props
  };

  if (template?.isLocaleDependent) {
    /** pass in data, because need it as labels (: Labels[]) array */
    return (
      <InputLocaleDependent {...finalProps} value={data}>
        {renderControl()}
      </InputLocaleDependent>
    );
  }

  return renderControl();

  function renderControl() {
    switch (template?.inputType) {
      case AttributeInputTypeEnum.simple:
        if (template.dataType === AttributeDataTypeEnum.text) {
          return <SingleText {...finalProps} />;
        } else if (template.dataType === AttributeDataTypeEnum.number) {
          return (
            <SingleNumber
              {...finalProps}
              numberType={template.dataSettings?.numberType}
            />
          );
        }
        break;
      case AttributeInputTypeEnum.textArea:
        return <SingleTextarea {...finalProps} />;
      case AttributeInputTypeEnum.toggle:
        return <ToggleEditor {...finalProps} />;
      case AttributeInputTypeEnum.selection:
        return (
          <DropdownSelection
            {...finalProps}
            searchable={template.inputSettings?.searchable}
          />
        );
      case AttributeInputTypeEnum.uploader:
        return <FileUploader {...finalProps} />;
      default:
        return <div>Unsupported type: {template?.inputType}</div>;
    }
  }
}

/**
 * Recursively handle a complex object, and render a form based on the template (aka set of configurations)
 */
export const NestedFormItem: React.FC<NestedFormItemProps> = (
  componentProps
): JSX.Element => {
  const {
    name,
    locale,
    data,
    path,
    form,
    onAdd,
    template,
    setting,
    renderAsFormItem,
    templateForItem,
    parentType,
    isEdit,
    after,
    initialFormItemName,
    ...props
  } = componentProps;
  let { parentNames } = componentProps;
  if (initialFormItemName) parentNames = initialFormItemName;

  let finalName: string[] = [];
  if (parentNames.length > 0) {
    if (isLabelGuardArray(name)) {
      // do nothing
      finalName = parentNames;
    } else if (name) {
      finalName = parentNames.concat(name);
    } else {
      finalName = parentNames;
    }
  } else {
    finalName = [name];
  }

  const { isObject: isObjectTemplate, isArray: isArrayTemplate } =
    getSettingType(template);
  const labelName = getEditerName({
    name: template?.labels,
    attributeName: template?.name,
    locale
  } as any);

  /**
   * 1. If template is both isObject (aka dataType=object), and isArray (aka. isMultipleValues=true),
   * take precedent to isArray, and render objects later.
   * In the isArray step, we render a Form.List, to be able to add and remove items
   */
  if (isArrayTemplate && !renderAsFormItem) {
    /**
     * 1.1 Item check
     * Now check each item in the array
     */
    const arrayTemplates = template?.template ?? [];
    /**
     * 1.2 Initial values mapping
     * Define initial values for the Form.List to render items
     */
    let mapTemplatesToInitialValues;
    if (data) {
      if (Array.isArray(data) && data.length === 0) {
        mapTemplatesToInitialValues = [null];
      } else {
        mapTemplatesToInitialValues = data as DataType[];
      }
    } else if (arrayTemplates.length === 0) {
      mapTemplatesToInitialValues = [null];
    } else {
      mapTemplatesToInitialValues = arrayTemplates.map((template) => {
        return {
          [template.name]: isMultipleValuesTemplate(template) ? [null] : null
        };
      });
    }

    if (isObjectTemplate && isArrayTemplate) {
      if (!data) {
        /** In this case, the initial value is `[null]`, so we can show just one set of inputs of the form */
        mapTemplatesToInitialValues = [null];
      }
    }

    return (
      <StyledArrayFormItem
        className="StyledArrayFormItem"
        label={
          labelName && (
            <StyledTypographyTitle>{labelName}</StyledTypographyTitle>
          )
        }
        required={setting?.isRequired && isEdit}
      >
        <Form.List name={finalName} initialValue={mapTemplatesToInitialValues}>
          {(fields, { remove }) => {
            return (
              <>
                {fields.map((field, fieldIndex) => {
                  let dataOfItem = data && (data as Obj)[fieldIndex];
                  if (isLabelGuard(data)) {
                    if (Array.isArray(data.value)) {
                      dataOfItem = data.value[fieldIndex];
                    }
                  }

                  const templateOfItem =
                    arrayTemplates && arrayTemplates[fieldIndex];
                  const {
                    isArray: isArrayData,
                    isObject: isObjectData,
                    isPrimitive: isPrimitiveData
                  } = getJsType(dataOfItem);
                  let finalParentPath = [field.name.toString()];
                  let finalTemplate = templateOfItem ? templateOfItem : null;

                  let finalTemplateForItem = template;
                  if (isObjectTemplate) {
                    finalTemplateForItem = finalTemplate;
                  }

                  if (isArrayData) {
                    parentNames = finalName;
                  } else if (isObjectData) {
                    if (isObjectTemplate && template) {
                      finalTemplate = {
                        ...template,
                        isMultipleValues: false,
                        labels: [],
                        name: ""
                      };
                    } else if (templateOfItem?.name) {
                      finalParentPath = finalParentPath.concat(
                        templateOfItem.name
                      );
                    }
                  } else if (isPrimitiveData) {
                    const parentIsArray =
                      parentNames.length === 1 &&
                      Number.isInteger(Number(parentNames[0])); // because of the form `["0"]`
                    parentNames = parentIsArray ? parentNames : finalName;
                  }
                  const finalPath = [...path];
                  if (name && !isLabelGuardArray(name)) {
                    finalPath.push(name);
                  }
                  finalPath.push(...finalParentPath);
                  const isRequired = !template?.isNullable;

                  const shouldRenderAsFormItem = !finalTemplate;

                  return (
                    <React.Fragment key={fieldIndex}>
                      <NestedFormItem
                        form={form}
                        name={""} // leave empty, else duplicates key, due to antd behavior
                        parentNames={finalParentPath}
                        locale={locale}
                        data={dataOfItem}
                        path={finalPath}
                        onAdd={onAdd}
                        template={finalTemplate}
                        setting={setting}
                        renderAsFormItem={shouldRenderAsFormItem}
                        templateForItem={finalTemplateForItem}
                        parentType="array"
                        isEdit={isEdit}
                        after={
                          <StyledDeleteIcon
                            className="StyledDeleteIcon"
                            $alignTop={
                              finalTemplateForItem?.inputType ===
                                AttributeInputTypeEnum.textArea ||
                              finalTemplateForItem?.inputType ===
                                AttributeInputTypeEnum.uploader
                            }
                          >
                            {isEdit && (fields.length > 1 || !isRequired) ? (
                              <AinIcon
                                size={20}
                                icon="delete-outlined-gray"
                                className="delete-icon"
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            ) : null}
                          </StyledDeleteIcon>
                        }
                      ></NestedFormItem>
                    </React.Fragment>
                  );
                })}
                {isEdit ? (
                  <StyledAddButton
                    type="primary"
                    onClick={() => {
                      let pathToObject = path;
                      if (name) {
                        pathToObject = path.concat(name);
                      }
                      onAdd(pathToObject);
                    }}
                  >
                    {t(tMap["common.add"])}
                    {typeof name === "string" ? ` ${name}` : ""}
                  </StyledAddButton>
                ) : null}
              </>
            );
          }}
        </Form.List>
      </StyledArrayFormItem>
    );
  } else if (isObjectTemplate) {
    return (
      <StyledObjectFormItem
        className="StyledObjectFormItem"
        label={
          labelName && (
            <StyledTypographyTitle>{labelName}</StyledTypographyTitle>
          )
        }
      >
        <StyledNestedObjectFormItem className="StyledNestedFormItem">
          {template?.template?.map((templateDefinition, index) => {
            const attributeName = templateDefinition.name;
            let childData = data && (data as Obj)[attributeName];
            if (isLabelGuard(data)) {
              if (Object.keys(data.value ?? {}).length > 0) {
                const valueAsObject = data.value as unknown as Obj;
                childData = valueAsObject[attributeName];
              }
            }

            const { isArray, isObject, isPrimitive } =
              getSettingType(templateDefinition);
            const parentIsArray =
              parentNames.length === 1 &&
              Number.isInteger(Number(parentNames[0])); // because of antd form using numbers as keys for array forms, eg. `["object", "path", "0", "key"]` for the object `{object: {path: [{key: "value"}]}}`
            if (isArray) {
              parentNames = finalName;
            } else if (isObject) {
              parentNames = finalName;
              // do nothing
            } else if (isPrimitive) {
              parentNames = parentIsArray ? parentNames : finalName;
            }
            const finalPath = [...path];
            if (name) {
              finalPath.push(name);
            }
            return (
              <React.Fragment key={index}>
                <NestedFormItem
                  form={form}
                  name={attributeName}
                  parentNames={parentNames}
                  locale={locale}
                  data={childData}
                  path={finalPath}
                  onAdd={onAdd}
                  template={templateDefinition}
                  parentType="object"
                  setting={setting}
                  isEdit={isEdit}
                ></NestedFormItem>
              </React.Fragment>
            );
          })}
        </StyledNestedObjectFormItem>
      </StyledObjectFormItem>
    );
  }

  return (
    <StyledFormItemContainer className="StyledFormItemContainer">
      {renderControlBasedOnSetting(name, finalName, {
        form,
        template: templateForItem ?? template,
        setting,
        locale,
        isEdit,
        parentType,
        data,
        hideLabel: !!templateForItem,
        props
      })}
      {after}
    </StyledFormItemContainer>
  );
};
