import React from "react";
import styled from "styled-components";
import { ProductPublicationLocale } from "./ProductPublicationLocale";
import { ProductPublicationChannel } from "./ProductPublicationChannel";
import { Flex } from "antd";

const Container = styled(Flex)`
  margin: 10px 25px;
`;

export const ProductPublications: React.FC = () => {
  return (
    <Container vertical={false} gap={20}>
      <ProductPublicationLocale/>
      <ProductPublicationChannel/>
    </Container>
  );
};
