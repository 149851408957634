import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { Button, Col, Form, Input, Popover, Row, Space } from "antd";
import "./editLocalsPopover.scss";
import { t, tMap } from "../../../../../features/i18n/translation";
import { Labels } from "../../../../domain/valueObjects/Label";
import { useCommonStore } from "../../../../domain/state/stores/useCommonStore";

interface Props {
  children: React.ReactNode;
  labels?: Labels[];
  isOpen?: boolean;
  isReadOnly?: boolean;
  onConfirm: (data: Labels[]) => void;
  onCancelClicked?: () => void;
}

export const EditLocalsPopover = forwardRef(
  (
    { children, isReadOnly, labels, onConfirm, onCancelClicked }: Props,
    ref
  ) => {
    const [form] = Form.useForm();
    const [isOpenModal, setIsOpen] = useState<boolean>(false);
    const [renderLabels, setRenderLabels] = useState<Labels[]>([]);
    const { ui } = useCommonStore();
    const { locales } = ui;

    const localeUiConfig: Labels[] = locales.map(({ code, name }) => ({
      locale: code,
      value: name
    }));

    useImperativeHandle(ref, () => ({
      setIsOpen
    }));

    useEffect(() => {
      const tempLabels =
        !labels || labels.length === 0
          ? locales.map(({ code }) => ({
              locale: code,
              value: ""
            }))
          : labels;

      form.setFieldValue("labels", tempLabels);
      setRenderLabels(tempLabels);
    }, [labels]);

    const handleOpenChange = (newOpen: boolean) => {
      setIsOpen(newOpen);
    };

    const handleConfirm = async () => {
      const values = await form.validateFields();
      await onConfirm(values.labels);
    };

    const handleCancel = async () => {
      if (onCancelClicked) {
        onCancelClicked();
        return;
      }

      setIsOpen(false);
    };

    return (
      <Popover
        open={isOpenModal}
        onOpenChange={handleOpenChange}
        placement="bottomLeft"
        content={
          <div className="edit-locals-popover">
            <Form
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete="off"
            >
              <Form.List name="labels">
                {(fields) => (
                  <>
                    {fields.map((field, fieldIndex) => {
                      const currentLabel = renderLabels[fieldIndex];
                      const localeConfig = localeUiConfig.find(
                        (x) => x.locale === currentLabel?.locale
                      );
                      if (!localeConfig) return;

                      return (
                        <React.Fragment key={fieldIndex}>
                          <Form.Item
                            label={`${t(tMap["attributeDetails.labelIn"])} ${
                              localeConfig?.value
                            }`}
                            name={[field.name, "value"]}
                            initialValue={currentLabel?.value}
                            className={isReadOnly ? "read-only-label" : ""}
                          >
                            {isReadOnly ? (
                              <span className="locale-text">
                                {currentLabel?.value || "--"}
                              </span>
                            ) : (
                              <Input
                                placeholder={t(
                                  tMap[
                                    "attributeDetails.newProductTemplateName"
                                  ]
                                )}
                              />
                            )}
                          </Form.Item>
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </Form.List>

              {!isReadOnly && (
                <Row justify="end">
                  <Space>
                    <Col>
                      <Button onClick={handleCancel}>
                        {t(tMap["common.cancel"])}
                      </Button>
                    </Col>
                    <Col>
                      <Button type="primary" onClick={handleConfirm}>
                        {t(tMap["common.save"])}
                      </Button>
                    </Col>
                  </Space>
                </Row>
              )}
            </Form>
          </div>
        }
        trigger="click"
      >
        {children}
      </Popover>
    );
  }
);
