import { Select } from "antd";
import { useEffect, useState } from "react";
import EditorViewMode from "@pm/ui/atoms/EditorViewMode";
import EditorEditMode from "@pm/ui/atoms/EditorEditMode";
import { SelectionOption } from "../../../../../common/domain/valueObjects/SelectionOption";
import { DefaultOptionType } from "antd/es/select";
import { Rule } from "antd/es/form";
import { t, tMap } from "../../../../../features/i18n/translation";
import { selectSearchFunction } from "../../../../../common/modules/selectHelper";
import BaseEditorProps from "@pm/models/BaseEditorProps";
import getEditerName from "@pm/hooks/getEditerName";
import { requiredButNoValidation } from "./validators/requiredButNoValidation";

// Use when: Input type = Selection

interface Props extends BaseEditorProps {
  value?: string | string[];
  defaultValue?: string | string[];
  options: SelectionOption[];
  searchable?: boolean;
  isMultipleValues?: boolean;
}

export const DropdownSelection: React.FC<Props> = (props): JSX.Element => {
  const {
    isEdit,
    value,
    defaultValue,
    options,
    isRequired,
    isMultipleValues,
    form,
    formItemName,
    searchable,
    locale
  } = props;
  const translatedName = getEditerName(props);
  const [selectOptions, setSelectOptions] = useState<DefaultOptionType[]>();

  const resolveFinalValue = () => {
    let finalValue = value;
    if (isMultipleValues) {
      if (!Array.isArray(finalValue)) {
        finalValue = [];
      }
    }

    return finalValue;
  };

  useEffect(() => {
    form.setFieldValue(formItemName, resolveFinalValue() ?? defaultValue);
  }, [value, isEdit]);

  useEffect(() => {
    const optionTemp = options?.map<DefaultOptionType>((x) => ({
      value: x.key,
      label: x.displayValues.find((x) => x.locale === locale)?.value || x.key
    }));

    setSelectOptions(optionTemp);
  }, [options, locale]);

  const resolveDisplayValue = () => {
    const finalValue = resolveFinalValue();
    if (typeof finalValue === "string") {
      return selectOptions?.find((x) => x.value === finalValue)?.label;
    }

    if (!Array.isArray(finalValue)) {
      return;
    }

    if (!finalValue.length) {
      return "--";
    }

    return finalValue
      ?.map((v) => selectOptions?.find((x) => x.value === v)?.label)
      ?.join(", ");
  };

  if (!isEdit) {
    return (
      <EditorViewMode>
        <div className="name">{translatedName}</div>
        <div>{resolveDisplayValue() ?? "--"}</div>
      </EditorViewMode>
    );
  }

  const rules: Rule[] = [];
  if (isRequired) {
    rules.push(requiredButNoValidation());
  }

  return (
    <EditorEditMode
      name={formItemName}
      label={translatedName}
      initialValue={defaultValue}
      labelCol={{ span: 24 }}
      rules={rules}
    >
      <Select
        placeholder={t(tMap["common.selectPlaceholder"])}
        options={selectOptions}
        showSearch={searchable}
        mode={isMultipleValues ? "multiple" : undefined}
        filterOption={searchable ? selectSearchFunction : undefined}
      />
    </EditorEditMode>
  );
};

export default DropdownSelection;
