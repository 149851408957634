import { useTestingSetup } from "@shared/helpers/testing/useTestingSetup";
import App from "./App";
import { AppAuthentication } from "./features/authentication/AppAuthentication";

export const AppController: React.FC = (): JSX.Element => {
  useTestingSetup();

  return (
    <AppAuthentication>
      <App />
    </AppAuthentication>
  );
};
