import EditorEditMode from "@pm/ui/atoms/EditorEditMode";
import EditorViewMode from "@pm/ui/atoms/EditorViewMode";
import { Form, Typography } from "antd";
import { styled } from "styled-components";

export const StyledTypographyTitle = styled.div`
  padding: 0;
  margin: 0;
  font-weight: 600;
`;

export const StyledDeleteIcon = styled.div<{ $alignTop?: boolean }>`
  display: flex;
  align-items: ${({ $alignTop }) => ($alignTop ? "baseline" : "center")};
  height: 100%;
  margin-left: 8px;
`;

export const StyledNestedObjectFormItem = styled.div`
  margin-bottom: 24px;
  border: 1px solid #e8e8e8;
  padding: 16px;
`;
export const StyledFormItemContainer = styled.div`
  display: flex;
  padding-bottom: 24px;
`;

export const StyledArrayFormItem = styled(Form.Item)``;
export const StyledObjectFormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0;
    margin-bottom: 8px;
  }
  ${StyledFormItemContainer} {
    padding-bottom: 0;
  }
`;

export const Container = styled.div`
  &.form-view-mode {
    ${StyledArrayFormItem} {
      .ant-form-item-label {
        padding: 0;
      }
    }
  }

  .ant-input {
    width: calc(100% - 30px);
  }

  .ant-form-item {
    margin-bottom: unset;
  }

  ${EditorViewMode} {
    margin-bottom: 8px;
  }

  ${EditorEditMode} {
    flex: 1;
  }
`;
