import { useAttributesEntityStore } from "./attributeEntityStore";
import { useAttributesUiStore } from "./attributeUiStore";

export const useAttributesStore = () => {
  const ui = useAttributesUiStore();
  const entity = useAttributesEntityStore();

  return {
    entity,
    ui
  };
};
