import { generateId } from "../../../../common/modules/debugging/generateId";
import { attributeConstants } from "../constants/constants";

export function getKeysFromKeyPath(keyPath: string | undefined): string[] {
  if (!keyPath) return [];
  return keyPath.split(attributeConstants.keySeparator);
}

export function getKeyLastKey(keyPath: string | undefined): string {
  const keys = getKeysFromKeyPath(keyPath);
  const lastKey = keys[keys.length - 1];
  return lastKey;
}

export function getParentKey(keyPath: string | undefined): string {
  const keys = getKeysFromKeyPath(keyPath);
  const previousKeys = keys.slice(0, keys.length - 1);
  const parentKey = createKeyPath(previousKeys);
  return parentKey;
}

export function createKeyPath(keys: string[]): string {
  return keys.join(attributeConstants.keySeparator);
}

export function isTopLevelKey(keyPath: string): boolean {
  const keys = getKeysFromKeyPath(keyPath);
  const is = keys.length === 1;
  return is;
}

/**
 * aaa|bbb|ccc
 */
export function createTreeKeys(
  parentKey: string,
  name: string | undefined
): string {
  const newId = generateId();
  const key = parentKey
    ? `${parentKey}${attributeConstants.keySeparator}${name ?? newId}`
    : name ?? newId;
  return key;
}
