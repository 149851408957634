import styled from "styled-components";
import { LabelText } from "../atoms/LabelText";

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

// const LabelText = styled(Typography.Text)`
//   font-weight: 600;
// `;

export interface LabelProps {
  labelText: string;
  for: React.ReactElement;
}
export const Label: React.FC<LabelProps> = ({ labelText, for: forElement }) => (
  <LabelContainer>
    <LabelText>{labelText}</LabelText>
    {forElement}
  </LabelContainer>
);
