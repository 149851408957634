import { AttributeDataTypeEnum } from "../../models/enums/attributeDataTypeEnum";
import { AttributeInputTypeEnum } from "../../models/enums/attributeInputTypeEnum";

/**
 * https://ainavio.atlassian.net/wiki/spaces/PATE/pages/245137410/Attribute+management#%F0%9F%93%96-Appendix-A.-Detailed-settings-for-each-input-type
 */
export const dataTypesMapping = {
  [AttributeInputTypeEnum.simple]: [
    /** scope cut down #297 */
    AttributeDataTypeEnum.text,
    // AttributeDataTypeEnum.email,
    // AttributeDataTypeEnum.phone,
    AttributeDataTypeEnum.number
    // AttributeDataTypeEnum.date,
    // AttributeDataTypeEnum.time,
  ],
  [AttributeInputTypeEnum.textArea]: [AttributeDataTypeEnum.text],
  [AttributeInputTypeEnum.toggle]: [AttributeDataTypeEnum.boolean],
  [AttributeInputTypeEnum.uploader]: [AttributeDataTypeEnum.file],
  [AttributeInputTypeEnum.selection]: [
    AttributeDataTypeEnum.text
    /** scoped cut down #295 */
    // AttributeDataTypeEnum.number,
    // AttributeDataTypeEnum.date,
    // AttributeDataTypeEnum.time,
    // AttributeDataTypeEnum.file,
    // AttributeDataTypeEnum.object,
  ],
  [AttributeInputTypeEnum.form]: [AttributeDataTypeEnum.object]
} as const;
