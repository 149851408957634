import { Checkbox, Form, FormInstance } from "antd";
import "./toggleColumnInput.scss";
import { useEffect } from "react";

interface Props {
  value: boolean;
  disabled: boolean;
  form: FormInstance;
  formItemName: string[];
}

export const ToggleColumnInput: React.FC<Props> = ({
  value,
  disabled,
  form,
  formItemName
}): JSX.Element => {
  useEffect(() => {
    form?.setFieldValue(formItemName, value);
  }, [value]);

  return (
    <Form.Item
      className="toggle-column-input"
      name={formItemName}
      valuePropName="checked"
    >
      <Checkbox className="checkbox" disabled={disabled} />
    </Form.Item>
  );
};

export default ToggleColumnInput;
