import { Spin, Tabs, TabsProps } from "antd";
import React, { useCallback, useEffect } from "react";
import { styled } from "styled-components";
import { useParams, useSearchParams } from "react-router-dom";
import { t, tMap } from "../../../../../features/i18n/translation";
import { ProductInformation } from "../../organisms/productInformation/ProductInformation";
import { useAtom, useSetAtom } from "jotai";
import {
  currentProductIdAtom,
  productEntityStateAtom
} from "@pm/state/productManagementEntityState";
import { GeneralInformation } from "@pm/ui/organisms/GeneralInformation";
import { ProductVariants } from "@pm/ui/organisms/productVariants/ProductVariants";
import { ProductPublications } from "@pm/ui/organisms/productPublications/ProductPublications";
import { useUIState } from "@pm/hooks/useUIState";

const Container = styled.section`
  .ant-tabs {
    margin: 0;

    .ant-tabs-nav {
      margin: unset;
      padding: 0 24px;
    }
  }
`;

const LoadingSpin = styled(Spin)`
  display: block;
  text-align: center;
  padding-top: 16px;
`;

type TabKeys = "information" | "variants" | "publications";

const tabs: TabsProps["items"] & { key: TabKeys }[] = [
  {
    key: "information",
    forceRender: true,
    label: t(tMap["common.information"]),
    children: <ProductInformation />
  },
  {
    key: "variants",
    forceRender: true,
    label: t(tMap["common.variants"]),
    children: <ProductVariants />
  },
  {
    key: "publications",
    forceRender: true,
    label: t(tMap["common.publications"]),
    children: <ProductPublications />
  }
];

export const ProductDetailPage: React.FC = () => {
  const { id: productId } = useParams();
  const [, setProductId] = useAtom(currentProductIdAtom);
  const [productLoader] = useAtom(productEntityStateAtom);
  const { isLoading } = productLoader;
  const [searchParams, setSearchParams] = useSearchParams();
  const { setProductVariants, setCategoryList } = useUIState();
  const { setIsFormUpdated } = useUIState();

  const viewParam = searchParams.get("view");

  const changeDetailsView = useCallback(
    (tabKey: string) => {
      setSearchParams({ view: tabKey });
    },
    [setSearchParams]
  );

  useEffect(() => {
    return () => {
      setCategoryList([]);
      setProductVariants([]);
    };
  }, []);

  useEffect(() => {
    if (!productId) {
      return;
    }

    setIsFormUpdated(false);
    setProductId(productId);
  }, [productId]);

  if (isLoading) {
    return <LoadingSpin />;
  }

  return (
    <Container>
      <GeneralInformation />
      <Tabs
        defaultActiveKey={viewParam ?? "information"}
        items={tabs}
        onChange={changeDetailsView}
      />
    </Container>
  );
};
