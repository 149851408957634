import { ImageProps, Image } from "antd";

export interface SquareImageProps extends ImageProps {
  size?: number;
}
export const PreviewImage: React.FC<SquareImageProps> = ({
  size,
  width,
  height,
  ...rest
}) => <Image width={size} height={size} {...rest} />;
