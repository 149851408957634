import { useUIStateReadOnly } from "@pm/hooks/useUIState";
import React, {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useMemo,
  useState
} from "react";
import styled from "styled-components";
import { FlagChip } from "@pm/ui/molecules/FlagChip";
import { langToFlag } from "@pm/mock/flags";
import {
  Button,
  Divider,
  Flex,
  Popconfirm,
  Select,
  Table,
  TableProps
} from "antd";
import { useCommonStore } from "../../../../../common/domain/state/stores/useCommonStore";
import { GetLocaleResponse } from "../../../../../common/domain/valueObjects/GetLocaleResponse";
import { useProductPublicationState } from "@pm/hooks/usePublicationState";

type Locale = GetLocaleResponse;

const Container = styled.div`
  flex: 1;
`;

export const ProductPublicationLocale: React.FC = () => {
  const { isEditMode } = useUIStateReadOnly();

  const { ui } = useCommonStore();
  const { locales } = ui;

  const { publicationState, setLocales } = useProductPublicationState();
  const { locales: productLocales } = publicationState;

  const [selectedLocales, setSelectedLocale] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const localesSelection = useMemo(() => {
    const activeLocales = productLocales.map<string>((x) => x.code) ?? [];
    return locales
      .filter((x) => !activeLocales.includes(x.code))
      .map((x) => ({
        value: x.code,
        label: (
          <Flex vertical={false} align="center" gap={10}>
            <FlagChip flag={langToFlag(x.code as any)} marginTop={0} />
            {x.name}
          </Flex>
        )
      }));
  }, [productLocales]);

  const handleAddLocale = () => {
    const mappedLocale: Locale[] = selectedLocales.flatMap((l) => {
      const locale = locales.find((x) => x.code == l);
      return locale ?? [];
    });
    setLocales([...mappedLocale, ...productLocales]);
    setSelectedLocale([]);
    setShowDropdown(false);
  };

  const handleRemoveLocale = useCallback(
    (code: string) => {
      if (productLocales.length === 1) {
        // this part should not be reached! but to be safe
        alert("Can not remove the last locale!");
        return;
      }
      const ret = productLocales.filter((x) => x.code !== code);
      setLocales(ret);
    },
    [productLocales]
  );

  const optionRenderer = (
    menu: ReactElement<any, string | JSXElementConstructor<any>>
  ) => {
    return (
      <>
        {menu}
        <Divider style={{ margin: "4px 0" }} />
        <Button
          key="add-button"
          type="primary"
          onClick={handleAddLocale}
          style={{ width: "100%" }}
        >
          Add
        </Button>
      </>
    );
  };

  // TODO: add locale to title
  const columns = useMemo(() => {
    const activeLocaleCount = productLocales.length;
    const colmuns: TableProps<Locale>["columns"] = [
      {
        title: "Code",
        key: "locale",
        dataIndex: "code",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.code.localeCompare(b.code)
      },
      {
        title: "Name",
        key: "value",
        dataIndex: "name",
        filters: productLocales.map((x) => ({
          text: x.name,
          value: x.code
        })),
        filterSearch: true,
        onFilter: (value: any, record) => record.code === value,
        render: (_, r) => (
          <Flex align="center" gap={10}>
            <FlagChip marginTop={0} flag={langToFlag(r.code as any)} /> {r.name}
          </Flex>
        )
      }
    ];
    if (isEditMode === true) {
      if (activeLocaleCount === 1) {
        colmuns.push({
          title: "Action",
          key: "action",
          width: 20,
          render: (_, r) => null
        });
      } else {
        colmuns.push({
          title: "Action",
          key: "action",
          width: 20,
          render: (_, r) => (
            <Popconfirm
              style={{maxWidth: 300}}
              title={`Are you sure you want to continue removing ${r.name} locale?`}
              description="All product data, including its variants will be deleted for this locale and cannot be reversed."
              okText="Remove"
              okType="danger"
              cancelText="Cancel"
              onConfirm={() => handleRemoveLocale(r.code)}
            >
              <Button type="text" danger>
                Remove
              </Button>
            </Popconfirm>
          )
        });
      }
    }
    return colmuns;
  }, [isEditMode, handleRemoveLocale, productLocales]);

  return (
    <Container>
      <Flex justify="space-between" align="center">
        <b>Locale</b>
        {isEditMode ? (
          <Select
            open={showDropdown}
            onDropdownVisibleChange={setShowDropdown}
            showSearch
            mode="multiple"
            options={localesSelection}
            style={{ width: 200 }}
            placeholder="Find to add"
            value={selectedLocales}
            dropdownRender={optionRenderer}
            onChange={setSelectedLocale}
            disabled={localesSelection.length === 0}
            tagRender={() => <></>}
          />
        ) : null}
      </Flex>
      <Table
        rowKey="code"
        columns={columns}
        dataSource={productLocales}
        style={{ marginTop: 10 }}
        pagination={false}
      />
    </Container>
  );
};
