import { Col, FormInstance, Row, Tag } from "antd";
import "./toggleInputSetting.scss";
import { TemplateSetting } from "../../../../domain/valueObjects/templateSetting";
import BooleanFormItem from "@cm/ui/molecules/formItems/booleanFormItem/BooleanFormItem";
import { t, tMap } from "../../../../../../features/i18n/translation";

interface Props {
  setting: TemplateSetting;
  form: FormInstance;
}

export const ToggleInputSetting: React.FC<Props> = ({
  setting,
  form
}): JSX.Element => {
  return (
    <Row className="toggle-input-setting">
      <Col span={24}>
        <span className="label">{t(tMap["category.settings.inputType"])}</span>
        <Tag>{setting.inputType}</Tag>
      </Col>
      <Col span={24}>
        <span className="label">{t(tMap["category.settings.dataType"])}</span>
        <Tag>{setting.dataType}</Tag>
      </Col>
      <Col span={24}>
        <span className="label">
          {t(tMap["category.settings.defaultValue"])}
        </span>
        <BooleanFormItem
          form={form}
          formItemName={[setting.name, "default"]}
          value={setting.default}
          onText={t(tMap["category.settings.on"])}
          offText={t(tMap["category.settings.off"])}
        />
      </Col>
    </Row>
  );
};

export default ToggleInputSetting;
