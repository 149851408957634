import styled from "styled-components";

const EditorViewMode = styled.div`
  margin-bottom: 16px;

  .name {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
  }
`;

export default EditorViewMode;
