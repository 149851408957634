import { categoryApiService } from "@cm/common/services/categoryApiService";
import { CategoryEntity } from "@cm/domain/entities/categoryEntity";
import { CategoryAttribute } from "@cm/domain/valueObjects/categoryAttribute";
import { GetProductResponse, ProductTypeEnum } from "@pm/models/ProductEntity";
import { ProductAttributesMap } from "@pm/types/ProductAttributesMap";
import { useEffect, useMemo, useState } from "react";
import { useUIState } from "./useUIState";
import _ from "lodash";
import { CategoryAttributeWithOriginal } from "@pm/state/productManagementUIState";
import { apiService as attributeApiService } from "@am/api/ApiService";

/* An utility hook for product's attributes. It will load all attributes and setting based on product
 * Returns:
 * - attributesMap: a map of attribute detail, in order
 * - isLoading: boolean value for loading state
 * - attributeSettingMap: a map for attribute setting
 */
export function useLoadAttributes(product?: GetProductResponse) {
  const [isLoading, setLoading] = useState(false);
  const {
    uiState: { attributesMap, attributeSettingMap, categoryList, overwriteCategoryIds },
    setAttributesMap,
    setAttributeSettingMap,
    setCategoryList,
  } = useUIState();

  // this list should be ordered
  const [attributeList, setAttributeList] = useState<string[]>([]);

  async function loadCategories(categoryIds: string[]) {
    setLoading(true);

    const fetchedCats = await Promise.all(
      categoryIds.map((c) => categoryApiService.getCategory(c))
    );
    setCategoryList(fetchedCats);

    setAttributeList(getAllAttributeNamesSorted(fetchedCats));
    setAttributeSettingMap(getAttributeSettingsMerged(fetchedCats));
  }

  async function loadAttributes(attributeNames: string[]) {
    const fetchedAttributes = await Promise.all(
      attributeNames.map(async (name) => {
        return await attributeApiService.getAttributeByName(name);
      })
    );

    const map: ProductAttributesMap = {};
    fetchedAttributes.forEach((a) => {
      map[a.name] = a;
    });
    setAttributesMap(map);
    setLoading(false);
  }

  useEffect(() => {
    if (!product?.categories?.length) {
      return;
    }

    const newCategoryIds = overwriteCategoryIds ?? product?.categories.map((x) => x.id);
    const oldCategoryIds = categoryList.map((x) => x.id);
    if (_.isEqual(newCategoryIds, oldCategoryIds)) {
      return;
    }

    loadCategories(newCategoryIds);
  }, [product?.categories, overwriteCategoryIds]);

  useEffect(() => {
    if (attributeList.length > 0) loadAttributes(attributeList);
  }, [attributeList]);

  const finalAttributesMap = useMemo(() => {
    const tempAttributesMap: ProductAttributesMap = {};

    for (const key in attributeSettingMap) {
      if (
        product?.type === ProductTypeEnum.main &&
        attributeSettingMap[key].isVariantDependent
      ) {
        continue;
      }

      if (
        product?.type === ProductTypeEnum.variant &&
        !attributeSettingMap[key].isVariantDependent
      ) {
        continue;
      }

      tempAttributesMap[key] = attributesMap[key];
    }

    return tempAttributesMap;
  }, [attributesMap, product?.type, attributeSettingMap]);

  return {
    isLoading,
    attributeSettingMap,
    attributesMap: finalAttributesMap
  };
}

/////// Support functions ///////

function attributeSorter(a: CategoryAttribute, b: CategoryAttribute): number {
  // larger mean upper in list
  return (b.displayOrder ?? 0) - (a.displayOrder ?? 0);
}

export function getAllAttributeNamesSorted(categories: CategoryEntity[]): string[] {
  const attributeList: string[] = [];
  categories.forEach((c) => {
    // make sure we sort internal attributes first
    const attributeTemplates = c.templates.flatMap((c) => c.attributes);
    attributeTemplates.sort(attributeSorter);

    attributeTemplates.forEach((a) => {
      // attributes are added based on order, if not already exist
      if (!attributeList.includes(a.name)) attributeList.push(a.name);
    });
  });
  return attributeList;
}

export function getAttributeSettingsMerged(
  categories: CategoryEntity[]
): Record<string, CategoryAttributeWithOriginal> {
  const attributeSettingMap: Record<string, CategoryAttributeWithOriginal> = {};

  /* Ref: https://ainavio.atlassian.net/wiki/spaces/PATE/pages/245006346/Product+management#A.5.-Main-view%3A-Attribute-settings---Rules-for-overwriting
   * Summaries:
   * - Latest one override
   * - Same tree line will use leaf, even if they added after other cat, so basically, ignore it
   */

  const includedCat: string[] = [];

  categories.forEach((c) => {
    if (includedCat.includes(c.id)) return;

    const attributeTemplates = c.templates.flatMap((c) => c.attributes);
    attributeTemplates.forEach((a) => {
      attributeSettingMap[a.name] = {
        ...a, // override always
        categoryId: c.id, // store which attributes the setting taken from
      }
    });

    // now record all added cats so we can skip when matched
    c.templates.forEach((t) => {
      if (t.categoryName !== "base" && !includedCat.includes(t.categoryId)) {
        includedCat.push(t.categoryId);
      }
    });
  });

  return attributeSettingMap;
}
