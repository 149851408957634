import { attributesRouteConstants } from "@am/attributeRoutes";
import { inputTypesUiMetadata } from "@am/common/helpers/attributeUiMappings";
import { t, tMap } from "../../../../../../features/i18n/translation";
import { Row, Space, Button, Col, Tag, Typography, Flex } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getDate } from "../../../../../../common/modules/dateFormat";
import { showNotifications } from "../../../../../../common/modules/showNotifications";
import { AinIcon } from "../../../../../../common/ui/atoms/AinIcon";
import AinGrid from "../../../../../../common/ui/molecules/AinGrid";
import TextWithLabel, {
  ITextWithLabelProps
} from "../../../../../../common/ui/molecules/TextWithLabel";
import { AttributeEntity } from "@am/models/entities/attributeEntity";
import { themeColors } from "../../../../../../common/ui/styles/themeColors";
import { useAttributesStore } from "@am/state/stores/attributesStore";
import { useDispatchDraftSavedClicked } from "@am/hooks/useDraftSaveClickedListener";
import { useGetDetailsFormValuesPublisher } from "@am/hooks/useGetDetailsFormValuesPublisher";
import { DetailsHeaderStyled } from "./DetailsHeaderStyled";
import { AttributeTypeIcon } from "@am/ui/molecules/AttributeTypeIcon";
import { getAttributeTypeChecker } from "@am/common/businessLogic/getAttributeType";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";

const labelStyles = (
  width = 202
): Omit<ITextWithLabelProps, "label" | "size"> => ({
  direction: "horizontal",
  labelStyle: {
    width,
    display: "block"
  },
  color: themeColors.colorTextSecondary
});

// const items: MenuProps["items"] = [
//   {
//     label: t(tMap["common.saveDraft"]),
//     key: "save"
//   },
//   {
//     label: t(tMap["common.deleteDraft"]),
//     key: "delete"
//   }
// ];

type Props = {
  attributeEntity: AttributeEntity;
  onDelete: () => void;
};

// enum DraftButtonValues {
//   save = "save",
//   delete = "delete"
// }

export const DetailsHeader: React.FC<Props> = ({
  attributeEntity,
  onDelete
}): JSX.Element => {
  const navigate = useNavigate();
  const getDetailsFormValuesPublisher = useGetDetailsFormValuesPublisher();
  const { dispatchDraftSavedClicked } = useDispatchDraftSavedClicked();
  const { ui } = useAttributesStore();
  const { isEditing, setIsEditing, setLastButtonClick } = ui;

  function onSaveClicked() {
    setLastButtonClick("publish");
    setIsEditing(false);
  }

  // const handleDraftButtonClick: MenuProps["onClick"] = (args) => {
  //   const key = args.key as keyof typeof DraftButtonValues;
  //   if (key === "save") {
  //     setLastButtonClick("saveDraft");
  //     setIsEditing(false);
  //     dispatchDraftSavedClicked(Date.now());
  //     return;
  //   }

  //   setLastButtonClick("deleteDraft");
  //   setIsEditing(false);
  //   dispatchDraftSavedClicked(Date.now());
  // };

  return (
    <DetailsHeaderStyled className="details-header-container">
      <Row className="header-breadcrumb">
        <Space>
          <Button
            data-test={dMap["button-back"]}
            type="link"
            onClick={() => {
              setIsEditing(false);
              navigate(`/${attributesRouteConstants.attributes}`);
            }}
            size="small"
          >
            <AinIcon icon="arrow-left" size={12}></AinIcon>
          </Button>
          <span className="header-breadcrumb-app-title">
            {t(tMap["common.attribute"])}
          </span>
          /<strong>{attributeEntity.name}</strong>
        </Space>
      </Row>

      {/* Input Type, Name --- Actions */}
      <Col className="header-details-name-container">
        <Space>
          <AttributeTypeIcon attributeEntity={attributeEntity} />

          <Tag>{inputTypesUiMetadata()[attributeEntity.inputType]?.label}</Tag>

          <Typography.Title className="header-details-name" level={5}>
            {attributeEntity.name}
          </Typography.Title>
        </Space>
      </Col>

      <Row className="header-details-info-and-actions" justify="space-between">
        {/* Actions */}
        <Col span={17}>
          <AinGrid colSpans={[12, 12]} colGap={8}>
            <Row className="details-info-row">
              <Col>
                <TextWithLabel
                  label={t(tMap["common.created"])}
                  {...labelStyles()}
                >
                  {getDate(attributeEntity.createdAt)}
                </TextWithLabel>
              </Col>
              <Col>
                <TextWithLabel
                  label={t(tMap["attribute.id"])}
                  direction="horizontal"
                  {...labelStyles()}
                >
                  {attributeEntity.id}
                </TextWithLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextWithLabel
                  label={t(tMap["common.lastUpdated"])}
                  direction="horizontal"
                  {...labelStyles()}
                >
                  {getDate(attributeEntity.updatedAt)}
                </TextWithLabel>
              </Col>
              <Col>
                {/* <TextWithLabel
                  label={t(tMap["common.status"])}
                  direction="horizontal"
                  {...labelStyles()}
                >
                  <Tag
                    color={
                      attributeStatusUiMapping()[attributeEntity.status]?.color
                    }
                  >
                    {attributeStatusUiMapping()[attributeEntity.status]?.label}
                  </Tag>
                </TextWithLabel> */}
              </Col>
            </Row>
          </AinGrid>
        </Col>

        {getAttributeTypeChecker(attributeEntity).isSystem ? null : (
          <Flex align="end">
            <Space>
              <Button type="default" danger onClick={onDelete}>
                <AinIcon icon="delete-outlined" height={22}></AinIcon>
              </Button>
              {isEditing ? (
                <Space>
                  {/* <Dropdown
                  menu={{
                    items,
                    onClick: handleDraftButtonClick
                  }}
                  trigger={["click"]}
                >
                  <Button>
                    <Space>
                      {t(tMap["common.draft"])}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown> */}

                  <Button onClick={() => setIsEditing(false)}>
                    {t(tMap["common.cancel"])}
                  </Button>
                  <Button
                    data-test={dMap["publish-button"]}
                    type="primary"
                    onClick={onSaveClicked}
                    onMouseOver={() => {
                      getDetailsFormValuesPublisher();
                    }}
                    onFocus={() => {
                      getDetailsFormValuesPublisher();
                    }}
                  >
                    {t(tMap["common.publish"])}
                  </Button>
                </Space>
              ) : (
                <Button
                  data-test={dMap["edit-button"]}
                  onClick={() => setIsEditing(true)}
                >
                  {t(tMap["common.edit"])}
                </Button>
              )}
            </Space>
          </Flex>
        )}
      </Row>
    </DetailsHeaderStyled>
  );
};
