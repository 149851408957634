import { atom, useAtom } from "jotai";
import { useAttributesAtomWithImmer } from "../atoms/attributeAtomsImmer";
import { SetAtom } from "../../../../common/types/jotaiHelperTypes";
import { AttributeEntity } from "../../models/entities/attributeEntity";

const draftAttributeAtom = atom<AttributeEntity | null>(null);

type UiStoreReturn = {
  draftAttribute: AttributeEntity | null;
  setDraftAttribute: SetAtom<AttributeEntity | null>;
  addAttribute: (attr: AttributeEntity) => void;
};

export const useAttributesEntityStore = (): UiStoreReturn => {
  const [attributes, setAttributes] = useAttributesAtomWithImmer();
  const [draftAttribute, setDraftAttribute] = useAtom(draftAttributeAtom);

  const addAttribute = (newAttribute: AttributeEntity) => {
    const updatedAttributes = [...attributes, newAttribute];
    setAttributes(updatedAttributes);
  };
  // resetDetails - eg. when navigating away from details

  return {
    addAttribute,
    draftAttribute,
    setDraftAttribute
  };
};
