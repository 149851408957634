import { NestedForm } from "../../../../../../common/ui/organisms/form/NestedForm";
import { t, tMap } from "../../../../../../features/i18n/translation";
import {
  AssetMinimumInfo,
  GetProductResponse,
  ProductTypeEnum
} from "@pm/models/ProductEntity";
import { styled } from "styled-components";
import {
  borders,
  themeColors
} from "../../../../../../common/ui/styles/themeColors";
import { useEffect, useMemo, useState } from "react";
import { buildFormStructure } from "../../../../../../common/ui/organisms/form/buildFormStructure";
import { productsApiService } from "@pm/api/ProductsApiService";
import { Modal, Spin } from "antd";
import deepmerge from "deepmerge";
import { LanguageSelectorHeader } from "@pm/ui/organisms/languageRadioGroup/LanguageRadioGroup";
import { useLocaleOptions } from "../../../../../../common/hooks/useLocaleOptions";
import { combineMerge } from "../../../../../../common/modules/combineMerge";
import { useAtom, useSetAtom } from "jotai";
import { productEntityStateAtom } from "@pm/state/productManagementEntityState";
import { ContentFilledDropdown } from "@pm/ui/molecules/ContentFilledDropdown";
import { useLoadAttributes } from "@pm/hooks/useLoadAttributes";
import { useUIState } from "@pm/hooks/useUIState";
import { usePublishProductPublisher } from "@pm/hooks/usePublishProductPublisher";

const Container = styled.div`
  .product-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid ${borders.colorBorderSecondary};
    flex-wrap: wrap;
    .locale-selector {
      display: flex;
      align-items: center;
    }
  }
  .content-filled-section {
    .content-filled-label {
      color: ${themeColors.colorPrimaryActive};
    }
    .content-filled-label-icon {
      margin-left: 8px;
      color: ${themeColors.colorPrimaryActive};
    }
  }
  .product-detail-section {
    padding: 24px 16px 0 16px;
  }
`;

interface Props {
  product: GetProductResponse;
  isEdit: boolean;
  thumbnail: AssetMinimumInfo | null;
  type: ProductTypeEnum;
}

export const ProductDetailForm: React.FC<Props> = ({
  product,
  isEdit,
  thumbnail,
  type
}): JSX.Element => {
  const [{ refetch }] = useAtom(productEntityStateAtom);
  const { selectedLocale, localeOptions } = useLocaleOptions();
  const {
    setIsEditMode,
    setProductVariants,
    uiState: { overwriteCategoryIds, productVariants }
  } = useUIState();
  const {
    attributesMap,
    isLoading: isAttributesLoading,
    attributeSettingMap
  } = useLoadAttributes(product);
  const [localeForForm, setLocaleForForm] = useState(selectedLocale);
  const publishProductPublisher = usePublishProductPublisher();
  const { setIsFormUpdated } = useUIState();

  const finalProduct = useMemo(() => {
    if (isAttributesLoading) return;
    if (!product) return;
    const result = buildFormStructure(product, attributesMap, localeOptions);
    return {
      ...product,
      attributes: result.attributes
    };
  }, [product, attributesMap, isAttributesLoading]);

  useEffect(() => {
    if (!selectedLocale) return;
    setLocaleForForm(selectedLocale);
  }, [selectedLocale?.value]);

  const handleFinish = async (
    updatedProduct: Pick<GetProductResponse, "attributes" | "name">,
    isPublishing: boolean
  ) => {
    if (product.type !== "main") {
      console.log(`Ignore product variant ${product.id}`);
      return;
    }
    let mergedAttributes = deepmerge(
      product.attributes,
      updatedProduct.attributes,
      {
        arrayMerge: combineMerge,
        customMerge: customMergeAttributes
      }
    );

    mergedAttributes = keepNullValues();

    const finalUpdatedProduct = {
      ...product,
      ...updatedProduct,
      thumbnail,
      attributes: {
        ...product.attributes,
        ...mergedAttributes
      },
      categories:
        overwriteCategoryIds?.map((c) => ({
          id: c,
          name: "",
          appearanceName: []
        })) ?? product.categories,
      id: product.id
    };
    await productsApiService.updateProduct(product.id, finalUpdatedProduct);
    setIsFormUpdated(false);

    if (productVariants?.length) {
      const responses = await Promise.all(
        productVariants.map(async (product) => {
          return await productsApiService.updateProduct(product.id, product);
        })
      );

      // check the response to make sure it is a ProductEntity before set the data
      if (responses.every((x) => x.id)) {
        setProductVariants(responses);
      }
    }
    setIsEditMode(false);

    if (isPublishing) {
      await handlePublish(product.id, productVariants?.map((x) => x.id) ?? []);
    }

    await refetch();

    /**
     * Else, the null values will be removed from the merged object during the request
     */
    function keepNullValues() {
      Object.keys(product.attributes).forEach((key) => {
        if (mergedAttributes[key] === undefined) {
          mergedAttributes[key] = product.attributes[key];
        }
      });
      return mergedAttributes;
    }
  };

  // TODO: move all save/publish logic to store
  const handlePublish = async (productId: string, variantIds: string[]) => {
    async function handleProductCompleteness(): Promise<boolean> {
      // fetch latest product data & test for completeness
      const productData = await productsApiService.getProduct(productId);
      const notCompleteLocale = productData.locales
        .filter((x) => x.completeness < 100)
        .map((x) => x.value);

      if (notCompleteLocale.length > 0) {
        return new Promise((r) => {
          Modal.error({
            title: tMap["product.edit.cannotPublish"],
            content: tMap["product.edit.cannotPublishContent"],
            onOk: () => {
              r(false);
            },
            okText: tMap["common.close"]
          });
        });
      }
      return true;
    }

    async function handleVariantCompleteness(): Promise<boolean> {
      // test each variant, one after another
      const variantData = await productsApiService.getProductVariants(
        productId,
        ""
      );
      const inCompleteVariants = variantData.items.filter((v) => {
        const inCompleteLocales = v.locales.filter((x) => x.completeness < 100);

        return inCompleteLocales.length > 0;
      });

      if (inCompleteVariants.length) {
        return new Promise((r) => {
          Modal.error({
            title: tMap["product.edit.variantIncomplete"],
            content: `${
              tMap["product.edit.variantIncompleteContent"]
            } ${inCompleteVariants.map((x) => x.id).join(", ")}`,
            onOk: () => {
              r(false);
            },
            okText: tMap["common.close"]
          });
        });
      }
      return true;
    }

    const publishProduct = await handleProductCompleteness();
    if (!publishProduct) return;
    const publishVariant = await handleVariantCompleteness();
    if (!publishVariant) return;

    publishProductPublisher("");
  };

  if (isAttributesLoading) return <Spin className="loading" />;

  return (
    <Container>
      <section className="product-detail-header">
        <div className="locale-selector">
          {isEdit
            ? t(tMap["common.editInLocale"])
            : t(tMap["common.viewInLocale"])}
          <LanguageSelectorHeader onChange={setLocaleForForm} />
        </div>
        <div className="content-filled-section">
          <ContentFilledDropdown
            locale={localeForForm?.value as string}
            product={finalProduct}
          />
        </div>
      </section>
      <section className="product-detail-section">
        <NestedForm
          type={type}
          product={finalProduct as unknown as GetProductResponse}
          productAttributesMap={attributesMap}
          attributeSettingMap={attributeSettingMap}
          locale={localeForForm?.value as string}
          isEdit={isEdit}
          handleFinish={handleFinish}
        />
      </section>
    </Container>
  );
};

/**
 * Make data API compatible.
 * if a value is `undefined`, it will be removed in the API payload, thus have to set it to `null`, so it is not removed
 */
function customMergeAttributes<T extends Record<string, unknown>>(key: string) {
  return (source: T, target: T) => {
    if (source == null) {
      Object.entries(target).forEach(([key, value]) => {
        const isArray = Array.isArray(value);
        if (isArray && value[0] === null) {
          // @ts-expect-error is object
          target[key] = null; // if value=[null], then set it to `null` for the API
          return;
        }

        if (isArray) {
          value.forEach((item) => {
            if (value.length === 1 && item === null) {
              // case: [null]
              return;
            }

            Object.entries(item).forEach(([nestedKey, nestedValue]) => {
              if (nestedValue !== undefined) return;
              item[nestedKey] = null; // if `undefined`, it will be removed in the API payload, thus have to set it to `null`, so it is not removed
            });
          });
        }
      });
      return target;
    }

    return target;
  };
}
