import { Labels } from "../../../../common/domain/valueObjects/Label";
import { AttributeDataTypeEnum } from "../enums/attributeDataTypeEnum";
import { AttributeInputTypeEnum } from "../enums/attributeInputTypeEnum";
import { AttributeObjectOption } from "./attributeObjectOptions";
import { AttributesDataSettings } from "./dataSettings";
import { AttributesInputSettings } from "./inputSettings";

export type AttributeTemplate = {
  id?: string; // internal FE-only id to handle tree updates
  name: string;
  description: string;
  isChannelDependent: boolean;
  isLocaleDependent: boolean;
  labels: Labels[];
  dataType: AttributeDataTypeEnum;
  isMultipleValues: boolean;
  inputType: AttributeInputTypeEnum;
  inputSettings?: AttributesInputSettings | null;
  dataSettings?: AttributesDataSettings | null;
  default: any;
  isUnique: boolean;
  isNullable: boolean;
  settings?: string | null;
  template: AttributeTemplate[] | null;
  options: AttributeObjectOption[] | null;
};

export const emptyAttributeTemplate: AttributeTemplate = {
  name: "",
  description: "",
  isChannelDependent: false,
  isLocaleDependent: false,
  labels: [],
  dataType: AttributeDataTypeEnum.text,
  isMultipleValues: false,
  inputType: AttributeInputTypeEnum.simple,
  default: "",
  isUnique: false,
  isNullable: false,
  settings: null,
  inputSettings: null,
  dataSettings: null,
  template: null,
  options: null
};
