import { styled } from "styled-components";

export const DetailsOptionValueStyled = styled.div`
  height: 100vh;
  width: 100%;

  .title-selector,
  .locale-selector {
    min-width: 200px;
  }
`;

export const OptionValueHeader = styled.div`
  .option-value-header-row {
    padding: 16px 24px;
    .header-row-title {
      margin: 0;
    }
  }
`;
