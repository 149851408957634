import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { Button, Modal, Pagination, Table } from "antd";
import "./importCategoriesModal.scss";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import Search from "antd/es/input/Search";
import { attributesRouteConstants } from "@am/attributeRoutes";
import { apiService as attributeApiService } from "@am/api/ApiService";
import {
  ApiListRequest,
  ApiSearchParam,
  ApiSortParam
} from "../../../../../../common/types/commonTypes";
import { commonConstants } from "../../../../../../common/constants/commonConstants";
import { t, tMap } from "../../../../../../features/i18n/translation";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";

interface Props {
  onImportAttribute: (attributes: string[]) => void;
}

interface TableDataType {
  key: string;
  description: string;
}

const defaultSearch: ApiSearchParam = {
  field: "isMasterAttribute",
  operation: "eq",
  condition: "false"
};

const ImportCategoriesModal = forwardRef((props: Props, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<TableDataType[]>();
  const [total, setTotal] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [search, setSearch] = useState<ApiSearchParam[]>();
  const [page, setPage] = useState<number>(1);
  const [sorts, setSorts] = useState<ApiSortParam[]>();
  const availableAttributes = useRef<string[]>([]);

  const columns: ColumnsType<TableDataType> = [
    {
      title: t(tMap["category.details.name"]),
      dataIndex: "key",
      key: "key",
      sorter: true,
      render: (key) => (
        <div
          className="attribute-name"
          onClick={() => handleRowSelected({ key } as TableDataType)}
        >
          {key}
        </div>
      )
    },
    {
      title: t(tMap["category.details.description"]),
      dataIndex: "description",
      key: "description",
      sorter: true
    },
    {
      title: t(tMap["category.common.action"]),
      width: 100,
      render: (_, record) => (
        <Button type="link">
          <Link
            to={`/${attributesRouteConstants.attributes}/${record.key}/config`}
            target="_blank"
          >
            {t(tMap["category.details.viewAttribute"])}
          </Link>
        </Button>
      )
    }
  ];

  const handleOpenModal = (attributeNames: string[]) => {
    setSelectedRowKeys(attributeNames);
    availableAttributes.current = attributeNames;
    setIsOpen(true);
  };

  useImperativeHandle(ref, () => ({ open: handleOpenModal }));

  const callApi = useCallback(async () => {
    if (!isOpen) {
      return;
    }

    setDataSource(undefined);

    const request: ApiListRequest = {
      pageSize: commonConstants.tablePageSize,
      page,
      sorts,
      search: [...(search ?? []), defaultSearch]
    };

    const response = await attributeApiService.getAttributes(request);
    const tempDataSource = response.items.map((x) => ({
      key: x.name,
      description: x.description
    }));

    setDataSource(tempDataSource);
    setTotal(response.total);
  }, [page, sorts, search, isOpen]);

  useEffect(() => {
    callApi();
  }, [page, sorts, search, isOpen]);

  const handleSortColumn = (sorter: any) => {
    const sorts = [
      {
        field: sorter.field!.toString(),
        order: sorter.order!
      }
    ];

    setSorts(sorts);
  };

  const handleSearchChanged = (keyword: string) => {
    let tempSearch: ApiSearchParam[] | undefined;

    if (keyword) {
      tempSearch = [
        {
          field: "name",
          operation: "ct",
          condition: keyword
        }
      ];
    }

    setSearch(tempSearch);
  };

  const handleOk = () => {
    props.onImportAttribute(selectedRowKeys);
    setIsOpen(false);
  };

  const handleRowSelected = (record: TableDataType) => {
    const keys = selectedRowKeys.includes(record.key)
      ? selectedRowKeys.filter((x) => x !== record.key)
      : [...selectedRowKeys, record.key];

    setSelectedRowKeys(keys);
  };

  return (
    <Modal
      title={t(tMap["category.details.importAttributes"])}
      okText={
        <span data-test={dMap["modal-button-import"]}>
          {t(tMap["category.details.importSelectedAttribute"])}
        </span>
      }
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={handleOk}
      width={1200}
      destroyOnClose
      className="import-categories-modal"
    >
      <div>
        <Search
          className="search"
          placeholder={t(tMap["category.details.searchAttributePlaceholder"])}
          onSearch={handleSearchChanged}
        />
        <span className="total">{`${total} ${t(
          tMap["category.details.attributesFound"]
        )}`}</span>
      </div>
      <Table
        dataSource={dataSource}
        loading={!dataSource}
        columns={columns}
        pagination={false}
        onChange={(_, __, sorter) => handleSortColumn(sorter)}
        rowSelection={{
          type: "checkbox",
          hideSelectAll: true,
          selectedRowKeys,
          onSelect: handleRowSelected,
          getCheckboxProps: (record) => ({
            disabled: availableAttributes.current.includes(record.key)
          })
        }}
      />
      {!dataSource?.length ? null : (
        <Pagination
          defaultCurrent={page}
          pageSize={commonConstants.tablePageSize}
          total={total}
          onChange={setPage}
        />
      )}
    </Modal>
  );
});

export default ImportCategoriesModal;
