import { productsApiService } from "@pm/api/ProductsApiService";
import {
  AssetMinimumInfo,
  GetProductResponse,
  ProductEntity,
  ProductTypeEnum
} from "@pm/models/ProductEntity";
import { currentProductIdAtom } from "@pm/state/productManagementEntityState";
import { LabelText } from "@shared/ui/atoms/LabelText";
import { Button, Col, Row, Select } from "antd";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useCommonStore } from "../../../../../common/domain/state/stores/useCommonStore";
import { commonConstants } from "../../../../../common/constants/commonConstants";
import { t, tMap } from "../../../../../features/i18n/translation";
import { AttributeInputTypeEnum } from "@am/models/enums/attributeInputTypeEnum";
import { ProductDetailForm } from "@pm/ui/pages/productDetailsPage/productDetailForm/ProductDetailForm";
import { ThumbnailImage } from "../thumbnailImage/ThumbnailImage";
import { TableDataType, VariantsTable } from "./VariantsTable";
import { useUIState, useUIStateReadOnly } from "@pm/hooks/useUIState";
import { useLoadAttributes } from "@pm/hooks/useLoadAttributes";
import { Labels } from "../../../../../common/domain/valueObjects/Label";

const Container = styled(Row)`
  .thumbnail-image {
    width: 100%;
    background-color: #f3f3f3;
    padding-bottom: 16px;
    padding-left: 16px;

    .preview-image .ant-image,
    .ant-upload-drag,
    .placeholder {
      height: 130px !important;
    }

    .anticon-picture {
      font-size: 30px;
    }
  }

  .variant-list {
    border-right: 1px solid #d9d9d9;
  }

  .actions {
    padding: 16px;

    > label {
      text-transform: lowercase;
    }

    .locale {
      margin-left: 16px;

      .ant-select {
        min-width: 185px;
        margin-left: 8px;
      }
    }

    .add-button {
      float: right;
    }

    @media screen and (max-width: 1100px) {
      position: relative;
      height: 90px;

      > label {
        position: absolute;
        margin-top: 40px;
      }

      .locale {
        position: absolute;
        margin-left: 0;
        margin-top: 0;
      }
    }
  }
`;

export const ProductVariants: React.FC = () => {
  const productId = useAtomValue(currentProductIdAtom);
  const {
    uiState: { productVariants },
    setProductVariants,
    setIsFormUpdated
  } = useUIState();
  const { isEditMode } = useUIStateReadOnly();
  const [locale, setLocale] = useState<string>(commonConstants.defaultLocale);
  const { attributesMap } = useLoadAttributes(
    productVariants?.length
      ? (productVariants[0] as GetProductResponse)
      : undefined
  );
  const [dataSource, setDataSource] = useState<TableDataType[]>();
  const [selectedVariant, setSelectedVariant] = useState<ProductEntity>();
  const changingVariantId = useRef<string>();
  const {
    ui: { locales }
  } = useCommonStore();

  const callApi = useCallback(
    async (isAddNew?: boolean) => {
      if (!productId) {
        return;
      }

      const response = await productsApiService.getProductVariants(
        productId,
        locale
      );

      if (isAddNew) {
        const newVariant = response.items?.find((v) =>
          productVariants?.every((x) => x.id !== v.id)
        );

        if (newVariant && productVariants) {
          setProductVariants([...productVariants, newVariant]);
          handleRowClicked(newVariant.id);
        }
      } else {
        setProductVariants(response.items);
      }
    },
    [locale, productId, productVariants]
  );

  const resolveAttributeValueText = (value: any) => {
    if (value === undefined || value === null) {
      return "--";
    }

    if (!Array.isArray(value)) {
      return value;
    }

    if (!value.length) {
      return "--";
    }

    const label = value.find((x) => x.locale === locale);
    if (label) {
      return label.value ?? "--";
    }
  };

  const buildDataSource = () => {
    if (!productVariants?.length) {
      return;
    }

    const simpleAttributes: any[] = Object.entries(attributesMap).filter(
      ([_, value]) =>
        (value as any)?.inputType === AttributeInputTypeEnum.simple
    );

    const dataSourceTemp = productVariants.map((variant) => ({
      key: variant.id,
      name:
        (variant.name as Labels[])?.find((x) => x.locale === locale)?.value ??
        "",
      thumbnail: variant.thumbnail,
      attributes:
        simpleAttributes?.map(([_, x]) => ({
          value: resolveAttributeValueText(variant.attributes[x.name]),
          label:
            x.labels?.find((x: any) => x.locale === locale)?.value ?? x.name
        })) || []
    }));
    setDataSource(dataSourceTemp);
  };

  useEffect(() => {
    callApi();
  }, [locale, productId]);

  useEffect(() => {
    buildDataSource();

    const variant = productVariants?.find((x) => x.id === selectedVariant?.id);
    if (variant) {
      setSelectedVariant({ ...variant });
    }
  }, [isEditMode]);

  useEffect(() => {
    if (!attributesMap) {
      return;
    }

    buildDataSource();
  }, [attributesMap, productVariants, selectedVariant]);

  const handleAddVariant = async () => {
    if (!productId) {
      return;
    }

    await productsApiService.createProduct({ mainProductId: productId });
    callApi(true);
  };

  const handleRowClicked = (variantId: string) => {
    changingVariantId.current = variantId;
    setSelectedVariant({} as any);
  };

  useEffect(() => {
    if (!selectedVariant || selectedVariant.attributes) {
      return;
    }

    const variant = productVariants?.find(
      (x) => x.id === changingVariantId.current
    );
    if (!variant) {
      return;
    }

    setSelectedVariant(variant);
  }, [selectedVariant]);

  const handleDeleteVariant = async (id: string) => {
    await productsApiService.deleteProduct(id);
    setProductVariants(productVariants?.filter((x) => x.id !== id));
    setDataSource(dataSource?.filter((x) => x.key !== id));
    setSelectedVariant(undefined);
  };

  const handleUploadThumbnailFinished = (thumbnail: AssetMinimumInfo) => {
    setIsFormUpdated(true);

    if (!productVariants?.length) {
      return;
    }

    const index = productVariants.findIndex(
      (x) => x.id === selectedVariant?.id
    );
    productVariants.splice(index, 1, {
      ...productVariants[index],
      thumbnail
    });
  };

  return (
    <Container>
      <Col span={12} className="variant-list">
        <div className="actions">
          <LabelText>
            {productVariants?.length ?? 0} {t(tMap["common.variants"])}
          </LabelText>
          <span className="locale">
            <span>{t(tMap["common.locale"])}: </span>
            <Select
              defaultValue={commonConstants.defaultLocale}
              onChange={setLocale}
              options={locales?.map((x) => ({ value: x.code, label: x.name }))}
            />
          </span>
          {!isEditMode ? null : (
            <Button
              className="add-button"
              type="primary"
              onClick={handleAddVariant}
            >
              {t(tMap["product.variantsTable.addVariant"])}
            </Button>
          )}
        </div>
        <VariantsTable
          isEdit={isEditMode}
          dataSource={dataSource}
          selectedVariantId={selectedVariant?.id}
          onRowClick={handleRowClicked}
          onDeleteVariant={handleDeleteVariant}
        />
      </Col>

      <Col span={12}>
        {selectedVariant?.attributes ? (
          <div>
            <ThumbnailImage
              isEdit={isEditMode}
              className="thumbnail-image"
              image={selectedVariant?.thumbnail}
              onUploadFinished={handleUploadThumbnailFinished}
            />
            <ProductDetailForm
              type={ProductTypeEnum.variant}
              isEdit={isEditMode}
              product={selectedVariant}
              thumbnail={selectedVariant?.thumbnail ?? null}
            />
          </div>
        ) : null}
      </Col>
    </Container>
  );
};
