import { useAtom } from "jotai";
import { atomWithListeners } from "../../../common/hooks/state/atomWithListeners";
import { ListenerArgs, defaultArg } from "@am/types/attributeCommonTypes";
import { useEffect, useState } from "react";
import { useCallbackRef } from "../../../common/hooks/useCallbackRef";

export const [getDetailsFormValuesAtom, getDetailsFormValuesSubscriber] =
  atomWithListeners<ListenerArgs>(defaultArg);

export function useGetDetailsFormValuesPublisher() {
  /**
   * Keep track of counter, to allow us to compare against the countAtom.
   * This enables, eg calling a callback once
   */
  const [counter, setCounter] = useState(0);
  const [countAtom, setCountAtom] = useAtom(getDetailsFormValuesAtom);
  const callbackRef = useCallbackRef(() => {
    countAtom.callback && countAtom.callback();
  }, []);

  useEffect(() => {
    if (countAtom.count === counter) {
      callbackRef.current();
    }
  }, [countAtom.count, counter, callbackRef]);

  return (callback?: () => void) => {
    const newCount = counter + 1;
    setCounter(newCount);
    setCountAtom({ count: newCount, callback });
  };
}
