import "./categoryPage.scss";
import { Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import React, { useCallback, useEffect, useState } from "react";
import CategoryHeader from "../../organisms/categoryHeader/CategoryHeader";
import CategoryTree from "../../organisms/categoryTree/CategoryTree";
import CategoryDetails from "../../organisms/categoryDetails/CategoryDetails";
import { DataNode } from "antd/es/tree";
import { categoryApiService } from "../../../common/services/categoryApiService";
import CategoryFilter from "../../organisms/categoryFilter/CategoryFilter";
import { CategoryListResponse } from "../../../domain/valueObjects/categoryListResponse";
import { useCategoryUpsertListener } from "../../../domain/hooks/useCategoryUpsertListener";
import { useCategoryPageState } from "../../../domain/hooks/useCategoryPageState";
import { useNavigate, useParams } from "react-router-dom";
import { routeConstants } from "../../../../../features/routes/routeConstants";

interface Props {}

const convertToTree = (
  categories: CategoryListResponse[],
  parentId?: string,
  parentPath?: string
): DataNode[] => {
  const filteredCategories = categories.filter(
    (category) => category.parentId === parentId
  );

  return filteredCategories.map((category) => ({
    key: `${parentPath}|${category.id}`,
    title: category.name,
    children: convertToTree(
      categories,
      category.id,
      `${parentPath}|${category.id}`
    )
  }));
};

const filterTreeByKeyword = (
  tree: DataNode[],
  keyword: string
): Set<string> => {
  const result = new Set<string>();

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if ((node!.title as string).toLowerCase().indexOf(keyword) > -1) {
      const keys = node.key.toString().split("|");
      keys.forEach((key) => {
        result.add(key);
      });
    }

    if (node.children?.length) {
      const filteredKeys = filterTreeByKeyword(node.children, keyword);
      filteredKeys.forEach((key) => {
        result.add(key);
      });
    }
  }

  return result;
};

export const CategoryPage: React.FC<Props> = (): JSX.Element => {
  const { id: categoryId } = useParams();
  const navigate = useNavigate();
  const [treeData, setTreeData] = useState<DataNode[] | undefined>();
  const [state, setState] = useCategoryPageState();

  const callApi = async (shouldNavigateToBase?: boolean) => {
    const categories = await categoryApiService.getCategories();
    const nonBaseCategories: CategoryListResponse[] = [];
    let treeDataTemp: DataNode[] = [];
    let baseCategoryId: string | undefined;

    if (categories.items.length) {
      categories.items.forEach((category) => {
        if (category.isBaseCategory) {
          baseCategoryId = category.id;
        } else {
          nonBaseCategories.push(category);
        }
      });

      treeDataTemp = convertToTree(
        nonBaseCategories,
        baseCategoryId,
        baseCategoryId
      );
    }

    setTreeData(treeDataTemp);

    setState({
      baseCategoryId,
      allCategories: categories.items
    });

    if (shouldNavigateToBase) {
      navigate(`/${routeConstants.categories}/${baseCategoryId}`);
    }
  };

  useEffect(() => {
    callApi(!categoryId);
  }, []);

  useCategoryUpsertListener(
    useCallback(async () => {
      setState({ allCategories: undefined });

      await callApi();
    }, [])
  );

  useEffect(() => {
    if (!state.allCategories) {
      return;
    }

    let treeDataTemp = convertToTree(
      state.allCategories,
      state.baseCategoryId,
      state.baseCategoryId
    );
    const filteredKeys = Array.from(
      filterTreeByKeyword(
        treeDataTemp!,
        state.searchKeyword?.toLowerCase() ?? ""
      )
    );
    const filteredCategories = state.allCategories.filter(
      (x) =>
        !x.isBaseCategory &&
        (state.searchKeyword ? filteredKeys.includes(x.id) : true)
    );

    treeDataTemp = convertToTree(
      filteredCategories,
      state.baseCategoryId,
      state.baseCategoryId
    );
    setTreeData(treeDataTemp);
  }, [state.searchKeyword]);

  return (
    <Layout className="category-page">
      <Layout>
        <CategoryHeader />
        <CategoryFilter />
        <Layout>
          <Sider
            className="category-sider"
            collapsible
            collapsed={!state.isOpenTree}
            collapsedWidth={0}
            width={300}
            trigger={null}
          >
            <CategoryTree treeData={treeData} />
          </Sider>
          <Content>
            <CategoryDetails />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CategoryPage;
