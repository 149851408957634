import { useAttributesStore } from "@am/state/stores/attributesStore";
import { DetailsOptionValue } from "./DetailsOptionValue";
import { AttributeEntity } from "@am/models/entities/attributeEntity";
import { AttributeInputTypeEnum } from "@am/models/enums/attributeInputTypeEnum";
import { useCallback, useEffect, useState } from "react";
import { Flex, SelectProps } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { DefaultOptionType } from "antd/es/select";
import {
  createTreeKeys,
  getKeysFromKeyPath
} from "@am/common/helpers/createTreeKeys";
import { AttributeTemplate } from "@am/models/valueObjects/attributeTemplate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AttributeSearchParams } from "@am/models/valueObjects/attributeSearchParams";
import { useCallbackRef } from "../../../../../../common/hooks/useCallbackRef";
import { GetLocaleResponse } from "../../../../../../common/domain/valueObjects/GetLocaleResponse";
import { useCommonStore } from "../../../../../../common/domain/state/stores/useCommonStore";

/**
 * # 1. On load
 * - options with all selections (also nested)
 * 1.1 Root: Top level is Selection
 * 1.2 Root: Top level is Form with nested Selection
 * 1.3 Query param in URL: keyPath query param, which should show up as `selectedAttributeName`
 *
 * # 2. Actions on Page
 * ## Attribute name selector
 * - Top level show up:  "xyz"
 * - Nested show up:     "-> xyz"
 * 3. When changing an attribute
 */

/**
 * Collect the Nested template names to display in the key selector
 */
function getObjectNames(
  templates: AttributeTemplate[] | null,
  options: DefaultOptionType[],
  parentKey: string = ""
) {
  if (!templates) return;

  templates.forEach((template) => {
    const key = createTreeKeys(parentKey, template.id);

    if (template.inputType !== AttributeInputTypeEnum.selection) {
      getObjectNames(template.template, options, key);
      return;
    }

    options.push({
      label: template.name,
      value: key
    });
  });
}

function getAttributeNameOptions(attribute: AttributeEntity | null) {
  if (!attribute) return;

  const nestedTitles: DefaultOptionType[] = [];
  const options: SelectProps["options"] = [];
  const topLevelIsSelection =
    attribute.inputType === AttributeInputTypeEnum.selection;

  if (topLevelIsSelection) {
    const firstLevelTitleOption = {
      label: attribute.name,
      value: attribute.id
    };
    options.unshift(firstLevelTitleOption);
  }

  const key = topLevelIsSelection ? "" : attribute.id;
  getObjectNames(attribute.template, nestedTitles, key);
  const nestedTitleOptions = nestedTitles.map((nested) => ({
    label: (
      <Flex align="center">
        <ArrowRightOutlined style={{ marginRight: 8 }} />
        <div>{nested.label}</div>
      </Flex>
    ),
    value: nested.value as string
  }));
  options.push(...nestedTitleOptions);

  return options;
}

/**
 * It can happen, that a new locale was
 * 1. added
 * 2. remove
 * but the displayValues were not updated yet
 * Thus, adapt displayValues with locales from the LocaleApi
 * @param attribute
 * @returns
 */
function adaptOptionDisplayValuesWithApiLocales(
  attribute: AttributeTemplate | undefined,
  locales: GetLocaleResponse[]
): AttributeTemplate | undefined {
  if (!attribute) return undefined;
  if (!attribute.options) return attribute;
  attribute.options.forEach((opt) => {
    if (!opt.displayValues) {
      opt.displayValues = [];
    }
    locales.forEach((locale) => {
      const existingLocale = opt.displayValues.find(
        (value) => value.locale === locale.code
      );
      if (!existingLocale) {
        opt.displayValues.push({
          locale: locale.code,
          value: ""
        });
      }
    });
  });

  return attribute;
}

/**
 * 1. At the first level, just return the given `attribute`
 * 2. Else recursively check in nested templates
 *
 * @param attribute
 * @param keyPath aaa|bbb|ccc
 */
function getAttributeTemplate(
  attribute: AttributeEntity | undefined | null,
  keyPath: string | undefined | null,
  locales: GetLocaleResponse[]
): AttributeTemplate | null {
  if (!attribute) return null;
  if (!keyPath) return null;

  // 1.
  const [_, ...otherKeys] = getKeysFromKeyPath(keyPath);
  const isTopLevelSelection =
    attribute.inputType === AttributeInputTypeEnum.selection &&
    otherKeys.length === 0;
  if (isTopLevelSelection) return attribute;

  // 2.
  let target: AttributeTemplate | undefined = undefined;
  getNestedTemplate(attribute.template, otherKeys);
  target = adaptOptionDisplayValuesWithApiLocales(target, locales);

  if (!target) return null;

  return target;

  function getNestedTemplate(
    template: AttributeTemplate[] | null,
    keys: string[]
  ) {
    if (keys.length === 0) return;
    const [key, ...remainingKeys] = keys;
    let targetKey = key;

    if (keys.length === 1) {
      targetKey = keys[0];
    }

    target = template?.find((temp) => temp.id === targetKey);
    if (!target) return;
    if (keys.length === 1) return target;

    getNestedTemplate(target.template, remainingKeys);
  }
}

export const DetailsOptionValueController: React.FC = (): JSX.Element => {
  const [searchParams] = useSearchParams() as [AttributeSearchParams, any];
  const navigate = useNavigate();
  const { entity, ui } = useAttributesStore();
  const { draftAttribute } = entity;
  const { setSelectedKeyPath } = ui;
  const { locales } = useCommonStore().ui;
  const [selectedAttributeName, setSelectedAttributeName] =
    useState<DefaultOptionType>();
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<AttributeTemplate | null>(null);
  const keyPathFromUrl = searchParams.get("keyPath");

  const handleTitleChange = useCallbackRef(
    (attributeName: DefaultOptionType) => {
      const selectedTemplate = getAttributeTemplate(
        draftAttribute,
        String(attributeName.value),
        locales
      );
      setSelectedAttributeName(attributeName);
      selectedTemplate && setSelectedTemplate(selectedTemplate);
      setSelectedKeyPath(String(attributeName.value));

      if (keyPathFromUrl) {
        navigate({
          pathname: "../options-value"
        });
      }
    },
    [draftAttribute]
  );

  useEffect(() => {
    const options = getAttributeNameOptions(draftAttribute);
    if (!options) return;

    const selectedId = keyPathFromUrl
      ? keyPathFromUrl
      : options[0].value?.toString();
    const targetTemplate = getAttributeTemplate(
      draftAttribute,
      selectedId,
      locales
    );
    const targetOption = options.find((opt) => opt.value === selectedId);

    options && setOptions(options);
    targetOption && setSelectedAttributeName(targetOption);
    targetTemplate && setSelectedTemplate(targetTemplate);
    setSelectedKeyPath(String(targetOption?.value));

    setIsLoading(false);
  }, []);

  /** Add new values */
  useEffect(() => {
    const selectedTemplate = getAttributeTemplate(
      draftAttribute,
      String(selectedAttributeName?.value),
      locales
    );
    selectedTemplate && setSelectedTemplate(selectedTemplate);
  }, [draftAttribute]);

  if (!draftAttribute) return <></>;
  if (isLoading) return <></>;
  if (!selectedTemplate)
    return <>No attribute template (DetailsOptionValueController.tsx)</>;

  return (
    <DetailsOptionValue
      selectedTemplate={selectedTemplate}
      selectedAttributeName={selectedAttributeName}
      options={options}
      onTitleChange={handleTitleChange}
    ></DetailsOptionValue>
  );
};
