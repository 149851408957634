import { LabelText } from "@shared/ui/atoms/LabelText";
import { PreviewImage } from "@shared/ui/atoms/PreviewImage";
import { useEffect, useMemo, useState } from "react";
import { Button, Flex } from "antd";
import { PictureFilled } from "@ant-design/icons";
import {
  ThumbnailImagePlaceholderWrapper,
  ThumbnailWrapper
} from "./ThumbnailImage.styles";
import { DnDUploader } from "@shared/ui/atoms/DnDUploader";
import { t, tMap } from "../../../../../features/i18n/translation";
import { AssetMinimumInfo } from "@pm/models/ProductEntity";
import { AssetType } from "@shared/entities/AssetType";
import { downloadFile } from "../../../../../common/modules/fileHelper";

const uploaderId = "thumbnail-upload";

export interface ThumbnailImageProps {
  className?: string;
  isEdit: boolean;
  image: AssetMinimumInfo | null;
  onUploadFinished: (image: AssetMinimumInfo) => void;
}

export const ThumbnailImage: React.FC<ThumbnailImageProps> = ({
  className,
  isEdit,
  image,
  onUploadFinished
}) => {
  const [uploadFiles, setUploadFiles] = useState<AssetMinimumInfo[] | undefined>(
    image ? [image] : undefined
  );

  useEffect(() => {
    setUploadFiles(image ? [image] : undefined);
  }, [image]);

  const imageContent = useMemo(() => {
    if (isEdit)
      return (
        <DnDUploader
          id={uploaderId}
          showPreview
          multiple={false}
          assetType={AssetType.thumbnail}
          files={uploadFiles}
          onChange={onUploadFinished}
          iconRender={() => null}
        />
      );

    if (image) {
      return (
        <div className="preview-image">
          <PreviewImage src={image?.fileLink} alt={image?.fileName} />
          <Button
            className="filename"
            type="link"
            title={image.fileName}
            onClick={() => downloadFile(image.fileLink, image.fileName)}
          >
            {image.fileName}
          </Button>
        </div>
      );
    }

    return (
      <ThumbnailImagePlaceholderWrapper className="placeholder">
        <PictureFilled />
      </ThumbnailImagePlaceholderWrapper>
    );
  }, [image, isEdit, uploadFiles]);

  const handleUploadClicked = () => {
    document.getElementById(uploaderId)?.click();
  };

  return (
    <ThumbnailWrapper vertical gap={16} className={className}>
      <Flex justify="space-between" align="baseline">
        <LabelText> {t(tMap["product.details.thumbnailImage"])}</LabelText>
        {isEdit && (
          <Button onClick={handleUploadClicked} size="small">
            {t(tMap["common.upload"])}
          </Button>
        )}
      </Flex>
      {imageContent}
    </ThumbnailWrapper>
  );
};
