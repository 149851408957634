import { AttributeStatus } from "../../models/enums/attributeStatus";
import { AttributeReadMode } from "../../types/attributeCommonTypes";

export function getReadMode(
  status: AttributeStatus | undefined
): AttributeReadMode | undefined {
  if (!status) return;
  const mode: AttributeReadMode = status === "published" ? "read" : "edit";
  return mode;
}
