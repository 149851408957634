import { useAtom } from "jotai";
import categoryPageAtom, { CategoryPageState } from "../state/categoryPageAtom";
import { produce } from "immer";

// TODO should be replace by the useAtomState
export const useCategoryPageState = (): [
  CategoryPageState,
  (values: any) => void
] => {
  const [state, setState] = useAtom(categoryPageAtom);

  const produceSetState = (values: any) => {
    setState(
      produce((x: any) => {
        Object.entries(values).forEach(([key, value]) => {
          x[key] = value;
        });
      })
    );
  };

  return [state, produceSetState];
};
