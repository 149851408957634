import { Radio } from "antd";
import { AttributeInputTypeEnum } from "../../models/enums/attributeInputTypeEnum";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";

export const renderInputType = (
  SvgComponent: React.FC<{ className: string }>,
  inputType: AttributeInputTypeEnum,
  name: string,
  selectedInputType: AttributeInputTypeEnum
) => (
  <Radio.Button value={inputType}>
    <div data-test={dMap["input-option"]} className="input-option">
      <SvgComponent
        className={`svg ${
          selectedInputType === inputType ? "selected-svg" : ""
        }`}
      />
      <div>{name}</div>
    </div>
  </Radio.Button>
);
