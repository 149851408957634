import { useAttributesStore } from "../../../../state/stores/attributesStore";
import { FormInputType } from "../inputTypes/formInputType/FormInputType";
import { Col } from "antd";

export const DetailsConfig: React.FC = (): JSX.Element => {
  const targetAttribute = useAttributesStore().entity.draftAttribute;

  if (!targetAttribute) return <>Not found</>;

  return (
    <>
      {/* Main */}
      <Col span={24} style={{ padding: 16 }}>
        <FormInputType attributeEntity={targetAttribute} />
      </Col>

      {/* Settings */}
      {/* <Col
        span={6}
        style={{
          height: "100vh",
          borderLeft: `1px solid ${themeColors.colorBorder}`,
        }}
      >
        <DetailsSettingsSidebar attributeEntity={targetAttribute} />
      </Col> */}
    </>
  );
};
