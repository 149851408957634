import React, { useMemo, useState } from "react";
import { Button, Pagination, Table, TableProps, Tooltip } from "antd";
import { t, tMap } from "../../../../../features/i18n/translation";
import "./attributeListing.scss";
import { commonConstants } from "../../../../../common/constants/commonConstants";
import {
  ApiListResponse,
  ApiSortParam
} from "../../../../../common/types/commonTypes";
import { AttributeEntity } from "../../../models/entities/attributeEntity";
import { ColumnsType, SorterResult } from "antd/es/table/interface";
import { useNavigate } from "react-router-dom";
import {
  dataTypesUiMetadata,
  inputTypesUiMetadata
} from "../../../common/helpers/attributeUiMappings";
import "./attributesTable.scss";
import { getDate } from "../../../../../common/modules/dateFormat";
import { getReadMode } from "../../../common/helpers/getReadMode";
import { apiService } from "../../../api/ApiService";
import { AttributeInputTypeEnum } from "../../../models/enums/attributeInputTypeEnum";
import { AttributeDataTypeEnum } from "../../../models/enums/attributeDataTypeEnum";
import { useAttributesStore } from "../../../state/stores/attributesStore";
import { attributesRouteConstants } from "../../../attributeRoutes";
import { AttributeTypeIcon } from "@am/ui/molecules/AttributeTypeIcon";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";

interface Props {
  dataSource?: ApiListResponse<AttributeEntity>;
  page: number;
  onPageChanged: (newPage: number) => void;
  onSortChanged: (sorts: ApiSortParam[]) => void;
  onPublishClicked: (attribute: AttributeEntity) => void;
}

const AttributesTableColumns = (
  onPublishClicked: (attribute: AttributeEntity) => void
): TableProps<AttributeEntity>["columns"] => [
  {
    title: t(tMap["attributeTable.header.name"]),
    dataIndex: "name",
    key: "name",
    sorter: {
      multiple: 1
    },
    width: 220,
    fixed: "left",
    render: (value, record) => {
      return (
        <Tooltip title={record.description} className="attribute-name">
          <AttributeTypeIcon attributeEntity={record} />
          <div style={{ width: 450 }}>{value}</div>
        </Tooltip>
      );
    }
  },
  {
    title: t(tMap["attributeTable.header.inputTypes"]),
    dataIndex: "inputType",
    key: "inputType",
    width: 110,
    filters: [
      ...Object.entries(inputTypesUiMetadata()).map(([inputType, label]) => ({
        text: label && label.label,
        value: inputType
      }))
    ],
    onFilter: (value, record) => record.inputType === value,
    render: (value: AttributeInputTypeEnum) => (
      <>{inputTypesUiMetadata()[value]?.label}</>
    )
  },
  {
    title: t(tMap["attributeTable.header.dataTypes"]),
    dataIndex: "dataType",
    key: "dataType",
    width: 110,
    filters: [
      ...Object.entries(dataTypesUiMetadata()).map(([dataType, label]) => ({
        text: label && label.label,
        value: dataType
      }))
    ],
    onFilter: (value, record) => record.dataType === value,
    render: (value: AttributeDataTypeEnum) => (
      <>{dataTypesUiMetadata()[value]?.label}</>
    )
  },

  {
    title: (
      <span data-test={dMap["isLocaleDependent"]}>
        {t(tMap["attributeTable.header.valueByLocales"])}
      </span>
    ),
    width: 110,
    dataIndex: "isLocaleDependent",
    key: "isLocaleDependent",
    sorter: {
      multiple: 1
    },
    render: (value) => (value ? t(tMap["common.yes"]) : t(tMap["common.no"]))
  },
  {
    title: t(tMap["attributeTable.header.valueByChannels"]),
    width: 110,
    dataIndex: "isChannelDependent",
    key: "isChannelDependent",
    sorter: {
      multiple: 1
    },
    render: (value) => (value ? t(tMap["common.yes"]) : t(tMap["common.no"]))
  },
  // {
  //   title: t(tMap["common.status"]),
  //   dataIndex: "status",
  //   key: "status",
  //   width: 120,
  //   sorter: {
  //     multiple: 1
  //   },
  //   render: (status: AttributeStatusEnum) => (
  //     <Tag color={attributeStatusUiMapping()[status]?.color}>
  //       {attributeStatusUiMapping()[status]?.label}
  //     </Tag>
  //   )
  // },
  {
    title: t(tMap["attributeTable.header.lastUpdate"]),
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 140,
    sorter: {
      multiple: 1
    },
    render: (value) => getDate(value)
  },
  {
    title: t(tMap["common.action"]),
    width: 50,
    dataIndex: "id",
    render: () => (
      /** Dummy button, since navigate is handled by row click */
      <Button type="default">{t(tMap["common.update"])}</Button>
    )
  }
];

const AttributesTable: React.FC<Props> = ({
  dataSource,
  page,
  onPageChanged,
  onSortChanged,
  onPublishClicked
}) => {
  const navigate = useNavigate();
  const { setIsEditing } = useAttributesStore().ui;
  const { setDraftAttribute } = useAttributesStore().entity;

  const columns: ColumnsType<AttributeEntity> = useMemo(
    () => AttributesTableColumns(onPublishClicked)!,
    []
  );

  const withKeyAdded = !dataSource
    ? []
    : dataSource.items?.map((datum) => ({
        ...datum,
        key: datum.id
      }));

  const handleSortColumn = (
    sorter: SorterResult<AttributeEntity> | SorterResult<AttributeEntity>[]
  ) => {
    const multipleSort: SorterResult<AttributeEntity>[] = [];

    if (!Array.isArray(sorter) && sorter.column) {
      multipleSort.push(sorter);
    }

    const sorts = multipleSort.map((s) => ({
      field: s.field!.toString(),
      order: s.order!
    }));

    onSortChanged(sorts);
  };

  const handleRowClicked = async (event: any, record: AttributeEntity) => {
    const mode = getReadMode(record.status);
    const response = await apiService.getAttributeByName(record.name, mode);
    setDraftAttribute(response);

    if (!event.defaultPrevented) {
      setIsEditing(false);
      navigate(`/${attributesRouteConstants.attributes}/${response.name}/config`);
    }
  };

  return (
    <span className="attributes-table">
      <Table
        loading={!dataSource}
        columns={columns}
        dataSource={withKeyAdded}
        pagination={false}
        onChange={(_, __, sorter) => handleSortColumn(sorter)}
        showSorterTooltip={false}
        onRow={(record) => ({
          onClick: (event) => handleRowClicked(event, record)
        })}
        scroll={{ x: 1440 }}
      />
      {!dataSource?.items?.length ? null : (
        <Pagination
          className="pagination"
          defaultCurrent={page}
          pageSize={commonConstants.tablePageSize}
          total={dataSource.total}
          onChange={onPageChanged}
        />
      )}
    </span>
  );
};

export default AttributesTable;
