export const SimpleFieldSvg: React.FC<{
  className: string;
}> = ({ className }): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 5C1.25 4.0335 2.0335 3.25 3 3.25H21C21.9665 3.25 22.75 4.03351 22.75 5V19C22.75 19.9665 21.9665 20.75 21 20.75H3C2.03351 20.75 1.25 19.9665 1.25 19V5ZM3 4.75C2.86193 4.75 2.75 4.86193 2.75 5V19C2.75 19.1381 2.86192 19.25 3 19.25H21C21.1381 19.25 21.25 19.1381 21.25 19V5C21.25 4.86192 21.1381 4.75 21 4.75H3Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.40909 7C5.18316 7 5 7.15618 5 7.34884C5 7.54149 5.18316 7.69767 5.40909 7.69767C6.22216 7.69767 6.63901 7.98451 6.87179 8.29329C7.12228 8.62555 7.18182 9.01767 7.18182 9.2093V14.7907C7.18182 14.9823 7.12228 15.3745 6.87179 15.7067C6.63901 16.0155 6.22216 16.3023 5.40909 16.3023C5.18316 16.3023 5 16.4585 5 16.6512C5 16.8438 5.18316 17 5.40909 17C6.50511 17 7.17917 16.5892 7.56003 16.084C7.92317 15.6023 8 15.0642 8 14.7907C8 15.0642 8.07683 15.6023 8.43997 16.084C8.82083 16.5892 9.49489 17 10.5909 17C10.8168 17 11 16.8438 11 16.6512C11 16.4585 10.8168 16.3023 10.5909 16.3023C9.77784 16.3023 9.36099 16.0155 9.12821 15.7067C8.87772 15.3745 8.81818 14.9823 8.81818 14.7907V9.2093C8.81818 9.05503 8.92396 8.65993 9.21925 8.30741C9.50094 7.97113 9.93242 7.69767 10.5909 7.69767C10.8168 7.69767 11 7.54149 11 7.34884C11 7.15618 10.8168 7 10.5909 7C9.61303 7 8.95361 7.42422 8.55347 7.90189C8.16695 8.36333 8 8.89846 8 9.2093C8 8.93582 7.92317 8.39771 7.56003 7.91601C7.17917 7.41083 6.50511 7 5.40909 7Z"
      fill="#333333"
    />
  </svg>
);
