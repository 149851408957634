import { DatePicker, TimePicker } from "antd";
import { AttributeDataTypeEnum } from "../../../../../models/enums/attributeDataTypeEnum";

export const resolveDateTimeInput = (
  dataType: AttributeDataTypeEnum,
  value: any
) => {
  switch (dataType) {
    // TODO: convert column.value to DayJS
    case AttributeDataTypeEnum.date:
      return <DatePicker defaultValue={value} />;
    case AttributeDataTypeEnum.time:
      return <TimePicker defaultValue={value} />;
    case AttributeDataTypeEnum.dateTime:
      return <DatePicker showTime defaultValue={value} />;
  }
};
