/* eslint-disable react-hooks/rules-of-hooks */
import { useAttributesStore } from "@am/state/stores/attributesStore";
import { commonConstants } from "../../../../common/constants/commonConstants";

export function useTestingSetup() {
  if (!commonConstants.isTesting) return;

  const store = useAttributesStore();
  (window as any).attributeStore = store;
}
