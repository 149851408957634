import { AssetMinimumInfo } from "@pm/models/ProductEntity";
import { Button, Flex, Popconfirm } from "antd";
import { useCallback, useMemo } from "react";
import { AinIcon } from "../../../../common/ui/atoms/AinIcon";
import styled from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons";
import { themeColors } from "../../../../common/ui/styles/themeColors";
import { t, tMap } from "../../../../features/i18n/translation";

export interface ExternalAnnotationProps {
  thumbnail: AssetMinimumInfo | null;
  onAnnotate: () => Promise<void>;
}

const Container = styled.div`
  padding: 0 16px 0 0;
`

const InfoPanel = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 10px;

  .ai-annotate-icon {
    margin-top: 4px;
  }
`

export default function ExternalAnnotation(props: ExternalAnnotationProps) {
  const { thumbnail, onAnnotate } = props;
  const canSendAnnotate = useMemo(() => {
    if (thumbnail && thumbnail.fileLink) return true;
    return false;
  }, [thumbnail])

  const handleAnnotation = useCallback(async () => {
    await onAnnotate();
  }, [onAnnotate])

  return (
    <Container>
      <Popconfirm 
        overlayInnerStyle={{maxWidth: 300}}
        title={t(tMap["product.detail.externalAnnotationConfirmTitle"])}
        okText={t(tMap["common.process"])}
        onConfirm={handleAnnotation}
        description={t(tMap["product.detail.externalAnnotationConfirmDescription"])}>
        <Button 
          disabled={!canSendAnnotate}
          style={{width: '100%'}}>
          <Flex align="center" justify="center" gap={5}>
            <AinIcon icon="external-annotation"/> AI Annotation
          </Flex>
        </Button>
      </Popconfirm>
      <InfoPanel style={{color: themeColors.grey6}}>
        <InfoCircleOutlined size={50} className="ai-annotate-icon"/>
        <span>
          { canSendAnnotate ? t(tMap["product.edit.externalAnnotationButton"]) : t(tMap["product.edit.externalAnnotationButtonDisabled"]) }
        </span>
      </InfoPanel>
    </Container>
  )
}