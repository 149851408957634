import { AttributeDataTypeEnum } from "@am/models/enums/attributeDataTypeEnum";
import { AttributeInputTypeEnum } from "@am/models/enums/attributeInputTypeEnum";
import { Labels } from "../../../common/domain/valueObjects/Label";
import { AttributeStatusEnum } from "@am/models/enums/attributeStatus";
import { CategoryEntity } from "@cm/domain/entities/categoryEntity";
import { IUserEntitiy } from "../../../common/domain/entities/UserEntitiy";
import { AinDate } from "../../../common/types/commonTypes";
import { Asset } from "@shared/entities/Asset";

export enum ProductTypeEnum {
  "main" = "main",
  "variant" = "variant"
}

export interface MaterialType {
  material: string;
  parts: number;
}

export interface ProductAttribute {
  [key: string]: any | null;
  // name: Labels[];
  // gtin: string;
  // attributeGenericMaterialFashion: {
  //   materialCompositionPerLocation: {
  //     location: string;
  //     locationPartsMaterial: MaterialType[];
  //   }[];
  // };
}

export interface AssetMinimumInfo {
  id: string;
  fileName: string;
  fileLink: string;
}

export interface GetProductResponse {
  id: string;
  name: string | Labels[];
  thumbnail: AssetMinimumInfo | null;
  attributes: ProductAttribute;
  categories: Pick<CategoryEntity, "id" | "name" | "appearanceName">[];
  locales: (Labels & { completeness: number })[];
  status: AttributeStatusEnum;
  type: ProductTypeEnum;
  updatedBy: IUserEntitiy;
  createdAt: AinDate;
  updatedAt: AinDate;
}

/** mapped form `GetProductsResponse_ProductItem` */
export interface ProductEntity {
  id: string;
  customerId?: string;
  description: string;
  mainProductId?: string | null;
  locales: (Labels & { completeness: number })[];
  name: string | Labels[];
  categories: Pick<
    CategoryEntity,
    "id" | "name" | "appearanceName" | "categoryPath"
  >[];
  status: AttributeStatusEnum;
  attributes: ProductAttribute;
  type: ProductTypeEnum;
  updatedBy: IUserEntitiy;
  createdAt: AinDate;
  updatedAt: AinDate;
  thumbnail: AssetMinimumInfo | null;
  isHavingDraft: boolean;
  isBlocked: boolean;
  variantCount: number;
}

export interface ProductColumn {
  name: string;
  description: string;
  label: Labels[];
  dataType: AttributeDataTypeEnum;
  isMultipleValues: boolean;
  inputType: AttributeInputTypeEnum;
  default: null;
  isUnique: boolean;
}

export interface ProductResponse {
  total: number;
  columns: Record<string, ProductColumn>;
  items: ProductEntity[];
}
