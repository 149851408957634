import { Form, FormInstance } from "antd";
import "./tableEditableRow.scss";
import React from "react";

export const EditableContext = React.createContext<FormInstance<any> | null>(
  null
);

interface Props {}

export const TableEditableRow: React.FC<Props> = (props): JSX.Element => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false} className="table-editable-row">
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export default TableEditableRow;
