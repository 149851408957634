export enum AttributeTypesEnum {
  common = "common",
  master = "master",
  system = "system"
}

export enum AttributeReadModeEnum {
  read = "read",
  edit = "edit"
}
export type AttributeReadMode = keyof typeof AttributeReadModeEnum;

export type ListenerArgs<T = null> = {
  count: number;
  payload?: T;
  callback?: () => void;
};
export const defaultArg: ListenerArgs = {
  count: 0,
  payload: null
};
