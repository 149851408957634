import "./generalInformation.scss";
import { Col, Form, Input, Row } from "antd";
import { AinIcon } from "../../../../../../common/ui/atoms/AinIcon";
import { useEffect, useRef, useState } from "react";
import { EditLocalsPopover } from "../../../../../../common/ui/molecules/popovers/editLocalsPopover/EditLocalsPopover";
import { CategoryEntity } from "../../../../domain/entities/categoryEntity";
import { useCategoryPageState } from "../../../../domain/hooks/useCategoryPageState";
import { Labels } from "../../../../../../common/domain/valueObjects/Label";
import { t, tMap } from "../../../../../../features/i18n/translation";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";

interface Props {}

export const GeneralInformation: React.FC<Props> = (): JSX.Element => {
  const editLocalsPopoverRef = useRef<any>(null);
  const [form] = Form.useForm<CategoryEntity>();
  const [state, setState] = useCategoryPageState();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    editLocalsPopoverRef.current.setIsOpen(false);
  }, [state.currentCategory]);

  useEffect(() => {
    setIsEditing(false);
  }, [state.currentCategory]);

  const handleEditLabelsConfirmed = (labels: Labels[]) => {
    if (state.currentCategory) {
      setState({
        currentCategory: {
          ...state.currentCategory,
          appearanceName: labels
        },
        updatedCategory: {
          ...state.updatedCategory,
          appearanceName: labels
        }
      });
    }

    editLocalsPopoverRef.current.setIsOpen(false);
  };

  const handleEditClicked = () => {
    setIsEditing(true);
    form.setFieldValue("name", state.currentCategory?.name);
    form.setFieldValue("description", state.currentCategory?.description);
  };

  const viewModeElements = (
    <Col className="view-mode-elements" span={22} onClick={handleEditClicked}>
      <div data-test={dMap["category-name"]} className="category-name">
        {state.currentCategory?.name}
      </div>
      <div>{state.currentCategory?.description}</div>
    </Col>
  );

  const editModeElements = (
    <>
      <Col span={22}>
        <Form.Item<CategoryEntity>
          name="name"
          rules={[
            {
              required: true,
              message: t(tMap["category.createNew.nameRequireMessage"])
            }
          ]}
        >
          <Input
            className="category-name"
            placeholder={t(tMap["category.createNew.name"])}
          />
        </Form.Item>
      </Col>
      <Col span={22} className="edit-description">
        <Form.Item<CategoryEntity> name="description">
          <Input.TextArea
            rows={1}
            placeholder={t(tMap["category.createNew.descriptionPlaceholder"])}
          />
        </Form.Item>
      </Col>
    </>
  );

  const handleValuesChanged = async (values: CategoryEntity) => {
    setTimeout(() => {
      if (form.getFieldsError().every((x) => !x.errors.length)) {
        setState({
          updatedCategory: {
            ...state.updatedCategory,
            ...values
          }
        });
      }
    });
  };

  const parent = state.allCategories?.find(
    (x) => x.id === state.currentCategory?.parentId
  );

  return (
    <Form
      className="general-information"
      name="category-general"
      form={form}
      onValuesChange={handleValuesChanged}
    >
      <Row>
        {isEditing ? editModeElements : viewModeElements}
        <Col span={2} className="action-buttons">
          {isEditing ? null : (
            <AinIcon icon="edit-filled" size={20} onClick={handleEditClicked} />
          )}
          <EditLocalsPopover
            ref={editLocalsPopoverRef}
            onConfirm={handleEditLabelsConfirmed}
            labels={state.currentCategory?.appearanceName}
          >
            <AinIcon icon="translation-outlined" size={20} />
          </EditLocalsPopover>
        </Col>
        <Col span={4} className="label first-row">
          {t(tMap["category.info.created"])}
        </Col>
        <Col span={8} className="first-row">
          {new Date(state.currentCategory?.createdAt ?? 0).toLocaleString()}
        </Col>
        <Col span={4} className="label first-row">
          {t(tMap["category.info.categoryId"])}
        </Col>
        <Col span={8} className="first-row">
          {state.currentCategory?.id}
        </Col>
        <Col span={4} className="label">
          {t(tMap["category.info.lastUpdated"])}
        </Col>
        <Col span={8}>
          {new Date(state.currentCategory?.updatedAt ?? 0).toLocaleString()}
        </Col>
        <Col span={4} className="label">
          {t(tMap["category.info.parent"])}
        </Col>
        <Col span={8}>{parent?.name}</Col>
      </Row>
    </Form>
  );
};

export default GeneralInformation;
