import styled, { css } from "styled-components";
import EditorViewMode from "./EditorViewMode";

const MultipleEditorEditMode = styled(EditorViewMode)<{ isRequired?: boolean }>`
  .delete-icon {
    margin-left: 8px;
    vertical-align: middle;
    cursor: pointer;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  ${({ isRequired }) =>
    isRequired
      ? css`
          .name:before {
            display: inline-block;
            margin-inline-end: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: "*";
          }
        `
      : null}
`;

export default MultipleEditorEditMode;
