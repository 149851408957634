import { AliasToken } from "antd/es/theme/internal";

type TokenType = Partial<AliasToken>;

export const colors = {
  white1: "#FFFFFF",
  white085: "rgba(255, 255, 255, 0.85)",
  white045: "rgba(255, 255, 255, 0.45)",
  white030: "rgba(255, 255, 255, 0.30)",
  white020: "rgba(255, 255, 255, 0.20)",
  white012: "rgba(255, 255, 255, 0.12)",
  white008: "rgba(255, 255, 255, 0.08)",
  white004: "rgba(255, 255, 255, 0.04)",

  black1: "rgba(20, 20, 20)", // '#141414',
  black085: "rgba(20, 20, 20, 0.85)",
  black045: "rgba(20, 20, 20, 0.45)",
  black025: "rgba(20, 20, 20, 0.25)",
  black015: "rgba(20, 20, 20, 0.15)",
  black006: "rgba(20, 20, 20, 0.06)",
  black004: "rgba(20, 20, 20, 0.04)",
  black002: "rgba(20, 20, 20, 0.02)",

  grey1: "#FFFFFF",
  grey2: "#FAFAFA",
  grey3: "#F5F5F5",
  grey4: "#D9D9D9",
  grey5: "#BFBFBF",
  grey6: "#8C8C8C",
  grey7: "#595959",
  grey8: "#434343",
  grey9: "#262626",
  grey10: "#002329",

  red1: "#fff1f0",
  red2: "#ffccc7",
  red3: "#ffa39e",
  red4: "#ff7875",
  red5: "#ff4d4f",
  red6: "#f5222d",
  red7: "#cf1322",
  red8: "#a8071a",
  red9: "#820014",
  red10: "0#5c0011",

  cyan1: "#ebfffb",
  cyan2: "#DBFFF7",
  cyan3: "#87e8de",
  cyan4: "#5cdbd3",
  cyan5: "#36cfc9",
  cyan6: "#13c2c2",
  cyan7: "#08979c",
  cyan8: "#006d75",
  cyan9: "#00474f",
  cyan10: "#002329"
};

export const colorSeedTokens: TokenType = {
  colorTextBase: "#000",
  colorTextLightSolid: colors.white1,
  colorTextSecondary: "#000000A6",
  colorTextDescription: colors.black045,
  colorBgBase: colors.white1,
  colorPrimary: colors.cyan7,
  colorError: colors.red5,
  colorWarning: "#faad14",
  colorSuccess: "#52c41a",
  colorInfo: colors.cyan7
};

export const backgrounds: TokenType = {
  colorBgContainer: colors.white1,
  colorBgContainerDisabled: "#0000000a",
  colorBgLayout: colors.grey3,
  colorBgElevated: colors.white1,
  controlItemBgHover: "#0000000a",
  controlItemBgActive: "#e6fffb",
  controlItemBgActiveHover: "#DBFFF7"
};

export const borders: TokenType = {
  colorBorder: "#00000026",
  colorBorderSecondary: "#0000000f",
  colorSplit: "#0000000f"
};

export const themeColors = {
  ...colors,
  ...colorSeedTokens,
  ...backgrounds,
  ...borders,
  colorPrimaryBg: colors.cyan1,
  colorPrimaryBgHover: colors.cyan2, //"#DBFFF7",
  colorPrimaryBorder: colors.cyan3, //"#87e8de",
  colorPrimaryBorderHover: colors.cyan4, //"#5cdbd3",
  colorPrimaryHover: colors.cyan5, //"#36cfc9",
  colorPrimary: colors.cyan6, //"#13c2c2",
  colorPrimaryActive: colors.cyan7, //"#08979c",
  // colorPrimaryTextHover: colors. ,//"#006475",
  colorPrimaryText: colors.cyan9, //"#00474f",
  colorPrimaryTextActive: colors.cyan10 //"#002329",
};
