import { Tag, TreeSelect } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { t, tMap } from "../../../features/i18n/translation";
import { CategoryListResponse } from "@cm/domain/valueObjects/categoryListResponse";
import { LabeledValue } from "antd/es/select";
import { DataNode } from "antd/es/tree";
import { styled } from "styled-components";

interface Props {
  isMultiple?: boolean;
  allCategories?: CategoryListResponse[];
  onChange?: (value: any) => void;
  categoryIds?: string[];
}

const TagContainer = styled.div`
  margin-top: 10px;

  .ant-tag {
    margin-bottom: 5px;
  }
`;

const convertToTree = (
  categories: CategoryListResponse[],
  parentId?: string
): DataNode[] => {
  const filteredCategories = categories.filter(
    (category) => category.parentId === parentId
  );

  return filteredCategories.map((category) => ({
    key: category.id,
    value: category.id,
    title: category.name,
    children: convertToTree(categories, category.id)
  }));
};

const CategoryTreeSelect: React.FC<Props> = ({
  isMultiple,
  onChange,
  allCategories,
  categoryIds
}) => {
  const [selectedCategoryNodes, setSelectedCategoryNodes] = useState<
    LabeledValue[]
  >([]);

  const categoryTree = useMemo(() => {
    if (!allCategories) {
      return undefined;
    }
    const baseCategory = allCategories.find((x) => x.isBaseCategory === true);
    const nonBaseCategory = allCategories.filter(
      (x) => x.isBaseCategory === false
    );
    return convertToTree(nonBaseCategory, baseCategory?.id);
  }, [allCategories]);

  const handleSetSelectedCategories = useCallback(
    (v: LabeledValue[]) => {
      if (!isMultiple) {
        setSelectedCategoryNodes([v as any]);
        onChange && onChange(v);
        return;
      }

      // we keep the ordering, last category will be added to bottom, find newly added one
      const newCatIdx = v.findIndex((x) =>
        selectedCategoryNodes.every((c) => c.value !== x.value)
      );
      if (newCatIdx >= 0) {
        v.push(v.splice(newCatIdx, 1)[0]); // move added item to bottom
      }

      setSelectedCategoryNodes(v);
      onChange && onChange(v.map((x) => x.value));
    },
    [selectedCategoryNodes]
  );

  useEffect(
    () =>
      setSelectedCategoryNodes(
        categoryIds?.map((x) => ({ label: "", value: x })) ?? []
      ),
    [categoryIds]
  );

  const selectedCategoryIds = useMemo(
    () => selectedCategoryNodes.map<string>((x) => x.value as string),
    [selectedCategoryNodes]
  );

  const selectedCategories = useMemo(() => {
    return (
      selectedCategoryIds?.flatMap(
        (x) => allCategories?.find((c) => c.id === x) ?? []
      ) ?? []
    );
    // return allCategories?.filter(x => selectedCategoryIds.includes(x.id)) ?? []
  }, [selectedCategoryIds, allCategories]);

  const handleRemoveCategory = (id: string) => {
    setSelectedCategoryNodes((v) => {
      const categories = v.filter((x) => x.value !== id);
      onChange && onChange(categories.map((x) => x.value));

      return categories;
    });
  };

  const treeFilter = useCallback((input: string, treeNode: any) => {
    const text =
      treeNode.title?.toString() ??
      "" + " " + (treeNode.value ?? "").toString();
    return text.toLowerCase().includes(input.toLowerCase());
  }, []);

  return (
    <>
      <TreeSelect
        placeholder={t(
          allCategories
            ? tMap["product.createNew.categoriesPlaceholder"]
            : tMap["common.loading"]
        )}
        treeCheckable={isMultiple}
        treeCheckStrictly={isMultiple}
        showCheckedStrategy="SHOW_CHILD"
        value={
          isMultiple ? selectedCategoryNodes : (selectedCategoryNodes[0] as any)
        }
        treeData={categoryTree}
        filterTreeNode={treeFilter}
        onChange={handleSetSelectedCategories}
        style={{ width: "100%" }}
        tagRender={isMultiple ? () => <></> : undefined}
      />
      {!isMultiple ? null : (
        <>
          <small>{tMap["product.edit.categoriesNotice"]}</small>
          <TagContainer>
            {selectedCategories.map((x) => (
              <Tag
                key={x.id}
                closable
                onClose={() => handleRemoveCategory(x.id)}
              >
                {x.name}
              </Tag>
            ))}
          </TagContainer>
        </>
      )}
    </>
  );
};

export default CategoryTreeSelect;
