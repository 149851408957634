import type React from "react";
import { Select } from "antd";
import { yesNoOptions } from "./SelectOptions";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";

export const BooleanSelect: React.FC<{ booleanValue: React.ReactNode }> = ({
  booleanValue,
  /**
   * If the element is used as part of a Form.Item, then have to pass in props from parent
   * https://stackoverflow.com/questions/64559702/ant-design-does-not-detect-custom-input-component-in-form-item-react
   */
  ...rest
}): JSX.Element => {
  const defaultValue =
    yesNoOptions.find((option) => option.label === booleanValue) ??
    yesNoOptions[1];
  return (
    <Select
      data-test={dMap["select"]}
      defaultValue={defaultValue}
      options={yesNoOptions}
      {...rest}
    />
  );
};
