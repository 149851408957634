import { PictureFilled } from "@ant-design/icons";
import { AssetMinimumInfo } from "@pm/models/ProductEntity";
import { LabelText } from "@shared/ui/atoms/LabelText";
import React from "react";
import styled from "styled-components";

interface Props {
  name: string;
  attributes: { label?: string; value?: string }[];
  thumbnail: AssetMinimumInfo | null;
}

const Container = styled.div`
  .thumbnail {
    width: 100px;
    display: inline-block;
    text-align: center;

    img {
      max-width: 100px;
      max-height: 100px;
      width: 100%;
      vertical-align: middle;
    }

    .anticon-picture {
      font-size: 24px;
      color: #818181;
      vertical-align: middle;
    }

    @media screen and (max-width: 1100px) {
      width: 50px;
    }
  }

  .attributes {
    margin-left: 8px;
    display: inline-block;
    color: #000000a6;
    vertical-align: middle;

    .attribute {
      font-size: 12px;

      label {
        margin-right: 8px;
      }
    }
  }
`;

export const VariantListItem: React.FC<Props> = ({
  name,
  thumbnail,
  attributes
}) => {
  return (
    <Container>
      <span className="thumbnail">
        {thumbnail ? (
          <img src={thumbnail?.fileLink} alt={thumbnail?.fileName} />
        ) : (
          <PictureFilled />
        )}
      </span>
      <span className="attributes">
        <LabelText>{name}</LabelText>
        {attributes.map((x, i) => (
          <div className="attribute" key={i}>
            <LabelText>{x.label}</LabelText>
            <span>{x.value}</span>
          </div>
        ))}
      </span>
    </Container>
  );
};
