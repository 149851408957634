import { AttributeEntity } from "@am/models/entities/attributeEntity";
import { AttributeTemplate } from "@am/models/valueObjects/attributeTemplate";

export function walkAttributeTemplate(
  attribute: AttributeEntity,
  visitor?: (element: AttributeTemplate) => AttributeTemplate | undefined | void
) {
  recursive(attribute);

  function recursive(attribute: AttributeTemplate) {
    if (!visitor) return;
    attribute.template?.forEach((element) => {
      visitor(element);
      recursive(element);
    });
  }
}
