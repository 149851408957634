import React from "react";
import { Typography } from "antd";
import ProductsTable from "./productsTable/ProductsTable";
import { ProductEntity } from "@pm/models/ProductEntity";
import { t, tMap } from "../../../../../features/i18n/translation";
import { styled } from "styled-components";
import { themeColors } from "../../../../../common/ui/styles/themeColors";

const headerHeight = 46;

const StyledProductsPage = styled.div`
  height: 100%;
  border-left: 1px solid ${themeColors.grey4};
  .header-container {
    padding: 16px 16px 8px 16px;
  }
  .products-table-container {
    height: calc(100% - ${headerHeight}px);
  }
`;

const ProductsPage: React.FC<{ products: ProductEntity[] }> = ({
  products
}): JSX.Element => {
  return (
    <StyledProductsPage className="StyledProductsPage">
      <section className="header-container">
        <Typography.Text>{t(tMap["product.tree.allProducts"])}</Typography.Text>
      </section>

      <section className="products-table-container">
        <ProductsTable dataSource={products} />
      </section>
    </StyledProductsPage>
  );
};

export default ProductsPage;
