import { productsApiService } from "@pm/api/ProductsApiService";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";

export const currentProductIdAtom = atom("");

export const productEntityStateAtom = atomWithQuery((get) => ({
  queryKey: ["entity", get(currentProductIdAtom)],
  queryFn: async ({ queryKey: [_, id] }) => {
    if (!id) {
      return;
    }

    return productsApiService.getProduct(id as string);
  }
}));
