export const SelectionSvg: React.FC<{
  className: string;
}> = ({ className }): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 5C3.25 4.58579 3.58579 4.25 4 4.25H20C20.4142 4.25 20.75 4.58579 20.75 5C20.75 5.41421 20.4142 5.75 20 5.75H4C3.58579 5.75 3.25 5.41421 3.25 5ZM3.40004 13.55C3.64857 13.2186 4.11867 13.1515 4.45004 13.4L12 19.0625L19.55 13.4C19.8814 13.1515 20.3515 13.2186 20.6 13.55C20.8486 13.8814 20.7814 14.3515 20.45 14.6L12.45 20.6C12.1834 20.8 11.8167 20.8 11.55 20.6L3.55004 14.6C3.21867 14.3515 3.15152 13.8814 3.40004 13.55ZM4 8.25C3.58579 8.25 3.25 8.58579 3.25 9C3.25 9.41421 3.58579 9.75 4 9.75H20C20.4142 9.75 20.75 9.41421 20.75 9C20.75 8.58579 20.4142 8.25 20 8.25H4Z"
      fill="#333333"
    />
  </svg>
);
