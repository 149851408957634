import { useSetAtom } from "jotai";
import { atomWithListeners } from "../../../common/hooks/state/atomWithListeners";

const [draftSavedClickedAtom, useDraftSavedClickedListener] =
  atomWithListeners(0);
export const useDispatchDraftSavedClicked = () => {
  return {
    dispatchDraftSavedClicked: useSetAtom(draftSavedClickedAtom)
  };
};

export { useDraftSavedClickedListener };
