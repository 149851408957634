import { guardFunction } from "../../modules/guardFunction";
import { getJsType } from "../../ui/organisms/form/getJsType";

export type Labels = {
  locale: string;
  /**
   * Note! It can happen, that value has the type `Record<string, unknown>` in the case of inputType=form dataType=object;
   * Imo
   * 1. the type should be `string | Record<string, unknown>`
   * 2. there should be proper type guard for subsequent uses in the code
   */
  value: string;
  channel?: string;
};

export const isLabelGuard = guardFunction<Labels>((value) => {
  if (!value) return false;
  if (getJsType(value).isPrimitive) return false;
  return "locale" in value && "value" in value;
});

export const isLabelGuardArray = guardFunction<Labels[]>((value) => {
  if (!value) return false;
  if (!Array.isArray(value)) return false;
  if (value.length === 0) return false;
  const is = isLabelGuard(value[0]);
  return is;
});
