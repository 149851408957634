export const FormSvg: React.FC<{
  className: string;
}> = ({ className }): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7708 12.5H20.4583C20.3552 12.5 20.2708 12.5844 20.2708 12.6875V20.1875H4.89584V4.8125H12.3958C12.499 4.8125 12.5833 4.72812 12.5833 4.625V3.3125C12.5833 3.20937 12.499 3.125 12.3958 3.125H3.95834C3.5435 3.125 3.20834 3.46016 3.20834 3.875V21.125C3.20834 21.5398 3.5435 21.875 3.95834 21.875H21.2083C21.6232 21.875 21.9583 21.5398 21.9583 21.125V12.6875C21.9583 12.5844 21.874 12.5 21.7708 12.5Z"
      fill="#333333"
    />
    <path
      d="M8.92564 13.0367L8.88111 15.8234C8.87877 16.032 9.04752 16.2031 9.25611 16.2031H9.26549L12.0311 16.1352C12.078 16.1328 12.1249 16.1141 12.1577 16.0812L21.9053 6.35469C21.978 6.28203 21.978 6.1625 21.9053 6.08984L18.9921 3.17891C18.9546 3.14141 18.9077 3.125 18.8585 3.125C18.8092 3.125 18.7624 3.14375 18.7249 3.17891L8.97955 12.9055C8.9457 12.9409 8.92645 12.9877 8.92564 13.0367ZM10.4139 13.5898L18.8585 5.16406L19.9178 6.22109L11.4686 14.6516L10.3975 14.6773L10.4139 13.5898Z"
      fill="#333333"
    />
  </svg>
);
