import { generateId } from "../../../../common/modules/debugging/generateId";
import { AttributeEntity } from "../../models/entities/attributeEntity";
import { walkAttributeTemplate } from "./walkAttributeTemplate";

/**
 * Workaround for templates not having a unique identifier.
 * Need unique id, in order to manipulate template items (and nested ones)
 */
export function addIdsToTemplates(attribute: AttributeEntity | null) {
  if (!attribute) return attribute;

  walkAttributeTemplate(attribute, (element) => {
    if (element.id) return;
    element.id = generateId();
  });
  return attribute;
}
