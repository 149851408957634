export const UploaderSvg: React.FC<{
  className: string;
}> = ({ className }): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M10.125 7.94687H11.8571V15.8758C11.8571 15.9789 11.9414 16.0633 12.0446 16.0633H13.4508C13.5539 16.0633 13.6383 15.9789 13.6383 15.8758V7.94687H15.375C15.5321 7.94687 15.6188 7.7664 15.5227 7.64452L12.8977 4.32109C12.8801 4.29868 12.8577 4.28055 12.8322 4.26809C12.8066 4.25562 12.7785 4.24915 12.75 4.24915C12.7216 4.24915 12.6935 4.25562 12.6679 4.26809C12.6423 4.28055 12.6199 4.29868 12.6024 4.32109L9.97737 7.64218C9.88128 7.7664 9.968 7.94687 10.125 7.94687ZM21.3282 15.1727H19.9219C19.8188 15.1727 19.7344 15.257 19.7344 15.3602V18.9695H5.76566V15.3602C5.76566 15.257 5.68128 15.1727 5.57816 15.1727H4.17191C4.06878 15.1727 3.98441 15.257 3.98441 15.3602V20.0008C3.98441 20.4156 4.31956 20.7508 4.73441 20.7508H20.7657C21.1805 20.7508 21.5157 20.4156 21.5157 20.0008V15.3602C21.5157 15.257 21.4313 15.1727 21.3282 15.1727Z"
      fill="#333333"
    />
  </svg>
);
