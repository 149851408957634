import {
  AuthProvider,
  TAuthConfig,
  TRefreshTokenExpiredEvent
} from "react-oauth2-code-pkce";
import { debugFlags } from "../../common/modules/debugging/debugFlags";
import { PropsWithChildren } from "react";

export const AppAuthentication: React.FC<PropsWithChildren> = ({
  children
}): JSX.Element => {
  const authConfig: TAuthConfig = {
    clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
    authorizationEndpoint: `${
      import.meta.env.VITE_AUTH_ENDPOINT
    }/B2C_1A_SIGNIN/oauth2/v2.0/authorize`,
    tokenEndpoint: `${
      import.meta.env.VITE_AUTH_ENDPOINT
    }/B2C_1A_SIGNIN/oauth2/v2.0/token`,
    logoutEndpoint: `${
      import.meta.env.VITE_AUTH_ENDPOINT
    }/B2C_1A_SIGNIN/oauth2/v2.0/logout`,
    redirectUri: import.meta.env.VITE_APP_URL,
    scope: import.meta.env.VITE_AUTH_SCOPES,
    extraAuthParameters: {
      p: "B2C_1A_SIGNIN"
    },
    onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
      window.confirm(
        "Session expired. Refresh page to continue using the site?"
      ) && event.login()
  };

  return debugFlags.isLocalAuthentication ? (
    <>{children}</>
  ) : (
    <AuthProvider authConfig={authConfig}>{children}</AuthProvider>
  );
};
