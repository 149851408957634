import { AttributeDataTypeEnum } from "@am/models/enums/attributeDataTypeEnum";
import { AttributeInputTypeEnum } from "@am/models/enums/attributeInputTypeEnum";
import { AttributeTemplate } from "@am/models/valueObjects/attributeTemplate";
import { ProductEntity } from "@pm/models/ProductEntity";
import {
  ProductAttributesMap,
  PartialAttributeTemplate
} from "@pm/types/ProductAttributesMap";
import { cloneDeep } from "lodash";
import { getJsType } from "./getJsType";
import { isLabelGuard } from "../../../domain/valueObjects/Label";
import { isMultipleValuesTemplate } from "@am/common/businessLogic/isMultipleValuesTemplate";
import { mergeWithOtherLocales } from "@pm/helpers/mergeWithOtherLocales";
import { DefaultOptionType } from "antd/es/select";

export function getSettingType(
  template: Pick<
    AttributeTemplate,
    "isMultipleValues" | "inputType" | "dataType"
  > | null
) {
  const isArray = isMultipleValuesTemplate(template);
  const isObject =
    template?.inputType === AttributeInputTypeEnum.form &&
    template?.dataType === AttributeDataTypeEnum.object;
  const isPrimitive = !isArray && !isObject;
  return {
    isArray,
    isObject,
    isPrimitive
  };
}

/**
 * Template can be `null` or empty array.
 * Thus have this function to check what we call "empty".
 */
function isTemplateEmpty(template: PartialAttributeTemplate) {
  const isNull = template.template === null;
  const isEmptyArray =
    Array.isArray(template.template) && template.template.length === 0;
  const is = isNull || isEmptyArray;
  return is;
}

export function buildFormStructure(
  product: Pick<ProductEntity, "attributes">,
  productAttributesMap: ProductAttributesMap,
  localOptions?: DefaultOptionType[]
) {
  const result = {
    attributes: cloneDeep(product.attributes)
  };
  Object.entries(productAttributesMap).map(
    ([attributeName, initialMappingData]) => {
      recursive(initialMappingData, attributeName, result.attributes);
    }
  );

  return result;

  function recursive(
    template: PartialAttributeTemplate | PartialAttributeTemplate[] | null,
    attributeName: string,
    result: Record<string, unknown>
  ) {
    if (template == null) return;
    if (Array.isArray(template)) return;

    const { isArray: isArrayParentLevel } = getSettingType(template);
    const templateFields: Record<string, unknown> = {};

    /** TODO: can happen, that there are partial values, so need to partially init with [] or null */
    const isEmptyArray =
      Array.isArray(result?.[attributeName]) &&
      (result[attributeName] as any[]).length === 0;
    if (!result?.[attributeName] || isEmptyArray) {
      const labels = mergeWithOtherLocales([], localOptions ?? []);

      if (isArrayParentLevel) {
        if (result) {
          if (!Array.isArray(result)) {
            if (isLabelGuard(result)) {
              if (!result.value) {
                // @ts-expect-error casting to object
                result.value = {};
              }
              // @ts-expect-error casting to object
              result.value[attributeName] = [];
            } else {
              result[attributeName] = [];
            }
          }
          if (template.isLocaleDependent) {
            if (result[attributeName]) {
              (result[attributeName] as any[]).push(cloneDeep(labels));
            }
          } else if (!isTemplateEmpty(template)) {
            if (result[attributeName]) {
              (result[attributeName] as any[]).push(templateFields);
            }
          }
        }
      } else {
        const { isArray } = getJsType(result);
        if (isArray) {
          // do nothing
        } else if (isTemplateEmpty(template)) {
          if (isLabelGuard(result)) {
            if (!result.value) {
              result.value = {} as any;
              // @ts-expect-error casting to object
              result.value[attributeName] = null;
            }
          } else if (result) {
            if (!result[attributeName]) {
              result[attributeName] = null;
            }
          }
        } else {
          if (template.isLocaleDependent) {
            result[attributeName] = cloneDeep(labels);
          } else if (!isLabelGuard(result)) {
            result[attributeName] = {} as Record<string, unknown>;
          }
        }
      }
    }

    if (!result) return;

    template?.template?.forEach((templateDefinition) => {
      recursive(
        templateDefinition,
        templateDefinition.name,
        result[attributeName] as any
      );
    });

    if (!Array.isArray(result)) return;

    result.forEach((resultItem) => {
      recursive(template, template.name, resultItem);
    });
  }
}
