export const attributeConstants = {
  api: {
    base: "attribute"
  },
  forms: {
    "add-new-input-popover": "add-new-input-popover",
    "create-attribute": "create-attribute"
  },
  keySeparator: "|",
  childRouterSelector: "*",
  code: {
    debounceTime: 1000
  }
};
