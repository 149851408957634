import { Select } from "antd";
import { formSelectionTypeUiMapping } from "../../../../../common/helpers/attributeUiMappings";
import { FormColumn } from "../formColumnType";

export const formSelectionTypeRender: Pick<FormColumn, "render"> = {
  render: (value) => {
    const finalOptions = Object.entries(formSelectionTypeUiMapping()).map(
      ([attributeType, data]) => ({
        value: attributeType,
        label: data?.label
      })
    );

    const defaultValue = finalOptions.find((option) => option.label === value);
    return <Select defaultValue={defaultValue} options={finalOptions} />;
  }
};
