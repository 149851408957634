import { DownOutlined } from "@ant-design/icons";
import { Divider, Dropdown, MenuProps, Space } from "antd";
import React from "react";
import { themeColors } from "../../../../common/ui/styles/themeColors";
import { useLocaleOptions } from "../../../../common/hooks/useLocaleOptions";
import { langToFlag } from "@pm/mock/flags";
import { FlagChip } from "./FlagChip";
import { productEntityStateAtom } from "@pm/state/productManagementEntityState";
import { useAtom } from "jotai";
import { styled } from "styled-components";
import { GetProductResponse } from "@pm/models/ProductEntity";
import { LocaleValues } from "../../../../common/types/commonTypes";
import { t, tMap } from "../../../../features/i18n/translation";

const StyledDropdownRender = styled.div`
  padding: 12px;
  width: 265px;
  .ant-dropdown-menu {
    padding: 12px;
    .ant-dropdown-menu-item {
      padding: unset;
      cursor: default;
      &:hover {
        background-color: unset;
      }
    }
  }
  .content-filled-dropdown-heading {
    font-weight: 600;
    background: ${themeColors.white1};
    margin-bottom: 8px;
  }
`;

const StyledDropdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .completness-text {
    font-weight: 600;
  }
`;

function getCompleteness(
  product: GetProductResponse | undefined,
  locale: LocaleValues | string | undefined
) {
  return product?.locales?.find(
    (localeCompleteness) => localeCompleteness.locale === locale
  )?.completeness;
}

interface Props {
  locale: string | undefined;
  product: GetProductResponse | undefined;
}
export const ContentFilledDropdown: React.FC<Props> = ({
  locale,
  product
}): JSX.Element => {
  const { localeOptions } = useLocaleOptions();
  const selectedCompleteness = getCompleteness(product, locale);
  const contentFilledText = `${t(
    tMap["product.details.contentFilled"]
  )}: ${selectedCompleteness}%`;
  const items = localeOptions.map(({ value, label }, index) => {
    const completeness = getCompleteness(product, String(value));
    return {
      key: value,
      label: (
        <>
          {index !== 0 && <Divider style={{ margin: "8px 0" }} />}
          <StyledDropdownItem>
            <Space>
              <FlagChip flag={langToFlag(value as any)} />
              {label}
            </Space>
            <span className="completness-text">{completeness}%</span>
          </StyledDropdownItem>
        </>
      )
    };
  }) as MenuProps["items"];
  const heading = {
    key: "heading",
    label: (
      <div className="content-filled-dropdown-heading">{contentFilledText}</div>
    )
  };
  items?.unshift(heading);

  return (
    <Dropdown
      menu={{ items }}
      dropdownRender={(menu) => (
        <StyledDropdownRender className="StyledDropdownRender">
          {React.cloneElement(menu as React.ReactElement)}
        </StyledDropdownRender>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <span className="content-filled-label">{contentFilledText}</span>
        <span className="content-filled-label-icon">
          <DownOutlined />
        </span>
      </a>
    </Dropdown>
  );
};
