export const routeConstants = {
  brands: "brands",
  categories: "categories",
  categoryDetail: "categories/:id",
  dam: "dam",
  setting: "setting",
  quick: "quick",
  storybook: "storybook",
  version: "version"
};
