import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled, { css } from "styled-components";
import { t, tMap } from "../../../../features/i18n/translation";

const Editor = styled(ReactQuill)<{ readOnly: boolean }>`
  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: 0;
  }

  .ql-container:not(.ql-disabled) {
    min-height: 117px;
  }

  .ql-toolbar.ql-snow {
    border-bottom: 1px solid #ccc;

    ${({ readOnly }) =>
      readOnly
        ? css`
            display: none;
          `
        : null}
  }
`;

type Props = {
  value?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  readOnly?: boolean;
};

const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    [{ align: [] }, "direction"],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    ["blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link"],
    ["clean"]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

const RichTextEditor: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  readOnly
}) => {
  const handleChanged = (value: string, _: any, __: any, editor: any) => {
    if (!onChange) {
      return;
    }

    const text: string = editor.getText().replace("\n", "");
    onChange(text ? value : text);
  };

  return (
    <Editor
      readOnly={readOnly}
      value={value ?? ""}
      modules={modules}
      onChange={handleChanged}
      placeholder={placeholder ?? t(tMap["common.typeHere"])}
      onKeyDown={(e) => e.stopPropagation()}
    />
  );
};

export default RichTextEditor;
