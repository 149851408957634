import { AttributeEntity } from "@am/models/entities/attributeEntity";
import { AttributeTypesEnum } from "@am/types/attributeCommonTypes";

export function getAttributeType(
  attributeEntity: AttributeEntity
): AttributeTypesEnum {
  if (attributeEntity.isSystemAttribute) return AttributeTypesEnum.system;
  if (attributeEntity.isMasterAttribute) {
    return AttributeTypesEnum.master;
  }

  return AttributeTypesEnum.common;
}

export function getAttributeTypeChecker(attributeEntity: AttributeEntity) {
  const attributeType = getAttributeType(attributeEntity);

  const isCommon = attributeType === AttributeTypesEnum.common;
  const isMaster = attributeType === AttributeTypesEnum.master;
  const isSystem = attributeType === AttributeTypesEnum.system;

  return {
    isCommon,
    isMaster,
    isSystem
  };
}
