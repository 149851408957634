import React, { useRef } from "react";
import { Layout } from "./Layout";
import { MenuBar } from "./header/MenuBar";
import { useAtom } from "jotai";
import ProductsPage from "./ProductsListPage";
import CategoryTree from "@pm/ui/organisms/CategoryTree";
import ProductsHeader from "./header/ProductsHeader";
import CreateProductModal from "@pm/ui/organisms/modals/CreatePropductModal";
import { productManagementListState } from "@pm/state/productManagementListState";
import CreateVariantModal from "@pm/ui/organisms/modals/CreateVariantModal";

export const ProductsListController: React.FC = () => {
  const [productsLoader] = useAtom(productManagementListState);
  const createProductModal = useRef<any>();
  const createVariantModal = useRef<any>();
  const { isError, data, error } = productsLoader;

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <>
      <Layout
        header={
          <ProductsHeader
            onNewProductClick={() => createProductModal.current.setIsOpen(true)}
            onNewVariantClick={() => createVariantModal.current.setIsOpen(true)}
          />
        }
        menubar={<MenuBar />}
        sidebar={<CategoryTree />}
        content={<ProductsPage products={data?.items ?? []} />}
      />
      <CreateProductModal ref={createProductModal} />
      <CreateVariantModal ref={createVariantModal} />
    </>
  );
};
