import { LanguageKey } from "./languages";
import { LocationKey } from "./locations";

export type FlagKey =
  | "de"
  | "gb"
  | "fr"
  | "it"
  | "es"
  | "us"
  | "ca"
  | "in"
  | "au"
  | "cn"
  | "vn"
  | "jp";

export const langToFlag = (lang: LanguageKey): FlagKey => {
  switch (lang) {
    case "de-DE":
      return "de";
    case "en-GB":
      return "gb";
    case "vi-VN":
      return "vn";
    case "fr":
      return "fr";
    case "it":
      return "it";
    case "es":
      return "es";
    case "hi":
      return "in";
    case "zh":
      return "cn";
    case "ja":
      return "jp";
    default:
      return "us";
  }
};

export const locationToFlag = (location: LocationKey): FlagKey => {
  switch (location) {
    case "de":
      return "de";
    case "ca":
      return "ca";
    case "fr":
      return "fr";
    case "us":
      return "us";
    case "uk":
      return "gb";
    case "au":
      return "au";
    case "in":
      return "in";
    case "cn":
      return "cn";
    case "jp":
      return "jp";
    default:
      return "us";
  }
};
