/**
 * @example
 * ```ts
 *  export const isApiErrorResponse = guardFunction<ApiErrorResponse>((value) => {
 *    if (!value) return false;
 *    return !!value.code || !!value.statusCode;
 *  });
 * ```
 */
export function guardFunction<T>(callback: (value: T) => boolean) {
  const check = (value: unknown): value is T => {
    return callback(value as T);
  };
  return check;
}
