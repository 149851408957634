import { Space, Row } from "antd";
import { FlagKey } from "../../mock/flags";

export interface FlagChipProps {
  flag: FlagKey;
  flagOnly?: boolean;
  marginTop?: number;
}
export const FlagChip: React.FC<FlagChipProps> = ({
  flag,
  flagOnly,
  marginTop
}): JSX.Element => {
  return (
    <Space align="center" style={{ marginTop: marginTop ?? 6 }}>
      {/* {flagOnly ? null : <div>{location[flag][lang]??''}</div>} */}
      <Row>
        <img width={24} src={`/flags/${flag}.svg`} />
      </Row>
    </Space>
  );
};
