export const attributesDebugFlags = {
  useLocalStorage: false,
  listing: {
    addMocks: false,
    useProductionMocks: false
  },
  creation: {
    newAttribute: {
      fillForm: false,
      openModal: false
    }
  },
  edition: {
    isEditMode: false
  }
};
