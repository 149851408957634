import { useUIStateReadOnly } from "@pm/hooks/useUIState";
import React from "react";
import styled from "styled-components";
import {
  backgrounds,
  themeColors
} from "../../../../../common/ui/styles/themeColors";
import { themeConstants } from "../../../../../common/ui/styles/themeConstants";

/**
 * Using CSS Grid to layout the page.
 *
 * the $ prefix is a styled-components syntax to indicate that this is a transient prop. (doesnt get passed to the DOM) https://styled-components.com/docs/faqs#transient-props-since-5.1
 */

const LayoutConstants = {
  headerHeight: `${themeConstants.headerHeight}px`,
  menubarHeight: `${themeConstants.menubarHeight}px`,
  sidebarWidth: "300px"
};

const transitionDuration = "0.3s";

interface LayoutContainerProps {
  $showSidebar: boolean;
}

const LayoutContainer = styled.div<LayoutContainerProps>`
  display: grid;
  grid-template-columns: ${({ $showSidebar }) =>
      $showSidebar ? LayoutConstants.sidebarWidth : "0px"} auto;
  grid-template-rows: ${LayoutConstants.headerHeight} ${LayoutConstants.menubarHeight} auto;
  grid-template-areas:
    "header header"
    "menubar menubar"
    "sidebar content";
  transition: all ${transitionDuration} ease-in-out;
  height: 100vh;
`;

const LayoutHeader = styled.div`
  grid-area: header;
  background-color: ${backgrounds.colorBgLayout};
`;

const LayoutMenubar = styled.div`
  grid-area: menubar;
  background-color: ${backgrounds.colorBgContainer};
  border-bottom: 1px solid ${themeColors.colorBorder};
`;

interface LayoutSidebarProps {
  $showSidebar: boolean;
}
const LayoutSidebar = styled.div<LayoutSidebarProps>`
  grid-area: sidebar;
  display: block;
  transition: all ${transitionDuration};
  transition-delay: all ${transitionDuration};
`;

const LayoutContent = styled.div<{ $isSidebarOpen?: boolean }>`
  grid-area: content;
  background-color: ${backgrounds.colorBgContainer};
  width: ${({ $isSidebarOpen }) => {
    const adjustWidth = $isSidebarOpen ? LayoutConstants.sidebarWidth : "0px";
    return `calc(100vw - ${adjustWidth} - 120px)`; // 120px navigation sidebar
  }};
`;

export interface LayoutProps {
  header: React.ReactNode;
  menubar: React.ReactNode;
  sidebar: React.ReactNode;
  content: React.ReactNode;
}
export const Layout: React.FC<LayoutProps> = ({
  header,
  menubar,
  sidebar,
  content
}) => {
  const { isSidebarOpen } = useUIStateReadOnly();
  return (
    <LayoutContainer $showSidebar={isSidebarOpen} className="LayoutContainer">
      <LayoutHeader>{header}</LayoutHeader>
      <LayoutMenubar>{menubar}</LayoutMenubar>
      <LayoutSidebar $showSidebar={isSidebarOpen}>{sidebar}</LayoutSidebar>
      <LayoutContent className="LayoutContent" $isSidebarOpen={isSidebarOpen}>
        {content}
      </LayoutContent>
    </LayoutContainer>
  );
};
