import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { Button, Col, Flex, Modal, Row, Space, Tag } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useAtom } from "jotai";
import { productEntityStateAtom } from "@pm/state/productManagementEntityState";
import { styled } from "styled-components";
import { themeColors } from "../../../../common/ui/styles/themeColors";
import { NavLink, useNavigate } from "react-router-dom";
import { productRouteConstants } from "@pm/productRoutes";
import { AinIcon } from "../../../../common/ui/atoms/AinIcon";
import { t, tMap } from "../../../../features/i18n/translation";
import { getDate } from "../../../../common/modules/dateFormat";
import { Labels } from "../../../../common/domain/valueObjects/Label";
import { commonConstants } from "../../../../common/constants/commonConstants";
import { useUIState } from "@pm/hooks/useUIState";
import { productsApiService } from "@pm/api/ProductsApiService";
import { isApiErrorResponse } from "../../../../common/domain/valueObjects/ApiErrorResponse";
import { showApiErrorNotification } from "../../../../common/modules/showNotifications";
import { routeConstants } from "../../../../features/routes/routeConstants";
import { useProductPublicationState } from "@pm/hooks/usePublicationState";
import ConfirmDeleteModal from "../../../../common/ui/molecules/modals/ConfirmDeleteModal";
import EditProductCategoryModal from "./modals/EditProductCategoryModal";
import { AttributeStatusEnum } from "@am/models/enums/attributeStatus";
import { publishProductSubscriber } from "@pm/hooks/usePublishProductPublisher";
import DisableDropdownButton from "../molecules/DisableDropdownButton";
import { useUpdateProductPublisher } from "@pm/hooks/useUpdateProductPublisher";
import { CategoryListResponse } from "@cm/domain/valueObjects/categoryListResponse";

const Container = styled.section`
  background: ${themeColors.grey3};
  border-bottom: 1px solid ${themeColors.colorBorder};
  padding: 24px;

  .header-breadcrumb-app-title {
    color: ${themeColors.colorTextDescription};
  }

  .product-name {
    font-weight: 600;
    font-size: 20px;
    color: #000000a6;
    margin: 12px 0 !important;
  }

  .categories {
    margin-right: 8px;
  }

  .label {
    font-weight: 600;
    color: #000000a6;
    min-width: 90px;
    display: inline-block;
  }

  .product-id {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(100% - 90px);
  }

  .status {
    background-color: #e6fffb;
    color: #08979c;
    text-transform: capitalize;
  }

  .ant-col {
    margin: 3px 0;
  }

  .ai-annotation {
    background-color: #ffffff;
    padding: 4px 8px;
    border-radius: 16px;
  }

  .action-buttons {
    text-align: right;

    .draft-text {
      margin-bottom: 8px;
    }

    .ant-dropdown-button {
      display: inline-block;
      width: initial;
      margin-left: 8px;
    }

    button:not(.ant-btn-compact-item) {
      margin-left: 8px;
    }
  }
`;

export const GeneralInformation: React.FC = () => {
  const navigate = useNavigate();
  const {
    uiState: { isEditMode, overwriteCategoryIds, allCategories, isFormUpdated },
    setIsEditMode,
    setNewCategories
  } = useUIState();
  const confirmDeleteModal = useRef<any>(null);
  const [productLoader] = useAtom(productEntityStateAtom);
  const updateProductPublisher = useUpdateProductPublisher();
  const { data } = productLoader;
  const {
    publicationState: { channels, locales }
  } = useProductPublicationState();

  const publishable = useMemo(() => {
    if (!channels || channels.length == 0) return false;
    return true;
  }, [channels]);

  const isBlocked = useMemo(() => data?.isBlocked === true, [data]);

  const [showEditCategory, setShowEditCategory] = useState(false);

  const productCategoryIds = useMemo(() => {
    return overwriteCategoryIds ?? data?.categories.map((x) => x.id) ?? [];
  }, [data, overwriteCategoryIds]);

  const productCategories = useMemo(() => {
    if (!allCategories) return data?.categories;
    return productCategoryIds
      .map((x) => allCategories.find((a) => x === a.id))
      .filter((x) => !!x) as CategoryListResponse[];
  }, [productCategoryIds, allCategories, data]);

  const updateCategory = useCallback((categoryIds: string[]) => {
    setNewCategories(categoryIds);
  }, []);

  useEffect(() => {
    return () => {
      setIsEditMode(false);
    };
  }, []);

  if (!data) {
    return null;
  }

  const handleConfirmDelete = async () => {
    const response = await productsApiService.deleteProduct(data?.id);
    confirmDeleteModal.current.setIsOpen(false);

    if (isApiErrorResponse(response)) {
      showApiErrorNotification(response);
    } else {
      setIsEditMode(false);
      navigate(`/${productRouteConstants.productList}`);
    }
  };

  const productName = (data.name as Labels[])?.find(
    (x) => x.locale === commonConstants.defaultLocale
  )?.value;

  const handlePublishClick = async () => {
    if (publishable) {
      updateProductPublisher(true);
      return;
    }

    Modal.error({
      title: tMap["product.edit.noChannel"],
      content: tMap["product.edit.noChannelContent"],
      okText: tMap["common.close"]
    });
  };

  publishProductSubscriber(async () => {
    // call publish product
    try {
      await productsApiService.publishProduct(
        data.id,
        locales.map((x) => x.code),
        channels
      );
    } catch (err: any) {
      console.log(err);
      Modal.error({
        title: tMap["common.apiErrorTitle"],
        content: err.message
      });
    }
  });

  const handleUnpublishClicked = async () => {
    await productsApiService.unpublishProduct(data.id);
  };

  const handleSaveDraftClicked = async () => {
    updateProductPublisher(false);
  };

  const handleDeleteDraftClicked = async () => {
    await productsApiService.discardProduct(data.id);
    navigate(`/${productRouteConstants.productList}`);
  };

  return (
    <Container>
      <Row>
        <Flex align="center" justify="space-between" style={{ width: "100%" }}>
          <Space>
            <Button
              data-test={dMap["button-back"]}
              type="link"
              onClick={() => {
                navigate(`/${productRouteConstants.productList}`);
              }}
              size="small"
            >
              <AinIcon icon="arrow-left" size={12}></AinIcon>
            </Button>
            <span className="header-breadcrumb-app-title">
              {t(tMap["common.pim"])}
            </span>
            /<strong>{productName}</strong>
          </Space>
          {isBlocked ? (
            <Flex align="center" className="ai-annotation" gap={5}>
              <AinIcon icon="external-annotation" />
              <span style={{ fontSize: 12 }}>
                {t(tMap["product.edit.externalAnnotationInProgress"])}
              </span>
            </Flex>
          ) : null}
        </Flex>
      </Row>
      <Row className="product-name">{productName}</Row>
      <Row>
        <Col span={24}>
          <span className="label">{t(tMap["common.categories"])}</span>
          {productCategories?.map((x) => (
            <Tag key={x.id} className="categories">
              <NavLink
                to={`/${routeConstants.categories}/${x.id}`}
                target="_blank"
                rel="noopener"
              >
                {x.name}
              </NavLink>
            </Tag>
          ))}
        </Col>
        <Col span={13}>
          <Row>
            <Col span={12}>
              <span className="label">{t(tMap["common.created"])}</span>
              {getDate(data.updatedAt)}
            </Col>
            <Col span={12}>
              <span className="label">{t(tMap["common.productId"])}</span>
              <span className="product-id">{data.id}</span>
            </Col>
            <Col span={12}>
              <span className="label">{t(tMap["common.lastUpdated"])}</span>
              {getDate(data.updatedAt)}
            </Col>
            <Col span={12}>
              <span className="label">{t(tMap["common.status"])}</span>
              <Tag className="status">{data.status}</Tag>
            </Col>
          </Row>
        </Col>
        <Col span={11} className="action-buttons">
          {data.isHavingDraft && isEditMode ? (
            <div className="draft-text">
              {t(tMap["product.details.draftText"])}
            </div>
          ) : null}
          {isEditMode ? (
            <>
              <Button
                danger
                data-test={dMap["edit-category-button"]}
                onClick={() => setShowEditCategory(true)}
              >
                {t(tMap["common.editCategories"])}
              </Button>
              <DisableDropdownButton
                buttonText={t(tMap["common.saveDraft"])}
                dropdownText={t(tMap["common.deleteDraft"])}
                disableButton={!isFormUpdated}
                // TODO Hide for the demo
                disableDropdown
                // disableDropdown={!data.isHavingDraft}
                onButtonClick={handleSaveDraftClicked}
                onDropdownClick={handleDeleteDraftClicked}
              />
              <DisableDropdownButton
                buttonText={t(tMap["common.publish"])}
                dropdownText={t(tMap["common.unpublish"])}
                disableButton={data.isBlocked}
                disableDropdown={
                  data.status === AttributeStatusEnum.unpublished
                }
                onButtonClick={handlePublishClick}
                onDropdownClick={handleUnpublishClicked}
              />
            </>
          ) : isBlocked ? (
            <>
              <Button
                type="primary"
                data-test={dMap["button-refresh"]}
                onClick={() => window.location.reload()}
              >
                {t(tMap["product.detail.statusRefresh"])}
              </Button>
            </>
          ) : (
            <>
              {data.status === AttributeStatusEnum.unpublished && (
                <Button
                  danger
                  data-test={dMap["delete-button"]}
                  onClick={() => confirmDeleteModal.current.setIsOpen(true)}
                >
                  {t(tMap["product.delete"])}
                </Button>
              )}
              <Button
                type="primary"
                data-test={dMap["edit-button"]}
                onClick={() => setIsEditMode(true)}
              >
                {t(tMap["common.edit"])}
              </Button>
            </>
          )}
        </Col>
      </Row>

      <ConfirmDeleteModal
        ref={confirmDeleteModal}
        onDeleteClicked={handleConfirmDelete}
      >
        <div>{t(tMap["product.detail.confirmDelete.text"])}</div>
        <br />
        <div>
          {t(tMap["product.detail.confirmDelete.detailed1"])}
          <span className="delete-confirm-highlight">
            {t(tMap["common.confirmDelete.highlight"])}
          </span>
          {t(tMap["product.detail.confirmDelete.detailed2"])}
        </div>
      </ConfirmDeleteModal>
      <EditProductCategoryModal
        isOpen={showEditCategory}
        onClose={() => setShowEditCategory(false)}
        categoryIds={productCategoryIds}
        updateCategory={updateCategory}
      />
    </Container>
  );
};
