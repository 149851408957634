import { GetChannelResponse } from "../../domain/valueObjects/GetChannelResponse";
import { ApiListResponse } from "../../types/commonTypes";
import BaseApiService from "../ApiService";

const baseUrl = `/settings/channels`;
const ApiService = new BaseApiService(baseUrl);

export class ChannelsApiService {
  public async getChannels(): Promise<ApiListResponse<GetChannelResponse>> {
    const response = (await ApiService.getFetcher(
      ""
    )) as ApiListResponse<GetChannelResponse>;
    const sortedItems = response?.items.sort((itemA, itemB) =>
      itemA.name.localeCompare(itemB.name)
    );
    return {
      ...response,
      items: sortedItems
    };
  }
}

export const channelsApiService = new ChannelsApiService();
