export const TextAreaSvg: React.FC<{
  className: string;
}> = ({ className }): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 3C1.33579 3 1 3.33579 1 3.75C1 4.16421 1.33579 4.5 1.75 4.5H8V20.75C8 21.1642 8.33579 21.5 8.75 21.5C9.16421 21.5 9.5 21.1642 9.5 20.75V4.5H15.75C16.1642 4.5 16.5 4.16421 16.5 3.75C16.5 3.33579 16.1642 3 15.75 3H8.75H1.75ZM13.75 9.5C13.3358 9.5 13 9.83579 13 10.25C13 10.6642 13.3358 11 13.75 11H17V20.75C17 21.1642 17.3358 21.5 17.75 21.5C18.1642 21.5 18.5 21.1642 18.5 20.75V11H21.75C22.1642 11 22.5 10.6642 22.5 10.25C22.5 9.83579 22.1642 9.5 21.75 9.5H17.75H13.75Z"
      fill="#333333"
    />
  </svg>
);
