import { InputNumber } from "antd";
import { useEffect } from "react";
import styled from "styled-components";
import { t, tMap } from "../../../../../features/i18n/translation";
import EditorViewMode from "@pm/ui/atoms/EditorViewMode";
import EditorEditMode from "@pm/ui/atoms/EditorEditMode";
import { NumberType } from "@pm/models/NumberType";
import { Rule } from "antd/es/form";
import BaseEditorProps from "@pm/models/BaseEditorProps";
import getEditerName from "@pm/hooks/getEditerName";
import { requiredButNoValidation } from "./validators/requiredButNoValidation";

// Use when: Input type = Simple field | Data type = number | Multiple values = false

const EditMode = styled(EditorEditMode)`
  .ant-input-number {
    width: 100%;
  }
`;

interface Props extends BaseEditorProps {
  value?: number;
  defaultValue?: number;
  numberType?: NumberType;
}

export const SingleNumber: React.FC<Props> = (props): JSX.Element => {
  const {
    isEdit,
    value,
    isRequired,
    defaultValue,
    numberType,
    form,
    formItemName
  } = props;
  const translatedName = getEditerName(props);

  useEffect(() => {
    form.setFieldValue(formItemName, value ?? defaultValue);
  }, [value, isEdit]);

  if (!isEdit) {
    return (
      <EditorViewMode>
        <div className="name">{translatedName}</div>
        <div>{value ?? "--"}</div>
      </EditorViewMode>
    );
  }

  const rules: Rule[] = [];
  if (isRequired) {
    rules.push(requiredButNoValidation());
  }
  if (numberType == NumberType.Rounded) {
    rules.push({
      validator: (_, value) => {
        if (value == null) return Promise.resolve(); // allow, if value is empty
        if (!Number.isInteger(value)) {
          return Promise.reject(
            new Error(t(tMap["common.roundedNumberMessage"]))
          );
        }
        return Promise.resolve();
      }
    });
  }

  return (
    <EditMode
      name={formItemName}
      label={translatedName}
      labelCol={{ span: 24 }}
      initialValue={defaultValue}
      rules={rules}
    >
      <InputNumber
        placeholder={t(tMap["common.typeHere"])}
        step={numberType === NumberType.Decimal ? 0.01 : undefined}
      />
    </EditMode>
  );
};

export default SingleNumber;
