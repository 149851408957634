import { Button, Form, Table } from "antd";
import "./attributesTable.scss";
import { ColumnsType } from "antd/es/table";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { commonConstants } from "../../../../../../common/constants/commonConstants";
import { TemplateSetting } from "../../../../domain/valueObjects/templateSetting";
import { SettingInputType } from "../../../../domain/valueObjects/settingInputType";
import ToggleColumnInput from "../../../molecules/toggleColumnInput/ToggleColumnInput";
import SelectionColumnInput from "../../../molecules/selectionColumnInput/SelectionColumnInput";
import { AinIcon } from "../../../../../../common/ui/atoms/AinIcon";
import React from "react";
import ImportCategoriesModal from "../../modals/importCategoriesModal/ImportCategoriesModal";
import { useCategoryPageState } from "../../../../domain/hooks/useCategoryPageState";
import cloneDeep from "../../../../../../common/modules/cloneDeep";
import { CategoryAttribute } from "@cm/domain/valueObjects/categoryAttribute";
import { t, tMap } from "../../../../../../features/i18n/translation";
import deepMergeOverwriteArray from "../../../../../../common/modules/deepMergeOverwriteArray";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { CategoryTemplate } from "@cm/domain/valueObjects/categoryTemplate";
import { attributesRouteConstants } from "@am/attributeRoutes";
import { Link } from "react-router-dom";

interface Props {}

interface TableDataType {
  key: React.ReactNode;
  attributeName: string;
  categoryId: string;
  children?: TableDataType[];
  totalColumn: number;
  [key: string]: any;
}

const sortFunction = (a: any, b: any, fieldName: string) => {
  if (a.children) {
    return 0;
  }

  const aIsString = typeof a[fieldName] === "string";
  const bIsString = typeof b[fieldName] === "string";
  if (aIsString || bIsString) {
    const valueA = aIsString ? a[fieldName] : "";
    const valueB = bIsString ? b[fieldName] : "";
    return valueA.localeCompare(valueB);
  }

  if (Array.isArray(a[fieldName]) || Array.isArray(b[fieldName])) {
    return (a[fieldName] ?? []).length - (b[fieldName] ?? []).length;
  }

  return a[fieldName] - b[fieldName];
};

const renderExpandIcon = ({ expanded, onExpand, record }: any) => {
  if (!record.children) {
    return null;
  }

  return expanded ? (
    <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
  ) : (
    <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
  );
};

export const AttributesTable: React.FC<Props> = (): JSX.Element => {
  const [form] = Form.useForm();
  const [state, setState] = useCategoryPageState();
  const [dataSource, setDataSource] = useState<TableDataType[]>();
  const [columns, setColumns] = useState<ColumnsType<TableDataType>>([]);
  const importCategoriesModal = useRef<any>(null);
  const cachedDataSource = useRef<TableDataType[]>();
  const allSettings = useRef<TemplateSetting[]>();
  const allAttributeNames = useRef<string[]>([]);

  const resolveColumnInput = (
    setting: TemplateSetting,
    value: any,
    disabled: boolean,
    formItemName: string[]
  ) => {
    switch (setting.inputType) {
      case SettingInputType.Toggle:
        return (
          <ToggleColumnInput
            disabled={disabled}
            value={value}
            form={form}
            formItemName={formItemName}
          />
        );

      case SettingInputType.Selection:
        return (
          <SelectionColumnInput
            disabled={disabled}
            value={value}
            isMultiple={setting.isMultipleValues}
            options={setting.options}
            form={form}
            formItemName={formItemName}
          />
        );

      default:
        return value;
    }
  };

  const handleDeleteAttribute = (record: TableDataType) => {
    let newAttributes: any[] = [];
    cachedDataSource.current = cachedDataSource.current!.map((data) => {
      if (!data.children) {
        return data;
      }

      newAttributes = data.children.filter((x) => x.key !== record.key);
      return {
        ...data,
        children: newAttributes
      };
    });

    newAttributes = newAttributes.map((x) => x.attributeName);
    const attributes = state.updatedCategory!.template.attributes.filter((x) =>
      newAttributes.includes(x.name)
    );
    const templates = state.updatedCategory!.templates.map((template) => {
      if (template.categoryId !== state.currentCategory?.id) {
        return template;
      }

      return {
        ...template,
        attributes: template.attributes.filter((x) =>
          newAttributes.includes(x.name)
        )
      };
    });
    const tempUpdatedCategory = {
      ...state.updatedCategory,
      templates,
      template: {
        ...state.updatedCategory?.template,
        attributes
      }
    };

    setState({
      updatedCategory: tempUpdatedCategory
    });
    setDataSource(cachedDataSource.current);
    resolveAllAttributeNames(templates);
  };

  const renderCategoryCell = (value: string, record: TableDataType) => {
    if (!record.children) {
      return (
        <span className="attribute-name">
          {record.categoryId !== state.currentCategory?.id ? null : (
            <span
              className="delete"
              onClick={() => handleDeleteAttribute(record)}
            >
              <AinIcon icon="delete-outlined-gray" size={16} />
            </span>
          )}

          <Link
            className="value"
            to={`/${attributesRouteConstants.attributes}/${record.attributeName}/config`}
            target="_blank"
          >
            {value}
          </Link>
        </span>
      );
    }

    return (
      <span>
        <span className="category-name">{value}</span>
        <span className="category-action">
          <span>
            {`${record.children?.length || 0} ${t(
              tMap["category.details.countAttributes"]
            )}`}
          </span>
          {record.categoryId !== state.currentCategory?.id ? null : (
            <Button
              data-test={dMap["button-import"]}
              size="small"
              onClick={() =>
                importCategoriesModal.current?.open(allAttributeNames.current)
              }
            >
              {t(tMap["category.details.importAttributes"])}
            </Button>
          )}
        </span>
      </span>
    );
  };

  const defaultColumns = [
    {
      title: t(tMap["category.common.attributes"]),
      dataIndex: "attributeName",
      key: "attributeName",
      onCell: (record: TableDataType) => ({
        className: `custom-cell ${record.children ? "category-cell" : ""} ${
          record.categoryId === state.currentCategory?.id ? "" : "disabled"
        }`,
        colSpan: record.children ? record.totalColumn : 1
      }),
      render: renderCategoryCell,
      sorter: (a: TableDataType, b: TableDataType) =>
        sortFunction(a, b, "attributeName")
    },
    {
      title: t(tMap["common.required"]),
      dataIndex: "isRequired",
      key: "isRequired",
      onCell: (record: TableDataType) => ({
        className: "custom-cell",
        colSpan: record.children ? 0 : 1
      }),
      render: (value: boolean, record: TableDataType) => {
        const formItemName = [record.attributeName, "isRequired"];
        const disabled = record.categoryId !== state.currentCategory?.id;

        return (
          <ToggleColumnInput
            disabled={disabled}
            value={value}
            form={form}
            formItemName={formItemName}
          />
        );
      },
      sorter: (a: TableDataType, b: TableDataType) =>
        sortFunction(a, b, "isRequired")
    },
    {
      title: t(tMap["common.variantDependent"]),
      dataIndex: "isVariantDependent",
      key: "isVariantDependent",
      onCell: (record: TableDataType) => ({
        className: "custom-cell",
        colSpan: record.children ? 0 : 1
      }),
      render: (value: boolean, record: TableDataType) => {
        const formItemName = [record.attributeName, "isVariantDependent"];
        const disabled = record.categoryId !== state.currentCategory?.id;

        return (
          <ToggleColumnInput
            disabled={disabled}
            value={value}
            form={form}
            formItemName={formItemName}
          />
        );
      },
      sorter: (a: TableDataType, b: TableDataType) =>
        sortFunction(a, b, "isVariantDependent")
    },
    {
      title: t(tMap["common.variantKey"]),
      dataIndex: "isVariantKey",
      key: "isVariantKey",
      onCell: (record: TableDataType) => ({
        className: "custom-cell",
        colSpan: record.children ? 0 : 1
      }),
      render: (value: boolean, record: TableDataType) => {
        const formItemName = [record.attributeName, "isVariantKey"];
        const disabled = record.categoryId !== state.currentCategory?.id;

        return (
          <ToggleColumnInput
            disabled={disabled}
            value={value}
            form={form}
            formItemName={formItemName}
          />
        );
      },
      sorter: (a: TableDataType, b: TableDataType) =>
        sortFunction(a, b, "isVariantKey")
    }
  ];

  const buildDataSource = () => {
    cachedDataSource.current = state.currentCategory!.templates.map(
      (template) => {
        const children: TableDataType[] = template.attributes.map(
          (attribute) => ({
            key: `${template.categoryName}|${attribute.name}`,
            attributeName: attribute.name,
            categoryId: template.categoryId,
            totalColumn: columns.length,
            isRequired: attribute.isRequired,
            isVariantDependent: attribute.isVariantDependent,
            isVariantKey: attribute.isVariantKey,
            ...attribute.settings
          })
        );

        return {
          key: template.categoryId,
          attributeName: template.categoryName,
          categoryId: template.categoryId,
          totalColumn: columns.length,
          children
        };
      }
    );

    return cachedDataSource.current;
  };

  const buildColumns = () => {
    const columns: ColumnsType<TableDataType> = allSettings.current!.map(
      (setting) => ({
        title:
          setting.labels?.find(
            (x) => x.locale === commonConstants.defaultLocale
          )?.value || setting.name,
        dataIndex: setting.name,
        key: setting.name,
        onCell: (record) => ({
          className: "custom-cell",
          colSpan: record.children ? 0 : 1
        }),
        render: (value, record) => {
          const formItemName = [record.attributeName, "settings", setting.name];
          const disabled = record.categoryId !== state.currentCategory?.id;
          return resolveColumnInput(setting, value, disabled, formItemName);
        },
        sorter: (a, b) => sortFunction(a, b, setting.name)
        // TODO: maybe need to support filter for selection input type
        // filters: Object.entries(SettingRelation).map(([key, relation]) => ({
        //   text: key,
        //   value: relation,
        // })),
        // onFilter: (filterValue, record) => {
        //   return true;
        // },
      })
    );

    return [...defaultColumns, ...columns];
  };

  const resolveAllAttributeNames = (templates: CategoryTemplate[]) => {
    allAttributeNames.current = templates.reduce(
      (previous: string[], current) => {
        if (!current.attributes?.length) {
          return previous;
        }

        current.attributes.forEach((attribute) => {
          if (previous.every((x) => x !== attribute.name)) {
            previous.push(attribute.name);
          }
        });

        return previous;
      },
      []
    );
  };

  useEffect(() => {
    if (!state.currentCategory || !state.allCategories) {
      return;
    }

    const templates = [...state.currentCategory!.templates];
    allSettings.current = templates
      .reverse()
      .reduce((previous: TemplateSetting[], current) => {
        if (!current.settings?.length) {
          return previous;
        }

        current.settings.forEach((setting) => {
          if (previous.every((x) => x.name !== setting.name)) {
            previous.push(setting);
          }
        });

        return previous;
      }, []);

    setColumns(buildColumns());
    resolveAllAttributeNames(
      state.updatedCategory
        ? state.updatedCategory.templates
        : state.currentCategory.templates
    );
  }, [state.currentCategory, state.allCategories]);

  useEffect(() => {
    if (!columns.length) {
      return;
    }

    setDataSource(buildDataSource());
  }, [columns]);

  const handleValuesChanged = (values: any) => {
    const attributeName = Object.keys(values)[0];

    const attributes = state.updatedCategory!.template.attributes.map(
      (attribute) => {
        if (attribute.name !== attributeName) {
          return { ...attribute };
        }

        return deepMergeOverwriteArray(attribute, values[attributeName]);
      }
    );
    const tempUpdatedCategory = {
      ...state.updatedCategory,
      template: {
        ...state.updatedCategory?.template,
        attributes
      }
    };

    const templates = cloneDeep(state.currentCategory!.templates);
    templates.forEach((template) => {
      if (template.categoryId !== state.currentCategory?.id) {
        return;
      }

      template.attributes = template.attributes.map((attribute) => {
        if (attribute.name !== attributeName) {
          return { ...attribute };
        }

        return deepMergeOverwriteArray(attribute, values[attributeName]);
      });
    });
    const tempCurrentCategory = {
      ...state.currentCategory,
      templates
    };

    setState({
      currentCategory: tempCurrentCategory,
      updatedCategory: tempUpdatedCategory
    });
  };

  const handleImportAttributes = (attributes: string[]) => {
    const newAttributes: CategoryAttribute[] = [];
    const newSettings: any = {};
    allSettings.current?.forEach((setting) => {
      if (setting.default !== undefined || setting.default !== null) {
        newSettings[setting.name] = setting.default;
      }
    });
    attributes.forEach((attribute) => {
      if (allAttributeNames.current.every((x) => x !== attribute)) {
        newAttributes.push({
          name: attribute,
          isRequired: false,
          isVariantDependent: false,
          isVariantKey: false,
          settings: newSettings,
          displayOrder: 0
        });
      }
    });
    const templates = state.updatedCategory!.templates.map((template) => {
      if (template.categoryId !== state.currentCategory?.id) {
        return template;
      }

      return {
        ...template,
        attributes: [...template.attributes, ...newAttributes]
      };
    });

    const tempCategory = {
      ...state.updatedCategory,
      template: {
        ...state.updatedCategory?.template,
        attributes: [
          ...state.updatedCategory!.template.attributes,
          ...newAttributes
        ]
      },
      templates
    };

    setState({
      currentCategory: { ...tempCategory },
      updatedCategory: { ...tempCategory }
    });
  };

  return (
    <Form
      className="attributes-table"
      name="category-attributes"
      form={form}
      onValuesChange={handleValuesChanged}
    >
      <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        loading={!dataSource}
        expandable={{
          expandIcon: renderExpandIcon,
          defaultExpandedRowKeys: [state.currentCategory!.id]
        }}
      />

      <ImportCategoriesModal
        ref={importCategoriesModal}
        onImportAttribute={handleImportAttributes}
      />
    </Form>
  );
};

export default AttributesTable;
