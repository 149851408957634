import { Form, FormInstance, Select } from "antd";
import "./selectionColumnInput.scss";
import { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import { commonConstants } from "../../../../../common/constants/commonConstants";
import { SelectionOption } from "../../../../../common/domain/valueObjects/SelectionOption";

interface Props {
  value: any;
  options?: SelectionOption[];
  disabled: boolean;
  isMultiple?: boolean;
  form: FormInstance;
  formItemName: string[];
}

export const SelectionColumnInput: React.FC<Props> = ({
  value,
  options,
  disabled,
  isMultiple,
  form,
  formItemName
}): JSX.Element => {
  const [selectOptions, setSelectOptions] = useState<DefaultOptionType[]>();

  useEffect(() => {
    const optionTemp = options?.map<DefaultOptionType>((x) => ({
      value: x.key,
      label:
        x.displayValues.find((x) => x.locale === commonConstants.defaultLocale)
          ?.value || x.key
    }));

    setSelectOptions(optionTemp);
  }, [options]);

  useEffect(() => {
    form?.setFieldValue(formItemName, value ?? undefined);
  }, [value]);

  return (
    <Form.Item className="selection-column-input" name={formItemName}>
      <Select
        bordered={false}
        mode={isMultiple ? "multiple" : undefined}
        options={selectOptions}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default SelectionColumnInput;
