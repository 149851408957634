import { guardFunction } from "../../modules/guardFunction";

export interface ApiErrorResponse {
  status: string;
  statusCode: number;
  code: number;
  message: string;
  errors: Record<string, unknown>;
  reason: string;
  note: string;
}

export const isApiErrorResponse = guardFunction<ApiErrorResponse>((value) => {
  if (!value) return false;
  return !!value.code || !!value.statusCode;
});
