export function sortObjectFields<T extends Record<string, unknown>>(obj: T | null): T {
  // @ts-expect-error 'T' could be instantiated with an arbitrary type which could be unrelated to 'null'.ts(2322)
  if (!obj) return obj;
  const sortedObject: T = {} as T;

  Object.keys(obj)
    .sort()
    .forEach((key: keyof T) => {
      sortedObject[key] = obj[key];
    });

  return sortedObject;
}
