import { useAtom, useAtomValue } from "jotai";

import {
  ProductManagementUIState,
  uiStateAtom
} from "@pm/state/productManagementUIState";
import { categoryApiService } from "@cm/common/services/categoryApiService";

export const useUIState = () => {
  const [uiState, setUIState] = useAtom(uiStateAtom);

  const setSingleState =
    (name: keyof ProductManagementUIState) => (value: any) => {
      setUIState((prev: ProductManagementUIState) => {
        if (prev[name] === value) return prev;
        return {
          ...prev,
          [name]: value
        };
      });
    };

  // load all categories, unless already loaded
  const loadAllCategories = async () => {
    if (uiState.allCategories && uiState.allCategories.length > 0) return;
    const categories = await categoryApiService.getCategories();
    setUIState((v) => ({
      ...v,
      allCategories: categories.items
    }));
  };

  return {
    uiState,
    setIsEditMode: setSingleState("isEditMode"),
    setIsSidebarOpen: setSingleState("isSidebarOpen"),
    setProductVariants: setSingleState("productVariants"),
    setAttributesMap: setSingleState("attributesMap"),
    setAttributeSettingMap: setSingleState("attributeSettingMap"),
    setCategoryList: setSingleState("categoryList"),
    setNewCategories: setSingleState("overwriteCategoryIds"),
    setIsFormUpdated: setSingleState("isFormUpdated"),
    loadAllCategories
  };
};

export const useUIStateReadOnly = () => useAtomValue(uiStateAtom);
