import { Row, Col, Button, Input, Form, Tag, FormInstance } from "antd";
import { AinIcon } from "../../../../../../common/ui/atoms/AinIcon";
import TextWithLabel from "../../../../../../common/ui/molecules/TextWithLabel";
import { themeColors } from "../../../../../../common/ui/styles/themeColors";
import { t, tMap } from "../../../../../../features/i18n/translation";
import { AttributeDataTypeEnum } from "../../../../models/enums/attributeDataTypeEnum";
import {
  AttributeTemplate,
  emptyAttributeTemplate
} from "../../../../models/valueObjects/attributeTemplate";
import { useAttributesStore } from "../../../../state/stores/attributesStore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getDetailsFormValuesSubscriber } from "../../../../hooks/useGetDetailsFormValuesPublisher";
import { AttributeEntity } from "../../../../models/entities/attributeEntity";
import {
  AddNewInputPopover,
  AddNewInputProps
} from "../../../organisms/popovers/addNewInputPopover/AddNewInputPopover";
import { Labels } from "../../../../../../common/domain/valueObjects/Label";
import "./inputTypeBuilder.scss";
import React from "react";
import {
  createKeyPath,
  getKeyLastKey,
  getKeysFromKeyPath,
  getParentKey,
  isTopLevelKey
} from "../../../../common/helpers/createTreeKeys";
import { sortObjectFields } from "../../../../../../common/modules/sortObjectFields";
import { createSearchParams, useNavigate } from "react-router-dom";
import { AttributeSearchParamsObject } from "../../../../models/valueObjects/attributeSearchParams";
import { FormColumn } from "./formColumnType";
import { getBuilderData } from "./getBuilderData";
import { useDraftSavedClickedListener } from "../../../../hooks/useDraftSaveClickedListener";
import { PlusSquareFilled } from "@ant-design/icons";
import { AttributeInputTypeEnum } from "../../../../models/enums/attributeInputTypeEnum";
import { dataTypesUiMetadata } from "../../../../common/helpers/attributeUiMappings";
import { EditLocalsPopover } from "../../../../../../common/ui/molecules/popovers/editLocalsPopover/EditLocalsPopover";
import { updateAttributeImmer } from "../../../../state/updateAttribute";
import { generateId } from "../../../../../../common/modules/debugging/generateId";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import {
  dHtmlMap,
  dHtmlMapFunc
} from "../../../../../../common/modules/dataHtmlAttribtueMap";

const labelWidth = 120;

export const InputTypeBuilder: React.FC<{
  attributeEntity: AttributeEntity | AttributeTemplate;
  treeKey: string;
}> = React.memo(({ attributeEntity, treeKey }): JSX.Element => {
  const editLocalsPopoverRef = useRef<any>(null);
  const draft = useAttributesStore().entity.draftAttribute;
  const { setDraftAttribute } = useAttributesStore().entity;
  const { ui } = useAttributesStore();
  const navigate = useNavigate();
  const { isEditing } = useAttributesStore().ui;
  const [form] = Form.useForm();

  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);

  const isSelected = useMemo(
    () => ui.selectedKeyPath === treeKey,
    [ui.selectedKeyPath, treeKey]
  );
  const isTopLevel = useMemo(() => {
    return isTopLevelKey(treeKey);
  }, [treeKey]);

  const onFinish = (_values: any) => {};

  const handleEditLabelsClick = useCallback(
    async (updatedLabels: Labels[]) => {
      if (!draft) return;
      const updated = updateAttributeImmer(draft, null, {
        keyPath: treeKey,
        visistor: (target) => {
          target.labels = updatedLabels;
          return target;
        }
      });
      setDraftAttribute(updated);
      editLocalsPopoverRef.current.setIsOpen(false);
    },
    [draft, setDraftAttribute, treeKey]
  );

  const handleAddNewInputClick = useCallback<AddNewInputProps["onConfirm"]>(
    async (data) => {
      let hasDuplicatedName = false;
      const updated = updateAttributeImmer(draft, null, {
        keyPath: treeKey,
        visistor: (target) => {
          const { template } = target;
          hasDuplicatedName = !!template?.find(
            (element) => element.name === data.name
          );
          if (hasDuplicatedName) return;

          target.template = [...(target.template ?? [])];
          const finalData = {
            ...emptyAttributeTemplate,
            ...data,
            id: generateId()
          };
          target.template.push(finalData);
          return target;
        }
      });
      setDraftAttribute(updated);

      if (!hasDuplicatedName) {
        setIsCreateOpen(false);
      }

      return { hasDuplicatedName };
    },
    [draft, setDraftAttribute, treeKey]
  );

  const deleteObjectField = useCallback(() => {
    /** Go to parent, and delete child from parent level */
    const keys = getKeysFromKeyPath(treeKey);
    const currentKey = keys[keys.length - 1];
    const previousKeys = keys.slice(0, keys.length - 1);
    const parentKey = createKeyPath(previousKeys);
    const updated = updateAttributeImmer(draft, null, {
      keyPath: parentKey,
      visistor: (target) => {
        if (!target.template) return target;

        const filtered = target.template?.filter((temp) => {
          return temp.id !== currentKey;
        });
        const finalUpdate = {
          ...target,
          template: filtered
        };
        return finalUpdate;
      }
    });
    setDraftAttribute(updated);
  }, [draft, setDraftAttribute, treeKey]);

  const navigateToOptionValues = () => {
    ui.setSelectedKeyPath(treeKey);
    navigate({
      pathname: "../options-value",
      search: createSearchParams({
        keyPath: treeKey
      } as AttributeSearchParamsObject).toString()
    });
  };

  getDetailsFormValuesSubscriber(
    useCallback(() => {
      form.submit();
    }, [])
  );

  useDraftSavedClickedListener(
    useCallback(() => {
      form.submit();
    }, [])
  );

  useEffect(() => {
    const { template, inputSettings, ...toSet } = attributeEntity;
    form.setFieldsValue({
      ...toSet,
      inputSettings: {
        ...inputSettings,
        allowedFileTypes: inputSettings?.allowedFileTypes ?? []
      }
    });
  }, []);

  const builderData: FormColumn[][] = useMemo(() => {
    return getBuilderData(attributeEntity, isTopLevel);
  }, [attributeEntity, isTopLevel]);

  return (
    <div
      className="input-type-build"
      data-test={dMap["attribute-setting"]}
      data-id={dHtmlMapFunc(dHtmlMap["id"], getKeyLastKey(treeKey))}
      data-parent-key-path={dHtmlMapFunc(
        dHtmlMap["parentKeyPath"],
        getParentKey(treeKey)
      )}
      style={{
        border: `1px solid ${
          isSelected ? themeColors.black1 : themeColors.colorBorder
        }`,
        width: "80vw"
      }}
    >
      <Form form={form} onFinish={onFinish} name={treeKey} size="small">
        {/* Header */}
        <Row
          style={{
            background: themeColors.grey3,
            padding: "2px 8px 2px 0",
            borderBottom: `1px solid ${themeColors.colorBorder}`
          }}
          justify="space-between"
        >
          {/* Name */}
          <Col
            className="attribute-name"
            style={{
              display: "flex",
              flexGrow: 1
            }}
          >
            <TextWithLabel
              label={t(
                isTopLevel
                  ? tMap["attributeDetails.attributeName"]
                  : tMap["attributeDetails.objectFieldLabel"]
              )}
              direction="horizontal"
              size={16}
              labelStyle={{
                minWidth: labelWidth
              }}
              data-test={
                isTopLevel ? dMap["attribute-name"] : dMap["object-field-name"]
              }
            >
              {/* Read-only */}
              {isEditing ? (
                <Form.Item
                  name="name"
                  initialValue={attributeEntity.name}
                  noStyle
                >
                  <Input />
                </Form.Item>
              ) : (
                attributeEntity.name
              )}
            </TextWithLabel>
          </Col>

          {/* Actions */}
          <Col
            className="action-buttons"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Tag color={"default"} style={{ background: themeColors.grey3 }}>
              {dataTypesUiMetadata()[attributeEntity.dataType]?.label}
            </Tag>

            {/* todo: Data type */}
            {attributeEntity.inputType === AttributeInputTypeEnum.selection && (
              <Button
                type="text"
                onClick={navigateToOptionValues}
                style={{ marginRight: -1 }}
              >
                <AinIcon icon="table-outlined" size={21}></AinIcon>
              </Button>
            )}
            {isEditing && !isTopLevel && (
              <Button
                type="text"
                onClick={deleteObjectField}
                style={{ marginTop: 2, padding: "0 4px" }}
              >
                <AinIcon icon="delete-outlined-gray" height={18}></AinIcon>
              </Button>
            )}
            <EditLocalsPopover
              ref={editLocalsPopoverRef}
              isReadOnly={!isEditing}
              onConfirm={handleEditLabelsClick}
              labels={attributeEntity.labels}
            >
              <Button
                type="text"
                style={{
                  verticalAlign: "middle",
                  padding: "0 4px",
                  marginLeft: 1
                }}
              >
                <AinIcon icon="translation-outlined" size={20}></AinIcon>
              </Button>
            </EditLocalsPopover>
            {isEditing &&
              attributeEntity.dataType === AttributeDataTypeEnum.object && (
                <AddNewInputPopover
                  isOpen={isCreateOpen}
                  onCancelClicked={() => setIsCreateOpen(false)}
                  onConfirm={handleAddNewInputClick}
                >
                  <Button
                    data-test={dMap["add-new-object-field-button"]}
                    type="text"
                    onClick={() => setIsCreateOpen(true)}
                    style={{
                      verticalAlign: "middle",
                      padding: "0 4px"
                    }}
                  >
                    <PlusSquareFilled
                      style={{ color: themeColors.black045, fontSize: 17 }}
                    />
                  </Button>
                </AddNewInputPopover>
              )}
          </Col>
        </Row>

        {builderData.map((row) => {
          const firstCol = row[0];
          if (!firstCol) return null;

          const isSetting = firstCol.isSetting;
          if (isSetting) {
            if (row[0].hide) {
              return null;
            }
          }

          return (
            <Row
              key={Math.random()}
              style={{
                borderBottom: `1px solid ${themeColors.colorBorder}`,
                minHeight: 42
              }}
            >
              {row.map((col) => {
                const key =
                  typeof col.key === "string"
                    ? col.key
                    : Array.isArray(col.key)
                    ? col.key.join("|")
                    : Math.random().toString();
                return (
                  <Col
                    style={{
                      display: "flex",
                      padding: "0px 8px",
                      alignItems: "center",
                      borderLeft: `1px solid ${themeColors.grey4}`,
                      width: "100%"
                    }}
                    span={col.span}
                    key={key}
                    data-test={key}
                  >
                    <TextWithLabel
                      label={col.label as any}
                      size={16}
                      direction="horizontal"
                      labelStyle={{
                        display: "block",
                        minWidth: labelWidth
                      }}
                    >
                      {/* Read-only */}
                      {isEditing ? (
                        <Form.Item
                          name={col.key}
                          noStyle
                          style={{ width: "100%" }}
                        >
                          {col.render ? (
                            col.render(col.value)
                          ) : (
                            <Input
                              defaultValue={col.value?.toString()}
                              placeholder={t(tMap["common.input"])}
                            ></Input>
                          )}
                        </Form.Item>
                      ) : col.render && col.isReadOnly ? (
                        col.render(col.value)
                      ) : (
                        <>{col.value || t(tMap["common.na"])}</>
                      )}
                    </TextWithLabel>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </Form>
    </div>
  );
});
