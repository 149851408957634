export const ToggleSvg: React.FC<{
  className: string;
}> = ({ className }): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.25C3.37665 1.25 1.25 3.37665 1.25 6C1.25 8.62336 3.37665 10.75 6 10.75H18C20.6234 10.75 22.75 8.62336 22.75 6C22.75 3.37665 20.6234 1.25 18 1.25H6ZM2.75 6C2.75 4.20507 4.20507 2.75 6 2.75H18C19.7949 2.75 21.25 4.20507 21.25 6C21.25 7.79494 19.7949 9.25 18 9.25H6C4.20507 9.25 2.75 7.79494 2.75 6ZM6 13.25C3.37665 13.25 1.25 15.3766 1.25 18C1.25 20.6234 3.37665 22.75 6 22.75H18C20.6234 22.75 22.75 20.6234 22.75 18C22.75 15.3766 20.6234 13.25 18 13.25H6ZM2.75 18C2.75 16.2051 4.20507 14.75 6 14.75H18C19.7949 14.75 21.25 16.2051 21.25 18C21.25 19.7949 19.7949 21.25 18 21.25H6C4.20507 21.25 2.75 19.7949 2.75 18ZM16.25 6C16.25 5.03349 17.0335 4.25 18 4.25C18.9665 4.25 19.75 5.03349 19.75 6C19.75 6.96651 18.9665 7.75 18 7.75C17.0335 7.75 16.25 6.96651 16.25 6ZM18 5.75C17.8619 5.75 17.75 5.86191 17.75 6C17.75 6.13809 17.8619 6.25 18 6.25C18.1381 6.25 18.25 6.13809 18.25 6C18.25 5.86191 18.1381 5.75 18 5.75ZM6 16.25C5.03349 16.25 4.25 17.0335 4.25 18C4.25 18.9665 5.03349 19.75 6 19.75C6.96651 19.75 7.75 18.9665 7.75 18C7.75 17.0335 6.96651 16.25 6 16.25ZM5.75 18C5.75 17.8619 5.86191 17.75 6 17.75C6.13809 17.75 6.25 17.8619 6.25 18C6.25 18.1381 6.13809 18.25 6 18.25C5.86191 18.25 5.75 18.1381 5.75 18Z"
      fill="#333333"
    />
  </svg>
);
