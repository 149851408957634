import React from "react";
import { t, tMap } from "../../../../features/i18n/translation";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";

export const yesNoOptions: { label: React.ReactNode; value: boolean }[] = [
  {
    label: <span data-test={dMap["option-yes"]}>{t(tMap["common.yes"])}</span>,
    value: true
  },
  {
    label: <span data-test={dMap["option-no"]}>{t(tMap["common.no"])}</span>,
    value: false
  }
];
