import "./categoryFilter.scss";
import { Divider, Switch } from "antd";
import SearchCategoryForm from "../searchCategoryForm/SearchCategoryForm";
import { useCategoryPageState } from "../../../domain/hooks/useCategoryPageState";

interface Props {}

export const CategoryFilter: React.FC<Props> = (): JSX.Element => {
  const [state, setState] = useCategoryPageState();

  const handleSwitchChanged = (checked: boolean) =>
    setState({ isOpenTree: checked });

  return (
    <div className="category-filter">
      <span>
        Tree menu
        <Switch
          className="tree-switch"
          defaultChecked
          onChange={handleSwitchChanged}
        />
      </span>
      <Divider type="vertical" />
      <SearchCategoryForm />
    </div>
  );
};

export default CategoryFilter;
