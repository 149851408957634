import { Rule } from "antd/es/form";
import { t, tMap } from "../../../../../../features/i18n/translation";

/**
 * When the element is required, but the validation is not required.
 * Usecase: to show the user, that they need to fill this field to publish the form
 */
export function requiredButNoValidation() {
  return {
    required: true,
    message: t(tMap["common.requireInputMessage"]),
    validator: () => {
      /** Show the required mark, but don't force validation */
      return Promise.resolve(true);
    }
  } as Rule;
}
