import React from "react";
import { useEffect } from "react";
import Form from "antd/es/form";
import BaseEditorProps from "@pm/models/BaseEditorProps";
import { useLocaleOptions } from "../../../../../common/hooks/useLocaleOptions";
import {
  Labels,
  isLabelGuardArray
} from "../../../../../common/domain/valueObjects/Label";
import deepMergeOverwriteArray from "../../../../../common/modules/deepMergeOverwriteArray";
import { mergeWithOtherLocales } from "@pm/helpers/mergeWithOtherLocales";

export interface InputLocaleDependentProps extends BaseEditorProps {
  value?: string | Labels[] | null;
  defaultValue?: string;
  /** For react array, eg. .map */
  key?: string | number;
  pattern?: string;
  path?: string[];
  data?: any;
}

export const InputLocaleDependent: React.FC<InputLocaleDependentProps> = (
  props
): JSX.Element => {
  const { isEdit, value, form, formItemName, locale, data, children } = props;
  let labels = value as unknown as Labels[] | null;
  if (isLabelGuardArray(data)) {
    labels = data;
  }

  const { localeOptions } = useLocaleOptions();
  const labelsWithOtherLocales = mergeWithOtherLocales(labels, localeOptions);
  const targetLocaleIndex = labelsWithOtherLocales.findIndex(
    (label) => label.locale === locale
  );

  let withExistingData = labelsWithOtherLocales;
  if (!isLabelGuardArray(data)) {
    // If data is not in the shape of `Label[]`, then add
    if (labelsWithOtherLocales[targetLocaleIndex]) {
      labelsWithOtherLocales[targetLocaleIndex].value = data ?? null;
    }
  } else if (data) {
    // If it is in the shape of `Label[]`, then merge
    withExistingData = deepMergeOverwriteArray(data, labelsWithOtherLocales);
  }

  useEffect(() => {
    if (!isLabelGuardArray(withExistingData)) {
      console.error("[App Error] Expected `data` to be an array of `Labels`");
    }
    form.setFieldValue(formItemName, withExistingData);
  }, [isEdit]);

  if (!children) return <></>;

  return (
    <Form.List name={formItemName}>
      {(fields) => (
        <>
          {fields.map((field, fieldIndex) => {
            if (fieldIndex !== targetLocaleIndex) {
              return <span key={fieldIndex}></span>;
            }

            const formItemNamePerField = [String(field.name), "value"];
            const propForChild: InputLocaleDependentProps = {
              key: fieldIndex,
              ...props,
              value: withExistingData[targetLocaleIndex].value,
              formItemName: formItemNamePerField,
              initialFormItemName: formItemNamePerField,
              data: withExistingData[targetLocaleIndex],
              path: [...formItemName, ...formItemNamePerField]
            };

            const childrenWithProps = React.cloneElement(
              children as any,
              propForChild
            );
            return childrenWithProps;
          })}
        </>
      )}
    </Form.List>
  );
};
