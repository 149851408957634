import { Button, Col, Form, Layout, Row, Select } from "antd";
import AttributesTable from "./AttributesTable";
import { t, tMap } from "../../../../../features/i18n/translation";
import { themeColors } from "../../../../../common/ui/styles/themeColors";
import { PlusOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { useCallback, useEffect, useRef, useState } from "react";
import { apiService } from "../../../api/ApiService";
import "./attributeListing.scss";
import { commonConstants } from "../../../../../common/constants/commonConstants";
import {
  ApiListRequest,
  ApiListResponse,
  ApiSearchParam,
  ApiSortParam
} from "../../../../../common/types/commonTypes";
import { AttributeEntity } from "../../../models/entities/attributeEntity";
import CreateNewAttributeModal from "../../organisms/modals/CreateNewAttributeModal";
import { useNavigate } from "react-router-dom";
import { routeConstants } from "../../../../../features/routes/routeConstants";
import { useAttributesStore } from "../../../state/stores/attributesStore";
import {
  ApiErrorResponse,
  isApiErrorResponse
} from "../../../../../common/domain/valueObjects/ApiErrorResponse";
import { showApiErrorNotification } from "../../../../../common/modules/showNotifications";
import { CreateAttributeRequest } from "../../../models/valueObjects/createAttributeRequest";
import { attributesDebugFlags } from "../../../common/helpers/debugging/attributesDebugFlags";
import { attributeMocks } from "../../../mocks/mockAttributes";
import { useAttributesAtomWithImmer } from "../../../state/atoms/attributeAtomsImmer";
import { useSeedDataBaseWithProductionMocks } from "../../../mocks/seedDataBaseWithProductionMocks";
import { attributesRouteConstants } from "../../../attributeRoutes";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import ConfirmDeleteModal from "../../../../../common/ui/molecules/modals/ConfirmDeleteModal";

const { Header, Content } = Layout;

const AllTypeFilter = "all-type";

const isMasterAttributOptions = [
  { value: AllTypeFilter, label: t(tMap["attributeList.filterAllType"]) },
  { value: "true", label: t(tMap["common.master"]) },
  { value: "false", label: t(tMap["common.common"]) }
];

export const AttributeListing: React.FC = (): JSX.Element => {
  useSeedDataBaseWithProductionMocks();

  const confirmPublishModal = useRef<any>(null);
  const navigate = useNavigate();
  const store = useAttributesStore();
  const [, setAttribute] = useAttributesAtomWithImmer();
  const [form] = Form.useForm();
  const [search, setSearch] = useState<ApiSearchParam[] | undefined>();
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [sorts, setSorts] = useState<ApiSortParam[] | undefined>();
  const [selectedAttributes, setSelectedAttributes] = useState<
    AttributeEntity[]
  >([]);
  const [dataSource, setDataSource] = useState<
    ApiListResponse<AttributeEntity> | undefined
  >();

  const callApi = useCallback(async () => {
    setDataSource(undefined);

    const request: ApiListRequest = {
      pageSize: commonConstants.tablePageSize,
      page,
      sorts,
      search
    };

    const response = await apiService.getAttributes(request);

    if (isApiErrorResponse(response)) {
      showApiErrorNotification(response);

      return;
    } else if (!response) {
      // do nothing for now
    } else {
      if (attributesDebugFlags.listing.addMocks) {
        response.items = [
          ...(response?.items ?? []),
          ...attributeMocks.get.attributes
        ];
      }
      setDataSource(response);
      setAttribute(response.items);
    }
  }, [page, sorts, search, setAttribute]);

  useEffect(() => {
    callApi();
  }, [callApi, page, sorts]);

  const handleSearchFinish = (values: {
    attributeType: string;
    search: string;
  }) => {
    const isInvalidFilter =
      values.attributeType === undefined ||
      values.attributeType === AllTypeFilter;

    if (!values.search && isInvalidFilter && !search) {
      return;
    }

    const searchParams: ApiSearchParam[] = [];
    if (values.search) {
      searchParams.push({
        field: "name",
        operation: "ct",
        condition: values.search
      });
    }

    if (!isInvalidFilter) {
      searchParams.push({
        field: "isMasterAttribute",
        operation: "eq",
        condition: values.attributeType.toString()
      });
    }

    setPage(1);
    setSearch(searchParams.length ? searchParams : undefined);
  };

  const handleResetSearch = () => {
    form.resetFields();
    setPage(1);
    setSearch(undefined);
  };

  const handleCreateClick = async (data: CreateAttributeRequest) => {
    const response = await apiService.createAttribute(data);
    store.entity.addAttribute(response);
    store.entity.setDraftAttribute(response);

    if (response.id) {
      setIsCreateOpen(false);
      navigate(`/${attributesRouteConstants.attributes}/${response.name}/config`);
    }

    if (isApiErrorResponse(response)) {
      const error = response as unknown as ApiErrorResponse;
      showApiErrorNotification(error);
    }

    return response;
  };

  const handlePublishClicked = (attribute: AttributeEntity) => {
    setSelectedAttributes([attribute]);
    confirmPublishModal.current.setIsOpen(true);
  };

  const handleConfirmPublish = async () => {
    await apiService.putAttribute(
      selectedAttributes[0].id,
      selectedAttributes[0]
    );
    confirmPublishModal.current.setIsOpen(false);
    await callApi();
  };

  return (
    <span className="attribute-listing-page">
      <Header className="header" style={{ backgroundColor: themeColors.grey2 }}>
        <Row justify="space-between">
          <Col className="page-title">{t(tMap["attributeList.pageName"])}</Col>
          <Col>
            <Button
              type="primary"
              data-test={dMap["create-new-button"]}
              className="create-new-attribute-button"
              icon={<PlusOutlined key="" />}
              onClick={() => setIsCreateOpen(true)}
            >
              {t(tMap["common.createNew"])}
            </Button>
          </Col>
        </Row>
      </Header>
      <Content className="content">
        <Form
          form={form}
          name="filter-attribute"
          autoComplete="off"
          layout="inline"
          className="search-filter"
          onFinish={handleSearchFinish}
        >
          <span className="search-filter-title">
            {t(tMap["attributeList.searchFilter"])}
          </span>
          <Form.Item name="attributeType" initialValue={AllTypeFilter}>
            <Select
              className="filter-input"
              options={isMasterAttributOptions}
            />
          </Form.Item>
          <Form.Item name="search">
            <Search
              placeholder={t(tMap["attributeList.searchPlaceholder"])}
              className="filter-input"
            />
          </Form.Item>
          <Button type="link" className="button" onClick={handleResetSearch}>
            {t(tMap["attributeList.resetButton"])}
          </Button>
          <Button className="button" htmlType="submit">
            {t(tMap["attributeList.applyButton"])}
          </Button>
        </Form>
        <div className="list-table">
          <AttributesTable
            dataSource={dataSource}
            page={page}
            onPageChanged={setPage}
            onSortChanged={setSorts}
            onPublishClicked={handlePublishClicked}
          />
        </div>
        <CreateNewAttributeModal
          isOpen={isCreateOpen}
          onCancelClicked={() => setIsCreateOpen(false)}
          onCreateClicked={handleCreateClick}
        />
        {/* TODO: consider completely remove below Publish modal, (Daisy said: maybe we don't need it in the future) */}
        <ConfirmDeleteModal
          ref={confirmPublishModal}
          onDeleteClicked={handleConfirmPublish}
        >
          <div>
            {t(tMap["attributeList.confirmDelete.text"]).replace(
              "{0}",
              selectedAttributes[0]?.name
            )}
          </div>
          <br />
          <div>
            {`${t(tMap["common.confirmDelete.detailed"])} ${t(
              tMap["attributeList.confirmDelete.detailed"]
            )}`}
            <span className="delete-confirm-highlight">
              {t(tMap["common.confirmDelete.highlight"])}
            </span>
          </div>
        </ConfirmDeleteModal>
      </Content>
    </span>
  );
};
