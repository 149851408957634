import { Col, Popover, Row, Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { t, tMap } from "../../../../../../features/i18n/translation";
import styled from "styled-components";

const Container = styled(Row)`
  padding: 20px;
  padding-bottom: 16px;

  .ant-typography {
    margin: 0;
  }

  .create-button {
    border-color: #eee;
  }
`;

const PopoverContent = styled.div`
  .action-button {
    display: block;

    &:first-child {
      margin-bottom: 8px;
    }
  }
`;

interface ProductsHeaderProps {
  onNewProductClick: () => void;
  onNewVariantClick: () => void;
}

const ProductsHeader: React.FC<ProductsHeaderProps> = ({
  onNewProductClick,
  onNewVariantClick
}) => {
  // Using ant design col flex https://ant.design/components/grid#components-grid-demo-flex-stretch
  return (
    <Container justify={"space-between"}>
      <Typography.Title level={4}>{t(tMap["common.pim"])}</Typography.Title>

      <Col>
        <Popover
          placement="bottomRight"
          content={
            <PopoverContent>
              <Button
                className="action-button"
                type="text"
                onClick={onNewProductClick}
              >
                {t(tMap["product.createNew.product"])}
              </Button>
              <Button
                className="action-button"
                type="text"
                onClick={onNewVariantClick}
              >
                {t(tMap["product.createNew.variant"])}
              </Button>
            </PopoverContent>
          }
        >
          <Button
            dev-id="newProductButton"
            className="create-button"
            type="primary"
            icon={<PlusOutlined />}
          >
            {t(tMap["common.createNew"])}
          </Button>
        </Popover>
      </Col>
    </Container>
  );
};

export default ProductsHeader;
