import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { Space, SpaceProps, Typography } from "antd";
import type { CSSProperties, PropsWithChildren } from "react";
import React from "react";

export interface ITextWithLabelProps {
  label: React.ReactNode;
  direction?: SpaceProps["direction"];
  size?: SpaceProps["size"];
  fontWeight?: 400 | 500 | 600 | 700;
  color?: string;
  labelStyle?: CSSProperties;
  "data-test"?: string;
}

const antdToRealCSS: Map<
  SpaceProps["direction"],
  CSSProperties["flexDirection"]
> = new Map([
  ["vertical", "column"],
  ["horizontal", "row"]
]);

const TextWithLabel: React.FC<
  React.HTMLProps<HTMLDivElement> & PropsWithChildren & ITextWithLabelProps
> = (props) => {
  const {
    label,
    direction,
    size,
    children,
    fontWeight,
    color,
    style,
    labelStyle
  } = props;
  return (
    <div // DELETED STUPID ANT DESIGN SPACE
      style={{
        display: "flex",
        flexDirection: antdToRealCSS.get(direction ?? "vertical"),
        //justifyContent: "center",
        alignItems: "center",
        width: "100%",
        gap: size ?? 0,
        ...style
      }}
      data-test={props["data-test"]}
    >
      <Typography.Text
        style={{ fontWeight: fontWeight ?? 700, color, ...labelStyle }}
        ellipsis={{ tooltip: label }}
      >
        {label}
      </Typography.Text>
      <Typography.Text
        data-test={dMap["text-value"]}
        style={{ color, width: "100%" }}
        ellipsis={{ tooltip: children }}
      >
        {children}
      </Typography.Text>
    </div>
  );
};

export default TextWithLabel;
