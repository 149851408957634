import { Tree, TreeProps } from "antd";
import { AttributeEntity } from "../../../../../models/entities/attributeEntity";
import Icon, { CaretDownFilled } from "@ant-design/icons";
import { InputTypeBuilder } from "../InputTypeBuilder";
import { AttributeTemplate } from "../../../../../models/valueObjects/attributeTemplate";
import { DataNode } from "antd/es/tree";
import { createTreeKeys } from "../../../../../common/helpers/createTreeKeys";
import "./formInputType.scss";
import { useAttributesStore } from "../../../../../state/stores/attributesStore";
import { useCallback } from "react";

export const FormInputType: React.FC<{
  attributeEntity: AttributeEntity;
}> = ({ attributeEntity }): JSX.Element => {
  const { ui } = useAttributesStore();
  const { setSelectedKeyPath } = ui;

  function buildTree(
    attributeTemplate?: AttributeTemplate,
    parentKey: string = ""
  ): DataNode[] {
    if (!attributeTemplate) return [];
    const key = createTreeKeys(parentKey, attributeTemplate.id);
    const treeData: TreeProps["treeData"] = [
      {
        key,
        // title: attributeTemplate.name,
        title: (
          <InputTypeBuilder attributeEntity={attributeTemplate} treeKey={key} />
        ),
        children: attributeTemplate.template?.flatMap((temp) => {
          const result = buildTree(temp, key);
          return result;
        })
      }
    ];

    return treeData;
  }

  const onFieldSelected = useCallback(
    (key: React.Key) => {
      if (!key) return;
      setSelectedKeyPath(key.toString());
    },
    [setSelectedKeyPath]
  );

  const treeData: TreeProps["treeData"] = buildTree(attributeEntity);
  return (
    <span className="form-input-type-container--------------------">
      <Tree
        style={{ width: "80vw" }}
        onSelect={([key]) => onFieldSelected(key)}
        treeData={treeData}
        defaultExpandAll
        showLine
        showIcon={false}
        switcherIcon={
          <Icon
            style={{ fontSize: 16 }}
            component={() => <CaretDownFilled />}
          ></Icon>
        }
      ></Tree>
    </span>
  );
};
