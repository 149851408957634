import ReactDOM from "react-dom/client";
import "./index.css";
import { ConfigProvider } from "antd";
import { themeColors } from "./common/ui/styles/themeColors.ts";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import appRoutes from "./features/routes/App.routes.tsx";

const appRouter = createBrowserRouter(appRoutes);

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      hashed: false,
      token: {
        ...themeColors
      },
      components: {
        Layout: {
          siderBg: themeColors.cyan10,
          bodyBg: themeColors.white1
        },
        Menu: {
          colorBgBase: themeColors.cyan10
        },
        Table: {
          headerBg: themeColors.grey2,
          borderRadius: 0
        },
        Tree: {
          directoryNodeSelectedBg: themeColors.grey3,
          controlItemBgActive: themeColors.white1
        }
      }
    }}
  >
    <RouterProvider router={appRouter} />
  </ConfigProvider>
  // {/* </React.StrictMode>, */}
);
