import styled from "styled-components";
import { Button, Form, Switch } from "antd";
import { useUIState } from "@pm/hooks/useUIState";
import { Label } from "@shared/ui/molecules/Label";
import { Divider } from "@shared/ui/molecules/Divider";
import { LabelText } from "@shared/ui/atoms/LabelText";
import Search from "antd/es/input/Search";
import { t, tMap } from "../../../../../../features/i18n/translation";
import { LocaleSelector } from "../../../../../../common/ui/molecules/selection/LocaleSelector";
import { DefaultOptionType } from "antd/es/select";
import { ApiSearchParam } from "../../../../../../common/types/commonTypes";
import { useEffect } from "react";
import { useLocaleOptions } from "../../../../../../common/hooks/useLocaleOptions";
import { useAtomState } from "../../../../../../common/hooks/useAtomState";
import {
  ProductListState,
  productListStateAtom
} from "@pm/state/productListState";

const MenuBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  height: 100%;
  padding: 0px 16px;
`;

const FormItem = styled(Form.Item)`
  .search {
    min-width: 150px;
  }
`;

const FormButton = styled(Button)``;

const MenuBarForm = styled(Form)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  ${LabelText} {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
  }
  ${FormItem} {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
  }
  ${FormButton} {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 1100px) {
    gap: 8px;
  }
`;

export const MenuBar: React.FC = () => {
  const { uiState, setIsSidebarOpen } = useUIState();
  const { defaultLocale, selectedLocale } = useLocaleOptions();
  const { searchProductNameValue, setState } =
    useAtomState<ProductListState>(productListStateAtom);
  const [form] = Form.useForm();

  const onFinish = ({
    search,
    locale
  }: {
    search?: string;
    locale: DefaultOptionType;
  }) => {
    setState("selectedLocale", locale);

    const searchParams: ApiSearchParam[] = [];
    if (search) {
      searchParams.push({
        field: "name",
        operation: "ct",
        condition: search
      });
    }
    setState("searchProductNameValue", searchParams);
  };

  const handleResetSearch = () => {
    form.resetFields();
    form.setFieldValue("locale", defaultLocale);
  };

  useEffect(() => {
    const search = searchProductNameValue?.[0]?.condition;
    form.setFieldsValue({
      search
    });
    form.setFieldValue("locale", selectedLocale);
  }, [selectedLocale, form, searchProductNameValue]);

  return (
    <MenuBarContainer>
      <Label
        labelText="Tree Menu"
        for={
          <Switch
            className="tree-switch"
            checked={uiState.isSidebarOpen}
            onChange={() => setIsSidebarOpen(!uiState.isSidebarOpen)}
          />
        }
      />
      <Divider length={22} />
      <MenuBarForm form={form} autoComplete="off" onFinish={onFinish}>
        <LabelText>{t(tMap["common.searchFilter"])}</LabelText>
        <FormItem name="search">
          <Search placeholder="Search" className="search" />
        </FormItem>
        <LabelText>{t(tMap["common.locale"])}</LabelText>
        <FormItem name="locale">
          <LocaleSelector />
        </FormItem>
        <FormButton type="link" onClick={handleResetSearch}>
          {t(tMap["common.reset"])}
        </FormButton>
        <FormButton htmlType="submit">{t(tMap["common.apply"])}</FormButton>
      </MenuBarForm>
    </MenuBarContainer>
  );
};
