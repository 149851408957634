import "./translateSettingButton.scss";
import { useRef, useState } from "react";
import { EditLocalsPopover } from "../../../../../../common/ui/molecules/popovers/editLocalsPopover/EditLocalsPopover";
import { AinIcon } from "../../../../../../common/ui/atoms/AinIcon";
import { TemplateSetting } from "../../../../domain/valueObjects/templateSetting";
import { Labels } from "../../../../../../common/domain/valueObjects/Label";

interface Props {
  setting: TemplateSetting;
  onTranslationConfirmed: (setting: TemplateSetting, labels: Labels[]) => void;
}

export const TranslateSettingButton: React.FC<Props> = ({
  setting,
  onTranslationConfirmed
}): JSX.Element => {
  const editLocalsPopoverRef = useRef<any>(null);

  const handleConfirmed = (labels: Labels[]) => {
    onTranslationConfirmed(setting, labels);
    editLocalsPopoverRef.current.setIsOpen(false);
  };

  return (
    <EditLocalsPopover
      ref={editLocalsPopoverRef}
      onConfirm={handleConfirmed}
      labels={setting.labels}
    >
      <AinIcon icon="translation-outlined" size={20} />
    </EditLocalsPopover>
  );
};

export default TranslateSettingButton;
