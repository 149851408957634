import { ProductTypeEnum } from "@pm/models/ProductEntity";
import { ProductDetailForm } from "@pm/ui/pages/productDetailsPage/productDetailForm/ProductDetailForm";
import { Form, Switch } from "antd";
import { useEffect, useRef, useState } from "react";

const App: React.FC = (): JSX.Element => {
  const [isEdit, setIsEdit] = useState(true);
  const switchRef = useRef<HTMLElement>(null);

  useEffect(() => {
    document.addEventListener("keydown", (e: KeyboardEvent) => {
      if (e.ctrlKey) return;

      switch (e.key) {
        case "h": {
          switchRef.current?.click();
          break;
        }
      }
    });
  }, []);

  return (
    <>
      <Form.Item label="isEdit">
        <Switch onChange={setIsEdit} ref={switchRef} value={isEdit} />
      </Form.Item>
      <ProductDetailForm
        isEdit={isEdit}
        product={{} as any}
        thumbnail={null}
        type={ProductTypeEnum.main}
      />
    </>
  );
};

export default App;
