import { useAtom } from "jotai";

import { publicationStateAtom } from "@pm/state/productPublicationState";
import { productEntityStateAtom } from "@pm/state/productManagementEntityState";
import { useEffect } from "react";
import { GetLocaleResponse } from "../../../common/domain/valueObjects/GetLocaleResponse";
import { GetChannelResponse } from "../../../common/domain/valueObjects/GetChannelResponse";
import { useCommonStore } from "../../../common/domain/state/stores/useCommonStore";
import { channelsApiService } from "../../../common/services/api/ChannelsApiService";

export const useProductPublicationState = () => {
  const [publicationState, setPublicationState] = useAtom(publicationStateAtom);
  const [productLoader] = useAtom(productEntityStateAtom);
  const { data } = productLoader;

  const setLocales = (locales: GetLocaleResponse[]) => {
    setPublicationState((v) => {
      return {
        ...v,
        locales
      };
    });
  };

  const setChannels = (channels: GetChannelResponse[]) => {
    setPublicationState((v) => {
      return {
        ...v,
        channels
      };
    });
  };

  // preload area
  const { ui } = useCommonStore();
  const { locales, channels } = ui;

  useEffect(() => {
    setLocales(locales);
  }, [locales, data?.id]);
  useEffect(() => {
    setChannels(channels);
  }, [channels, data?.id]);

  // channels may not loaded, load them now
  useEffect(() => {
    if (channels.length === 0) {
      (async function () {
        const response = await channelsApiService.getChannels();
        ui.setChannels(response.items);
      })();
    }
  }, []);

  return {
    publicationState,
    setLocales,
    setChannels
  };
};
