import { useLayoutEffect, useRef } from "react";

export const useCallbackRef = (
  callback: (...args: any[]) => void,
  deps: any[]
) => {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback, deps]);
  return callbackRef;
};
