import React, { useEffect, useState } from "react";
import { Alert, Checkbox, Form, Input, Modal, Radio } from "antd";
import { t, tMap } from "../../../../../features/i18n/translation";
import "./createNewAttributeModal.scss";
import { AttributeInputTypeEnum } from "../../../models/enums/attributeInputTypeEnum";
import { DataTypeSelector } from "../../molecules/DataTypesSelector";
import { dataTypesMapping } from "../../../common/businessLogic/dataTypesMapping";
import { CreateAttributeRequest } from "../../../models/valueObjects/createAttributeRequest";
import { SimpleFieldSvg } from "../../molecules/SimpleFieldSvg";
import { TextAreaSvg } from "../../molecules/TextAreaSvg";
import { ToggleSvg } from "../../molecules/ToggleSvg";
import { SelectionSvg } from "../../molecules/SelectionSvg";
import { UploaderSvg } from "../../molecules/UploaderSvg";
import { FormSvg } from "../../molecules/FormSvg";
import { renderInputType } from "../../molecules/renderInputType";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";

interface Props {
  isOpen: boolean;
  onCreateClicked: (
    data: CreateAttributeRequest
  ) => Promise<CreateAttributeRequest>;
  onCancelClicked: () => void;
}

const CreateNewAttributeModal: React.FC<Props> = ({
  isOpen,
  onCreateClicked,
  onCancelClicked
}) => {
  const [form] = Form.useForm<CreateAttributeRequest>();
  const [selectedInputType, setSelectedInputType] = useState(
    AttributeInputTypeEnum.simple
  );

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen, form]);

  useEffect(() => {
    form.setFieldsValue({
      inputType: selectedInputType,
      dataType: form.getFieldValue("dataType")
    });
  }, []);

  const handleOk = async () => {
    const values = await form.validateFields();
    await onCreateClicked(values);
  };

  return (
    <Modal
      title={t(tMap["createAttribute.modelTitle"])}
      okText={
        <span data-test="confirm-modal">{t(tMap["common.createNew"])}</span>
      }
      open={isOpen}
      onCancel={onCancelClicked}
      onOk={handleOk}
      width={800}
      className="create-new-attribute-modal"
      data-test={dMap["create-new-attribute-modal"]}
    >
      <Form
        form={form}
        name="create-attribute"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        layout="horizontal"
      >
        <Form.Item<CreateAttributeRequest>
          label={t(tMap["common.attributeName"])}
          data-test={dMap["attribute-name"]}
          name="name"
          rules={[
            {
              required: true,
              message: t(tMap["createAttribute.nameRequireMessage"])
            }
          ]}
        >
          <Input placeholder={t(tMap["createAttribute.namePlaceholder"])} />
        </Form.Item>

        <Form.Item<CreateAttributeRequest>
          label={t(tMap["common.description"])}
          name="description"
        >
          <Input.TextArea
            placeholder={t(tMap["createAttribute.descriptionPlaceholder"])}
          />
        </Form.Item>

        <Form.Item<CreateAttributeRequest>
          data-test={dMap["input-type"]}
          label={t(tMap["createAttribute.inputType"])}
          name="inputType"
          rules={[
            {
              required: true,
              message: t(tMap["createAttribute.inputTypeRequireMessage"])
            }
          ]}
        >
          <Radio.Group
            className="input-option-group"
            onChange={({ target }) => setSelectedInputType(target.value)}
          >
            {renderInputType(
              SimpleFieldSvg,
              AttributeInputTypeEnum.simple,
              t(tMap["createAttribute.inputType.simpleField"]),
              selectedInputType
            )}
            {renderInputType(
              TextAreaSvg,
              AttributeInputTypeEnum.textArea,
              t(tMap["common.inputTypes.textarea"]),
              selectedInputType
            )}
            {renderInputType(
              ToggleSvg,
              AttributeInputTypeEnum.toggle,
              t(tMap["createAttribute.inputType.toggle"]),
              selectedInputType
            )}
            {renderInputType(
              SelectionSvg,
              AttributeInputTypeEnum.selection,
              t(tMap["createAttribute.inputType.selection"]),
              selectedInputType
            )}
            {renderInputType(
              UploaderSvg,
              AttributeInputTypeEnum.uploader,
              t(tMap["createAttribute.inputType.fileUploader"]),
              selectedInputType
            )}
            {renderInputType(
              FormSvg,
              AttributeInputTypeEnum.form,
              t(tMap["createAttribute.inputType.form"]),
              selectedInputType
            )}
          </Radio.Group>
        </Form.Item>

        <DataTypeSelector
          form={form}
          inputType={selectedInputType}
          defaultValue={dataTypesMapping[selectedInputType][0]}
        />
      </Form>
    </Modal>
  );
};

export default CreateNewAttributeModal;
