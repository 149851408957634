import { Button, FormInstance, InputNumber } from "antd";
import { useEffect } from "react";
import { t, tMap } from "../../../../../features/i18n/translation";
import EditorViewMode from "@pm/ui/atoms/EditorViewMode";
import Form, { FormListFieldData, Rule } from "antd/es/form";
import { styled } from "styled-components";
import { NumberType } from "@pm/models/NumberType";
import { AinIcon } from "../../../../../common/ui/atoms/AinIcon";
import MultipleEditorEditMode from "@pm/ui/atoms/MultipleEditorEditMode";
import BaseEditorProps from "@pm/models/BaseEditorProps";
import getEditerName from "@pm/hooks/getEditerName";

// Use when: Input type = Simple field | Data type = number | Multiple values = true

const EditMode = styled(MultipleEditorEditMode)`
  .ant-input {
    width: calc(100% - 30px);
  }
`;

interface Props extends BaseEditorProps {
  value?: number[];
  defaultValue?: number[];
  numberType?: NumberType;
}

export const MultipleNumber: React.FC<Props> = (props): JSX.Element => {
  const {
    isEdit,
    value,
    isRequired,
    defaultValue,
    numberType,
    form,
    formItemName
  } = props;
  const translatedName = getEditerName(props);

  useEffect(() => {
    form.setFieldValue(formItemName, value ?? defaultValue);
  }, [value, isEdit]);

  if (!isEdit) {
    const resolveViewValue = () => {
      if (!value?.length) {
        return "--";
      }

      if (value.length === 1) {
        return value[0];
      }

      return value.map((x, i) => <div key={i}>{`${i + 1}. ${x}`}</div>);
    };

    return (
      <EditorViewMode>
        <div className="name">{translatedName}</div>
        <div>{resolveViewValue()}</div>
      </EditorViewMode>
    );
  }

  const rules: Rule[] = [
    {
      required: true,
      message: t(tMap["common.requireInputMessage"])
    }
  ];
  if (numberType == NumberType.Rounded) {
    rules.push({
      validator: (_, value) => {
        if (!Number.isInteger(value)) {
          return Promise.reject(
            new Error(t(tMap["common.roundedNumberMessage"]))
          );
        }
        return Promise.resolve();
      }
    });
  }

  return (
    <EditMode isRequired={isRequired}>
      <div className="name">{translatedName}</div>
      <Form.List name={formItemName} initialValue={defaultValue}>
        {(fields, { add, remove }) => {
          let values = fields;
          if (!values && defaultValue?.length) {
            values = defaultValue.map<FormListFieldData>((x, i) => ({
              name: i,
              key: i
            }));
          }
          if (isRequired && !values.length) {
            values = [{ name: 0, key: 0 }];
          }

          return (
            <div>
              {values.map((field) => (
                <Form.Item {...field} name={undefined}>
                  <Form.Item
                    {...field}
                    initialValue={
                      field.key < (defaultValue?.length || -1)
                        ? defaultValue![field.key]
                        : undefined
                    }
                    rules={rules}
                    noStyle
                  >
                    <InputNumber
                      step={
                        numberType === NumberType.Decimal ? 0.01 : undefined
                      }
                    />
                  </Form.Item>
                  {fields.length > 1 || !isRequired ? (
                    <AinIcon
                      size={20}
                      icon="delete-outlined-gray"
                      className="delete-icon"
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Button type="primary" onClick={() => add()}>
                {t(tMap["common.addValue"])}
              </Button>
            </div>
          );
        }}
      </Form.List>
    </EditMode>
  );
};

export default MultipleNumber;
