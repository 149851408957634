import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import { Upload } from "antd";
import { t, tMap } from "../../../../features/i18n/translation";
import { LabelText } from "./LabelText";
import { AssetMinimumInfo } from "@pm/models/ProductEntity";
import { assetApiService } from "@shared/services/AssetApiService";
import { AssetType } from "@shared/entities/AssetType";

const Dropper = styled(Upload.Dragger)`
  .ant-upload-drag {
    padding: 8px;
    min-height: 120px;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 1;
    position: relative;

    .ant-upload {
      padding: 0;
    }

    .anticon-file {
      color: #878787 !important;
    }
  }

  .ant-upload-list-item-name {
    color: #13c2c2;
  }

  .preview-image {
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    position: absolute;
    object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export interface DnDUploaderProps extends UploadProps {
  id?: string;
  assetType: AssetType;
  files?: AssetMinimumInfo[];
  showPreview?: boolean;
  onChange?: (value: any) => void;
}

export const DnDUploader: React.FC<DnDUploaderProps> = ({
  id,
  files,
  multiple,
  showPreview,
  onChange,
  assetType,
  ...rest
}) => {
  const [previewUrl, setPreviewUrl] = useState<string>();
  const assetList = useRef<AssetMinimumInfo[] | undefined>(files);

  useEffect(() => {
    if (files?.length) {
      setPreviewUrl(files[0].fileLink);
    }
  }, [files]);

  const uploadedFiles = files?.map((x) => ({
    name: x.fileName,
    uid: x.id,
    status: "done",
    thumbUrl: x.fileLink
  }));
  const [fileList, setFileList] = useState<any[]>(uploadedFiles ?? []);

  const handleRemove = (file: UploadFile) => {
    setPreviewUrl(undefined);
    setFileList(multiple ? fileList?.filter((x) => x.uid !== file.uid) : []);

    assetList.current = assetList.current?.filter((x) => x.id !== file.uid);
    onChange && onChange(multiple ? assetList.current : undefined);
  };

  const handleBeforeUpload = (file: any, files: any[]) => {
    setFileList(multiple ? [...files, ...fileList] : [file]);

    return true;
  };

  const handleCustomRequest = async ({ onError, file }: any) => {
    const uploadFile = file as File;

    if (!uploadFile) {
      return onError && onError(new Error("No file provided"));
    }

    try {
      const response = await assetApiService.createAsset([
        {
          file: uploadFile,
          fileName: uploadFile.name,
          type: assetType
        }
      ]);

      if (response && response[0]) {
        const asset = {
          id: response[0].id,
          fileName: response[0].fileName,
          fileLink: response[0].fileLink
        };

        setPreviewUrl(asset.fileLink);
        if (!multiple) {
          assetList.current = [asset];
          onChange && onChange(asset);
        } else {
          if (!assetList.current) assetList.current = [];
          assetList.current.push(asset);
          onChange && onChange(assetList.current);
        }
      }
    } catch (error) {
      return onError && onError(error);
    }
  };

  return (
    <Dropper
      id={id}
      name={id ?? "file-upload"}
      multiple={multiple}
      fileList={fileList}
      beforeUpload={handleBeforeUpload}
      onRemove={handleRemove}
      customRequest={handleCustomRequest}
      accept=".png,.gif,.jpg"
      {...rest}
    >
      {showPreview && previewUrl ? (
        <img className="preview-image" src={previewUrl} />
      ) : (
        <span className="empty-content">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <LabelText>{t(tMap["common.uploadDraggerText"])}</LabelText>
        </span>
      )}
    </Dropper>
  );
};
