import { commonConstants } from "../constants/commonConstants";
import { useCommonStore } from "../domain/state/stores/useCommonStore";
import { DefaultOptionType } from "antd/es/select";
import {
  ProductListState,
  productListStateAtom
} from "@pm/state/productListState";
import { useAtomState } from "./useAtomState";

export function useLocaleOptions(): {
  /** Api locale as options */
  localeOptions: DefaultOptionType[];
  /** Default locale from user, else from commonConstants */
  defaultLocale: DefaultOptionType | undefined;
  /** Selected locale first from store, else from default */
  selectedLocale: DefaultOptionType | undefined;
} {
  const { selectedLocale: storeLocale } =
    useAtomState<ProductListState>(productListStateAtom);
  const { locales } = useCommonStore().ui;

  const localeOptions = locales.map((local) => ({
    label: local.name,
    value: local.code
  }));
  const appDefaultLocale = localeOptions.find(
    (l) => l.value === commonConstants.locale.default
  );
  const defaultLocaleByUser = locales.find((l) => l.isDefault);
  const defaultLocaleByUserAsOption: DefaultOptionType = {
    label: defaultLocaleByUser?.name,
    value: defaultLocaleByUser?.code
  };
  const defaultLocale = defaultLocaleByUser
    ? defaultLocaleByUserAsOption
    : appDefaultLocale;
  const localeInOptions = localeOptions.find(
    (l) => l.value === storeLocale
  );
  const selectedLocale = localeInOptions ?? appDefaultLocale;

  return { selectedLocale, localeOptions, defaultLocale };
}
