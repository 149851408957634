import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "antd";
import { t, tMap } from "../../../../features/i18n/translation";
import styled from "styled-components";

const Container = styled.div`
  margin: 16px 0;
`;

interface Props {
  onDeleteClicked: () => void;
  children: React.ReactNode;
}

const ConfirmDeleteModal = forwardRef(
  ({ children, onDeleteClicked }: Props, ref) => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      setIsOpen: setIsOpenModal
    }));

    return (
      <Modal
        destroyOnClose
        forceRender={false}
        maskClosable={false}
        title={t(tMap["common.deleteConfirmation"])}
        okText={t(tMap["common.delete"])}
        cancelText={t(tMap["common.notNow"])}
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        onOk={onDeleteClicked}
        okButtonProps={{ danger: true }}
        width={360}
      >
        <Container>
          {children}
          <br />
          <div>{t(tMap["common.confirmDelete.question"])}</div>
        </Container>
      </Modal>
    );
  }
);

export default ConfirmDeleteModal;
