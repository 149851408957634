export const downloadFile = async (url: string, fileName: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement("a");
    link.style.display = "none";
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    document.body.appendChild(link);
    setTimeout(() => {
      link.click();
      document.body.removeChild(link);
    });
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const isImageFileName = (fileName: string) => {
  // Reference here: https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
  const imageExtensions = [
    "webp",
    "apng",
    "avif",
    "jpg",
    "jpeg",
    "jfif",
    "pjpeg",
    "pjp",
    "svg",
    "png",
    "gif",
    "bmp",
    "ico",
    "cur"
  ];
  const extension = fileName.split(".").pop()?.toLowerCase();

  if (!extension) {
    return false;
  }

  return imageExtensions.includes(extension);
};
