import cloneDeep from "../../../common/modules/cloneDeep";
import { sortObjectFields } from "../../../common/modules/sortObjectFields";
import {
  isTopLevelKey,
  getKeysFromKeyPath
} from "../common/helpers/createTreeKeys";
import { AttributeEntity } from "../models/entities/attributeEntity";
import { AttributeTemplate } from "../models/valueObjects/attributeTemplate";

export function updateAttributeImmer(
  currentAttribute: AttributeEntity | null,
  updates: Partial<AttributeEntity | AttributeTemplate> | null,
  {
    givenId,
    keyPath,
    visistor
  }: {
    keyPath: string;
    givenId?: string;
    /**
     * Difference to the 2nd args `updates`:
     * To do updates in the a nested object, based on the `keyPath`, one would need to first
     *   1. find the target nested object
     *   2. apply the update
     * This visitor helps to do exactly that.
     */
    visistor?: (
      attributeTemplate: AttributeTemplate,
      /** parent of `attributeTemplate */
      parent?: AttributeTemplate[]
    ) => AttributeTemplate | undefined | void;
  }
) {
  if (!currentAttribute) return null;
  /** When url does not have id, allow to pass in an id */
  let draft = cloneDeep(currentAttribute);
  const isTopLevel = draft.id && isTopLevelKey(keyPath);
  if (isTopLevel) {
    const { template, labels, options, ...canUpdateFields } = draft;
    const shouldUseVisitor = !!(updates === null && visistor);
    const finalUpdates = shouldUseVisitor ? visistor(draft) : updates;
    draft = sortObjectFields({
      ...canUpdateFields,
      template,
      labels,
      options,
      ...finalUpdates
    });
  } else {
    const [_, ...otherKeys] = getKeysFromKeyPath(keyPath);

    // recursiveUpdate(draft, () => {
    findAndModify(draft.template, otherKeys);
  }

  return draft;

  function findAndModify(nested: AttributeTemplate[] | null, keys: string[]) {
    if (!nested) return;
    const [key, ...remainingKeys] = keys;

    nested.forEach((item, index) => {
      if (keys.length > 1) {
        findAndModify(item.template, remainingKeys);
        return;
      }

      if (key === item.id) {
        const shouldUseVisitor = !!(updates == null && visistor);
        const finalUpdates = shouldUseVisitor
          ? visistor(item, nested)
          : updates;
        if (key !== finalUpdates?.id) return;

        const result = sortObjectFields<AttributeTemplate>({
          ...item,
          ...finalUpdates
        });
        nested[index] = result;
      }
      findAndModify(item.template, remainingKeys);
    });
  }
}
