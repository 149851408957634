import { AttributeDataTypeEnum } from "../../models/enums/attributeDataTypeEnum";
import { t, tMap } from "../../../../features/i18n/translation";
import { AttributeInputTypeEnum } from "../../models/enums/attributeInputTypeEnum";
import { AttributesInputSettings } from "../../models/valueObjects/inputSettings";
import { TagProps } from "antd";
import { AttributeStatusEnum } from "../../models/enums/attributeStatus";
import { AttributesDataSettings } from "../../models/valueObjects/dataSettings";
import { AttributeTypesEnum } from "@am/types/attributeCommonTypes";
import { DefaultOptionType } from "antd/es/select";

type StatusMetaData = {
  label: string;
  abbrev?: string;
  color?: TagProps["color"];
  beKey?: string;
};

export const masterAttributeUiMapping: () => Record<
  AttributeTypesEnum,
  StatusMetaData | undefined
> = () => ({
  [AttributeTypesEnum.system]: {
    label: t(tMap["common.system"]),
    beKey: "isSystemAttribute"
  },
  [AttributeTypesEnum.master]: {
    label: t(tMap["common.master"]),
    beKey: "isMasterAttribute"
  },
  [AttributeTypesEnum.common]: {
    label: t(tMap["common.common"])
  }
});

export function getAttributeTypeAsSelectOptions(): DefaultOptionType[] {
  const options = Object.entries(
    masterAttributeUiMapping()
  ).map<DefaultOptionType>(([key, label]) => ({
    label: label?.label,
    value: key
  }));
  return options;
}

type OptionalExceptFor<T, TRequired extends keyof T = keyof T> = Partial<
  Pick<T, Exclude<keyof T, TRequired>>
> &
  Required<Pick<T, TRequired>>;

export const formLayoutOrientatoinUiMapping: () => Record<
  // @ts-expect-error should scope settings
  AttributesInputSettings["layoutOrientation"],
  StatusMetaData | undefined
> = () => ({
  horizontal: {
    label: t(
      tMap["attributeDetails.inputSettings.layoutOrientation.horizontal"]
    )
  },
  vertical: {
    label: t(tMap["attributeDetails.inputSettings.layoutOrientation.vertical"])
  }
});

export const formSelectionTypeUiMapping: () => Record<
  // @ts-expect-error should scope settings
  AttributesInputSettings["selectionType"],
  StatusMetaData | undefined
> = () => ({
  single: {
    label: t(tMap["common.single"])
  },
  multiple: {
    label: t(tMap["common.multiple"])
  }
});

export const dataTypesUiMetadata: () => Record<
  AttributeDataTypeEnum,
  StatusMetaData | undefined
> = () => ({
  [AttributeDataTypeEnum.text]: {
    label: t(tMap["common.dataTypes.text"])
  },
  [AttributeDataTypeEnum.email]: {
    label: t(tMap["common.dataTypes.email"])
  },
  [AttributeDataTypeEnum.phone]: {
    label: t(tMap["common.dataTypes.phone"])
  },
  [AttributeDataTypeEnum.number]: {
    label: t(tMap["common.dataTypes.number"])
  },
  [AttributeDataTypeEnum.date]: {
    label: t(tMap["common.dataTypes.date"])
  },
  [AttributeDataTypeEnum.time]: {
    label: t(tMap["common.dataTypes.time"])
  },
  [AttributeDataTypeEnum.file]: {
    label: t(tMap["common.dataTypes.file"])
  },
  [AttributeDataTypeEnum.object]: {
    label: t(tMap["common.dataTypes.object"])
  },
  [AttributeDataTypeEnum.boolean]: {
    label: t(tMap["common.dataTypes.boolean"])
  },
  [AttributeDataTypeEnum.dateTime]: {
    label: t(tMap["common.dataTypes.dateTime"])
  }
});

export const inputTypesUiMetadata: () => Record<
  AttributeInputTypeEnum,
  StatusMetaData | undefined
> = () => ({
  [AttributeInputTypeEnum.simple]: {
    label: t(tMap["common.inputTypes.simple"])
  },
  [AttributeInputTypeEnum.textArea]: {
    label: t(tMap["common.inputTypes.textarea"])
  },
  [AttributeInputTypeEnum.toggle]: {
    label: t(tMap["common.inputTypes.toggle"])
  },
  [AttributeInputTypeEnum.selection]: {
    label: t(tMap["common.inputTypes.selection"])
  },
  [AttributeInputTypeEnum.uploader]: {
    label: t(tMap["common.inputTypes.uploader"])
  },
  [AttributeInputTypeEnum.form]: {
    label: t(tMap["common.inputTypes.form"])
  }
});

export const numberTypeUiMapping: () => Record<
  AttributesDataSettings["numberType"],
  StatusMetaData | undefined
> = () => ({
  rounded: {
    label: t(tMap["common.rounded"])
  },
  decimal: {
    label: t(tMap["common.decimal"])
  }
});

export const attributeStatusUiMapping: () => Record<
  AttributeStatusEnum,
  StatusMetaData | undefined
> = () => ({
  published: {
    label: t(tMap["common.published"]),
    color: "cyan"
  },
  unpublished: {
    label: t(tMap["common.unpublished"])
  }
});
