import { Button, Input } from "antd";
import { useEffect } from "react";
import { t, tMap } from "../../../../../features/i18n/translation";
import EditorViewMode from "@pm/ui/atoms/EditorViewMode";
import Form, { FormListFieldData, Rule } from "antd/es/form";
import { styled } from "styled-components";
import { AinIcon } from "../../../../../common/ui/atoms/AinIcon";
import MultipleEditorEditMode from "@pm/ui/atoms/MultipleEditorEditMode";
import BaseEditorProps from "@pm/models/BaseEditorProps";
import getEditerName from "@pm/hooks/getEditerName";

// Use when: Input type = Simple field | Data type = text | Multiple values = true

const EditMode = styled(MultipleEditorEditMode)`
  .ant-input {
    width: calc(100% - 30px);
  }
`;

interface Props extends BaseEditorProps {
  value?: string[];
  defaultValue?: string[];
  pattern?: string;
}

export const MultipleText: React.FC<Props> = (props): JSX.Element => {
  const {
    isEdit,
    value,
    isRequired,
    defaultValue,
    pattern,
    form,
    formItemName
  } = props;
  const translatedName = getEditerName(props);

  useEffect(() => {
    form.setFieldValue(formItemName, value ?? defaultValue);
  }, [value, isEdit]);

  if (!isEdit) {
    const resolveViewValue = () => {
      if (!value?.length) {
        return "--";
      }

      if (value.length === 1) {
        return value[0];
      }

      return value.map((x, i) => <div key={i}>{`${i + 1}. ${x}`}</div>);
    };

    return (
      <EditorViewMode>
        <div className="name">{translatedName}</div>
        <div>{resolveViewValue()}</div>
      </EditorViewMode>
    );
  }

  const rules: Rule[] = [
    {
      required: true,
      message: t(tMap["common.requireInputMessage"])
    }
  ];
  if (pattern) {
    rules.push({
      pattern: new RegExp("^([^0-9]*)$"),
      message: t(tMap["common.patternInputMessage"])
    });
  }

  return (
    <EditMode isRequired={isRequired}>
      <div className="name">{translatedName}</div>
      <Form.List name={formItemName} initialValue={defaultValue}>
        {(fields, { add, remove }) => {
          let values = fields;
          if (!values && defaultValue?.length) {
            values = defaultValue.map<FormListFieldData>((x, i) => ({
              name: i,
              key: i
            }));
          }
          if (isRequired && !values.length) {
            values = [{ name: 0, key: 0 }];
          }

          return (
            <div>
              {values.map((field) => (
                <Form.Item {...field} name={undefined}>
                  <Form.Item
                    {...field}
                    initialValue={
                      field.key < (defaultValue?.length || -1)
                        ? defaultValue![field.key]
                        : undefined
                    }
                    rules={rules}
                    noStyle
                  >
                    <Input />
                  </Form.Item>
                  {fields.length > 1 || !isRequired ? (
                    <AinIcon
                      size={20}
                      icon="delete-outlined-gray"
                      className="delete-icon"
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Button type="primary" onClick={() => add()}>
                {t(tMap["common.addValue"])}
              </Button>
            </div>
          );
        }}
      </Form.List>
    </EditMode>
  );
};

export default MultipleText;
