import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Form, Input, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { CategoryListResponse } from "@cm/domain/valueObjects/categoryListResponse";
import { t, tMap } from "../../../../../features/i18n/translation";
import { CreateProductRequest } from "@pm/models/CreateProductRequest";
import { productsApiService } from "@pm/api/ProductsApiService";
import { useUIStateReadOnly } from "@pm/hooks/useUIState";
import { styled } from "styled-components";
import { productRouteConstants } from "@pm/productRoutes";
import CategoryTreeSelect from "../../../../../common/ui/molecules/CategoryTreeSelect";

const ProductForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 24px;
    }
  }

  .ant-modal-header {
    margin-bottom: 16px;
  }
`;

interface Props {}

const CreateProductModal = forwardRef((props: Props, ref) => {
  const [form] = Form.useForm<CreateProductRequest>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { allCategories } = useUIStateReadOnly();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({ setIsOpen }));

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen, form]);

  const handleOk = async () => {
    const values = await form.validateFields();
    const { id } = await productsApiService.createProduct(values);

    if (!id) {
      return;
    }

    navigate(`/${productRouteConstants.productList}/${id}`);
    setIsOpen(false);
  };

  return (
    <Modal
      title={t(tMap["product.createNew.title"])}
      okText={t(tMap["common.createNew"])}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={handleOk}
      width={800}
      destroyOnClose
    >
      <ProductForm
        form={form}
        name="create-product"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        layout="horizontal"
      >
        <Form.Item<CreateProductRequest>
          label={t(tMap["product.createNew.name"])}
          name="name"
          rules={[
            {
              required: true,
              message: t(tMap["product.createNew.nameRequireMessage"])
            }
          ]}
        >
          <Input placeholder={t(tMap["product.createNew.namePlaceholder"])} />
        </Form.Item>

        <Form.Item<CreateProductRequest>
          label={t(tMap["common.categories"])}
          name="categories"
          rules={[
            {
              required: true,
              message: t(tMap["product.createNew.categoriesRequireMessage"])
            }
          ]}
        >
          <CategoryTreeSelect allCategories={allCategories} isMultiple />
        </Form.Item>
        <Form.Item<CreateProductRequest>
          label={t(tMap["product.createNew.description"])}
          name="description"
        >
          <Input.TextArea
            placeholder={t(tMap["product.createNew.descriptionPlaceholder"])}
          />
        </Form.Item>
      </ProductForm>
    </Modal>
  );
});

export default CreateProductModal;
