import { getToken } from "../../features/authentication/getToken";
import { commonConstants } from "../constants/commonConstants";
import { isApiErrorResponse } from "../domain/valueObjects/ApiErrorResponse";
import { showApiErrorNotification } from "../modules/showNotifications";
import { ApiListRequest } from "../types/commonTypes";

const baseUrl = `${commonConstants.api.baseUrl}/${commonConstants.api.version}`;

const defaultHeaders = new Headers();
defaultHeaders.append("Accept", "application/json");

const buildSearchParams = <T extends ApiListRequest>(
  request: T,
  customParams?: URLSearchParams
): URLSearchParams | undefined => {
  if (!request) {
    return;
  }

  let params = customParams;
  if (!customParams) {
    params = new URLSearchParams();
  }

  params!.append("pageSize", request.pageSize.toString());
  params!.append("page", (request.page - 1).toString());

  if (request.sorts && request.sorts.length) {
    const sorts: string[] = [];
    request.sorts.forEach((s) => {
      if (s.order === "ascend") {
        sorts.push(`${s.field} asc`);
      } else if (s.order === "descend") {
        sorts.push(`${s.field} desc`);
      }
    });
    params!.append("sort", JSON.stringify(sorts));
  }

  if (request.search && request.search.length) {
    const search = request.search.map((s) => [
      s.operation,
      s.field,
      s.condition
    ]);
    params!.append("search", JSON.stringify(search));
  }

  return params;
};

const fetcher = async <InputType = unknown, OutputType = unknown>(
  url: string,
  method: string,
  body?: InputType
): Promise<OutputType> => {
  const token = getToken();

  if (!token) {
    // TODO: handle user un-authenticated
  }

  const finalUrl = `${baseUrl}${url}`;
  const headers = new Headers();
  let finalBody: any = body;

  headers.append("Accept", "application/json");
  headers.append("Authorization", `Bearer ${JSON.parse(token!)}`);

  if (body && !(body instanceof FormData)) {
    headers.append("Content-Type", "application/json");
    finalBody = JSON.stringify(body);
  }

  return new Promise((resolve) => {
    fetch(finalUrl, { headers, method, body: finalBody }).then(
      async (response) => {
        let responseObject;
        try {
          responseObject = await response.json();
        } catch (error) {
          // Log the error for debugging purposes or handle it appropriately
          console.error('Error parsing JSON response:', error);
          // Optionally, you can set responseObject to null or a default value
          responseObject = null; // Or some default value if that makes sense for your application
        }

        if (isApiErrorResponse(responseObject)) {
          showApiErrorNotification(responseObject);
        } else if (response.status === 500) {
          showApiErrorNotification({
            status: "Failed",
            statusCode: 500,
            code: -1,
            errors: {},
            reason: "Server can not process the request",
            note: "",
            message: "Server temporary unavailable, please try again later"
          });
        }        

        resolve(responseObject);
      }
    );
  });
};

class BaseApiService {
  private urlPath = "";
  public buildSearchParams = buildSearchParams;

  constructor(urlPath = "") {
    this.urlPath = urlPath;
  }

  async getFetcher<OutputType = unknown>(
    url: string,
    params?: URLSearchParams
  ): Promise<OutputType> {
    if (this.urlPath) {
      url = this.urlPath.concat(url);
    }
    if (params) {
      url = url.concat(`?${params.toString()}`);
    }

    return fetcher(url, "GET");
  }

  async postFetcher<InputType = unknown, OutputType = unknown>(
    url: string,
    body: InputType
  ): Promise<OutputType> {
    if (this.urlPath) {
      url = this.urlPath.concat(url);
    }
    return fetcher(url, "POST", body);
  }

  async putFetcher<InputType = unknown, OutputType = unknown>(
    url: string,
    body: InputType
  ): Promise<OutputType> {
    if (this.urlPath) {
      url = this.urlPath.concat(url);
    }
    return fetcher(url, "PUT", body);
  }

  async deleteFetcher<OutputType = unknown>(url: string): Promise<OutputType> {
    if (this.urlPath) {
      url = this.urlPath.concat(url);
    }
    return fetcher(url, "DELETE");
  }
}

export const ApiService = new BaseApiService();
export default BaseApiService;
