import { Flex } from "antd";
import { t, tMap } from "../../../../../../../features/i18n/translation";
import {
  AttributeObjectOption,
  FileOption
} from "../../../../../models/valueObjects/attributeObjectOptions";
import { ColumnType } from "antd/es/table";
import { AttributeTemplate } from "../../../../../models/valueObjects/attributeTemplate";
import { AttributeDataTypeEnum } from "../../../../../models/enums/attributeDataTypeEnum";
import { AinIcon } from "../../../../../../../common/ui/atoms/AinIcon";
import { themeColors } from "../../../../../../../common/ui/styles/themeColors";
import React from "react";
import { AinEditTable } from "../../../../../../../common/ui/organisms/AinEditableTable";
import { useCommonStore } from "../../../../../../../common/domain/state/stores/useCommonStore";
import { DefaultOptionType } from "antd/es/select";

type Column = ColumnType<AttributeObjectOption>;

export const DetailsOptionTable: React.FC<{
  dataSource: AttributeObjectOption[] | null;
  locale: DefaultOptionType;
  template: AttributeTemplate;
  "data-test"?: string;
}> = ({ dataSource, locale, template, ...rest }): JSX.Element => {
  const { locales } = useCommonStore().ui;
  const targetLocale = locales.find((l) => l.code === locale.value);

  const keyColumn: Column = {
    title: t(tMap["common.key"]),
    dataIndex: "key",
    key: "key",
    sorter: {
      multiple: 1
    },
    render: (value) => <>{value}</>
  };
  const languageRender = (
    displayValues: AttributeObjectOption["displayValues"]
  ) => (
    <>
      {
        displayValues.find(
          (displayValue) => displayValue.locale === locale?.value
        )?.value
      }
    </>
  );
  const languageColumn = {
    title: targetLocale?.name,
    dataIndex: "displayValues",
    key: "displayValues",
    sorter: {
      multiple: 1
    },
    render: languageRender
  };
  const displayTextColumn: Column = {
    title: t(tMap["common.displayText"]),
    dataIndex: "displayValues",
    key: "displayValues",
    sorter: {
      multiple: 1
    },
    render: languageRender
  };

  const columns = [] as Column[];

  switch (template.dataType) {
    case AttributeDataTypeEnum.text: {
      columns.push(keyColumn, languageColumn);
      break;
    }
    case AttributeDataTypeEnum.number:
    case AttributeDataTypeEnum.date:
    case AttributeDataTypeEnum.time: {
      const optionsValueColumn: Column = {
        title: t(tMap["common.optionsValue"]),
        dataIndex: "THIS_SHOULD_BE_REMOVED_displayValues",
        key: "THIS_SHOULD_BE_REMOVED_displayValues",
        sorter: {
          multiple: 1
        },
        render: (value) => <>{value}</>
      };
      columns.push(optionsValueColumn);
      break;
    }
    case AttributeDataTypeEnum.file: {
      const displayImageColumn: Column = {
        title: t(tMap["common.displayImage"]),
        dataIndex: "files",
        key: "files",
        sorter: {
          multiple: 1
        },
        render: (files: FileOption[]) => {
          const targetFile = files.find(
            (file) => file.locale === locale?.value
          );
          return (
            <span>
              <Flex>
                <Flex
                  style={{
                    border: `1px solid ${themeColors.black015}`,
                    borderRadius: 6,
                    width: 46,
                    height: 46
                  }}
                  justify="center"
                  align="center"
                >
                  <img src="/AinavioLogo.png" alt="" width="40" height="40" />
                </Flex>
                <AinIcon icon="paper-clip-outlined" />
                <span style={{ color: themeColors.colorPrimary }}>
                  {targetFile?.fileId}
                </span>
              </Flex>
            </span>
          );
        }
      };

      columns.push(keyColumn, displayImageColumn);
      break;
    }
    case AttributeDataTypeEnum.object: {
      {
        template.options;
      }
      const option = template.options?.length
        ? template.options[0]
        : ({} as AttributeObjectOption);
      const {
        key,
        displayValues,
        THIS_SHOULD_BE_REMOVED_displayValues,
        files,
        ...rest
      } = option;
      const additionalColumns = Object.entries(rest).map(
        ([columnName, value], index) => {
          const result: Column = {
            title: `Field ${index + 1}: ${columnName}`,
            dataIndex: columnName,
            key: columnName,
            sorter: {
              multiple: 1
            },
            render: (value) => <>{value}</>
          };
          return result;
        }
      );
      columns.push(keyColumn, displayTextColumn, ...(additionalColumns ?? []));
      break;
    }
  }

  return (
    <AinEditTable
      data-test={rest["data-test"]}
      selectedLocale={locale}
      dataSource={dataSource ?? []}
      columns={columns}
    />
  );
};
