import { atom } from "jotai";
import { GetLocaleResponse } from "../../../common/domain/valueObjects/GetLocaleResponse";
import { GetChannelResponse } from "../../../common/domain/valueObjects/GetChannelResponse";

export interface ProductPublicationState {
  locales: GetLocaleResponse[];
  channels: GetChannelResponse[];
}

export const publicationStateAtom = atom({
  locales: [],
  channels: []
} as ProductPublicationState);
