export function getJsType(data: any) {
  const isArray = Array.isArray(data);
  const isObject = typeof data === "object" && !isArray;
  const isPrimitive = !isArray && !isObject;
  return {
    isArray,
    isObject,
    isPrimitive
  };
}
