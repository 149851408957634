import styled from "styled-components";

const defaultColor = "#e8e8e8";
const defaultStrength = 1;

export interface DividerProps {
  strengthInPx?: number;
  color?: string;
  dir?: "vertical" | "horizontal";
  length?: number;
}
export const Divider = styled.div<DividerProps>`
  width: ${(props) => getWidth(props)};
  height: ${(props) => getHeight(props)};
  background-color: ${(props) => (props.color ? props.color : defaultColor)};
  display: inline-block;
  margin: ${(props) => getMargin(props)};
`;

const getHeight = (props: DividerProps) => {
  if (props.dir === "horizontal") {
    return props.strengthInPx ?? defaultStrength + "px";
  } else {
    return props.length ? props.length + "px" : "100%";
  }
};

const getWidth = (props: DividerProps) => {
  if (props.dir === undefined || props.dir === "vertical") {
    return props.strengthInPx ?? defaultStrength + "px";
  } else {
    return props.length ? props.length + "px" : "100%";
  }
};

const getMargin = (props: DividerProps) => {
  if (props.dir === "horizontal") {
    return "0px auto";
  } else {
    return "auto 0px";
  }
};
