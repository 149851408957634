import { atom, useAtom } from "jotai";
import { GetLocaleResponse } from "../../valueObjects/GetLocaleResponse";
import { GetChannelResponse } from "../../valueObjects/GetChannelResponse";
import { SetAtom } from "../../../types/jotaiHelperTypes";

const localesAtom = atom<GetLocaleResponse[]>([]);
const channelsAtom = atom<GetChannelResponse[]>([]);

type UiStoreReturn = {
  locales: GetLocaleResponse[];
  setLocales: SetAtom<GetLocaleResponse[]>;
  channels: GetChannelResponse[];
  setChannels: SetAtom<GetChannelResponse[]>;
};

export const useCommonUiStore = (): UiStoreReturn => {
  const [locales, setLocales] = useAtom(localesAtom);
  const [channels, setChannels] = useAtom(channelsAtom);

  const result: UiStoreReturn = {
    locales,
    setLocales,
    channels,
    setChannels
  };
  return result;
};
