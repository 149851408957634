import React, { useContext, useEffect, useMemo, useState } from "react";
import { Layout, Menu, Spin, type MenuProps } from "antd";
import { AinIcon } from "./common/ui/atoms/AinIcon";
import useDevShortcuts from "./common/hooks/UseDevShortcuts";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./App.scss";
import { routeConstants } from "./features/routes/routeConstants";
import { DebugPanel } from "./common/ui/pages/debugging/DebugPanel";
import { productRouteConstants } from "./apps/productManagement/productRoutes";
import { localeApiService } from "./common/services/api/LocalesApiService";
import { useCommonStore } from "./common/domain/state/stores/useCommonStore";
import { attributesRouteConstants } from "./apps/attributesManagement/attributeRoutes";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { themeColors } from "./common/ui/styles/themeColors";
import { themeConstants } from "./common/ui/styles/themeConstants";

type MenuItem = Required<MenuProps>["items"][number];

const NotificationContext = React.createContext({ name: "AppNotification" });

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem;
}

const menuItems: MenuItem[] = [
  getItem(
    "PIM",
    productRouteConstants.productList,
    <AinIcon icon="PIM" size={24} />
  ),
  // getItem("Brands", routeConstants.brands, <AinIcon icon="brand" size={24} />),
  getItem(
    "Categories",
    routeConstants.categories,
    <AinIcon icon="categories-menu" size={24} />
  ),
  getItem(
    "Attributes",
    attributesRouteConstants.attributes,
    <AinIcon icon="attributes" size={24} />
  )
  // getItem("DAM", routeConstants.dam, <AinIcon icon="DAM" size={24} />),
  // getItem(
  //   "Settings",
  //   routeConstants.setting,
  //   <AinIcon icon="config" size={24} />
  // )
];

const logoutItem = {
  key: "logout",
  className: "menu-item logout",
  icon: <AinIcon icon="logout" size={24} />,
  label: "Logout"
} as MenuItem;

const App: React.FC = () => {
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState<string[] | undefined>();
  const { ui } = useCommonStore();
  const { setLocales } = ui;

  useDevShortcuts();

  const notificationContextValue = useMemo(() => ({ name: "Pim" }), []);

  const authContext = useContext<IAuthContext>(AuthContext);
  const { loginInProgress: isLoading, error: authError } = authContext;
  useEffect(() => {
    if (isLoading === false) {
      (async function () {
        const response = await localeApiService.getLocales();
        setLocales(response.items);
      })();
    }
  }, [isLoading]);

  useEffect(() => {
    if (authError) authContext.login();
  }, [authError]);

  useEffect(() => {
    const menu = menuItems.find(
      (x) => x?.key && location.pathname.toLowerCase().startsWith(`/${x?.key}`)
    );

    setSelectedMenu(menu?.key ? [menu.key.toString()] : undefined);
  }, [location]);

  const handleBottomMenuClicked = (menu: any) => {
    switch (menu.key) {
      case "logout":
        authContext.logOut();
        break;
    }
  };

  if (isLoading) return <Spin size="large" className="loading" />;

  const renderMenuItem = (menuItem: any) => (
    <Menu.Item key={menuItem?.key} className="menu-item">
      <Link to={menuItem?.key}>
        {menuItem.icon}
        <div className="menu-label">{menuItem.label}</div>
      </Link>
    </Menu.Item>
  );

  return (
    <NotificationContext.Provider value={notificationContextValue}>
      <Layout className="app-layout">
        <Layout.Sider width={themeConstants.navigationSidebarWidth}>
          <div className="app-side">
            <div className="app-logo">
              <img src="/ainavio-short-transparent.svg"></img>
            </div>
            <div className="app-nav">
              <Menu theme="dark" selectedKeys={selectedMenu} mode="vertical">
                {menuItems.map(renderMenuItem)}
              </Menu>
            </div>
            <div
              className="app-nav-bottom"
              style={{ borderColor: themeColors.white020 }}
            >
              <Menu
                theme="dark"
                mode="vertical"
                onClick={handleBottomMenuClicked}
                items={[logoutItem]}
              ></Menu>
            </div>
          </div>
        </Layout.Sider>
        <Layout className="content-layout">
          <Outlet />
          <DebugPanel />
        </Layout>
      </Layout>
    </NotificationContext.Provider>
  );
};

export default App;
