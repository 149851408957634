import { useEffect } from "react";
import EditorViewMode from "@pm/ui/atoms/EditorViewMode";
import EditorEditMode from "@pm/ui/atoms/EditorEditMode";
import { Rule } from "antd/es/form";
import RichTextEditor from "@pm/ui/molecules/RichTextEditor";
import BaseEditorProps from "@pm/models/BaseEditorProps";
import getEditerName from "@pm/hooks/getEditerName";
import { requiredButNoValidation } from "./validators/requiredButNoValidation";

// Use when: Input type = Textarea | Multiple values = false

interface Props extends BaseEditorProps {
  value?: string;
  defaultValue?: string;
}

export const SingleTextarea: React.FC<Props> = (props): JSX.Element => {
  const { isEdit, value, isRequired, defaultValue, form, formItemName } = props;
  const translatedName = getEditerName(props);

  useEffect(() => {
    form.setFieldValue(formItemName, value ?? defaultValue);
  }, [value, isEdit]);

  if (!isEdit) {
    return (
      <EditorViewMode>
        <div className="name">{translatedName}</div>
        <div>{value ? <RichTextEditor readOnly value={value} /> : "--"}</div>
      </EditorViewMode>
    );
  }

  const rules: Rule[] = [];
  if (isRequired) {
    rules.push(requiredButNoValidation());
  }

  return (
    <EditorEditMode
      name={formItemName}
      label={translatedName}
      initialValue={defaultValue}
      labelCol={{ span: 24 }}
      rules={rules}
    >
      <RichTextEditor />
    </EditorEditMode>
  );
};

export default SingleTextarea;
