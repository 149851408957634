import { withImmer } from "jotai-immer";
import { attributesAtom } from "./attributeAtoms";
import { useAtom, useSetAtom } from "jotai";
import { useParams } from "react-router-dom";
import { Draft, produce } from "immer";
import { useState, useEffect } from "react";
import { sortObjectFields } from "../../../../common/modules/sortObjectFields";
import { getKeysFromKeyPath } from "../../common/helpers/createTreeKeys";
import { AttributeEntity } from "../../models/entities/attributeEntity";
import { AttributeTemplate } from "../../models/valueObjects/attributeTemplate";

export const attributesAtomWithImmer = withImmer(attributesAtom);

export const useAttributesAtomWithImmer = () =>
  useAtom(attributesAtomWithImmer);

export const useAttributeDetailWithImmer = () => {
  const [attributes, setAttributes] = useAttributesAtomWithImmer();
  const { id: attributeId } = useParams();
  const targetIndex = attributes.findIndex((att) => att?.id === attributeId);
  const target = attributes[targetIndex] as AttributeEntity | undefined;
  const [attribute, setAttribute] = useState(target);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!target) return;
    setAttribute(target);
  }, [target]);

  return attribute;
};

const recursiveUpdate = <T>(
  collection: T,
  updateFn: (draft: Draft<T>) => void
): T =>
  produce(collection, (draft) => {
    const applyUpdate = (item: any) => {
      if (typeof item === "object" && item !== null) {
        for (const key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            item[key] = applyUpdate(item[key]);
          }
        }
        return item;
      }
      return item;
    };

    applyUpdate(draft);
    updateFn(draft);
  });

export const useUpdateAttributeWithImmer = () => {
  const { id } = useParams();
  const setAttributes = useSetAtom(attributesAtomWithImmer);

  const updateAttribute = (
    keyPath: string | undefined,
    updates: Partial<AttributeTemplate> | null,
    /**
     * Difference to the 2nd args `updates`:
     * To do updates in the a nested object, based on the `keyPath`, one would need to first
     *   1. find the target nested object
     *   2. apply the update
     * This visitor helps to do exactly that.
     */
    visistor?: (
      attributeTemplate: AttributeTemplate
    ) => AttributeTemplate | undefined,
    /** When url does not have id, allow to pass in an id */
    givenId = id
  ) => {
    setAttributes((draft) => {
      const targetIndex = draft.findIndex((attr) => {
        return attr.id === givenId;
      });
      const target = draft[targetIndex];
      if (!target) return;

      const isTopLevel = target.id === keyPath;
      if (isTopLevel) {
        const { template, labels, options, ...canUpdateFields } = target;
        const shouldUseVisitor = !!(updates === null && visistor);
        const finalUpdates = shouldUseVisitor ? visistor(target) : updates;
        const result = sortObjectFields({
          ...canUpdateFields,
          template,
          labels,
          options,
          ...finalUpdates
        });
        draft[targetIndex] = result;
      }

      const [_, ...otherKeys] = getKeysFromKeyPath(keyPath);

      recursiveUpdate(draft, () => {
        const findAndModify = (
          nested: AttributeTemplate[] | null,
          keys: string[]
        ) => {
          if (!nested) return;
          const [key, ...remainingKeys] = keys;

          nested.forEach((item, index) => {
            if (key === item.id) {
              const { template, labels, options, ...canUpdateFields } = item;
              const shouldUseVisitor = !!(updates == null && visistor);
              const finalUpdates = shouldUseVisitor ? visistor(item) : updates;
              if (key !== finalUpdates?.id) return;

              const result = sortObjectFields({
                ...canUpdateFields,
                template,
                labels,
                options,
                ...finalUpdates
              });
              nested[index] = result;
            }
            findAndModify(item.template, remainingKeys);
          });
        };

        findAndModify(target.template, otherKeys);
      });
    });
  };

  return updateAttribute;
};
