import "./searchCategoryForm.scss";
import { Button, Form } from "antd";
import Search from "antd/es/input/Search";
import { useCategoryPageState } from "../../../domain/hooks/useCategoryPageState";
import { t, tMap } from "../../../../../features/i18n/translation";

interface Props {}

export const SearchCategoryForm: React.FC<Props> = (): JSX.Element => {
  const [state, setState] = useCategoryPageState();
  const [form] = Form.useForm();

  const handleSearchFinish = (values: { search: string }) => {
    setState({ searchKeyword: values.search });
  };

  const handleResetSearch = () => {
    form.resetFields();
    setState({ searchKeyword: undefined });
  };

  return (
    <div className="search-category-form">
      <Form
        form={form}
        name="filter-attribute"
        autoComplete="off"
        layout="inline"
        onFinish={handleSearchFinish}
      >
        <span className="filter-text">
          {t(tMap["category.filter.searchFilter"])}
        </span>
        <Form.Item name="search" className="search">
          <Search
            placeholder={t(tMap["category.filter.searchPlaceholder"])}
            className="filter-input"
          />
        </Form.Item>
        <Button type="link" className="button" onClick={handleResetSearch}>
          {t(tMap["category.filter.reset"])}
        </Button>
        <Button className="apply" htmlType="submit">
          {t(tMap["category.filter.apply"])}
        </Button>
      </Form>
    </div>
  );
};

export default SearchCategoryForm;
