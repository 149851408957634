import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { styled } from "styled-components";
import { useLocaleOptions } from "../../../hooks/useLocaleOptions";

const StyledSelect = styled(Select)`
  &.ant-select.ant-select-in-form-item {
    min-width: 185px;
  }
`;

export const LocaleSelector: React.FC<{
  onChange?: (option: DefaultOptionType) => void;
}> = ({ onChange, ...props }): JSX.Element => {
  const { localeOptions, selectedLocale } = useLocaleOptions();

  if (!selectedLocale) return <></>;

  return (
    <StyledSelect
      data-test={dMap["select-locale"]}
      className="locale-selector"
      placeholder={"locale"}
      options={localeOptions}
      defaultValue={selectedLocale}
      onSelect={(_, newLocale) => onChange && onChange(newLocale as any)}
      {...props}
    />
  );
};
