import "./categorySettings.scss";
import { useCategoryPageState } from "../../../../domain/hooks/useCategoryPageState";
import { Collapse, Form, Tag } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { commonConstants } from "../../../../../../common/constants/commonConstants";
import { AinIcon } from "../../../../../../common/ui/atoms/AinIcon";
import { TemplateSetting } from "../../../../domain/valueObjects/templateSetting";
import { SettingInputType } from "../../../../domain/valueObjects/settingInputType";
import SelectionInputSetting from "../../inputTypeSettings/selectionInputSetting/SelectionInputSetting";
import ToggleInputSetting from "../../inputTypeSettings/toggleInputSetting/ToggleInputSetting";
import AddSettingButton from "../addSettingButton/AddSettingButton";
import TranslateSettingButton from "../translateSettingButton/TranslateSettingButton";
import { Labels } from "../../../../../../common/domain/valueObjects/Label";
import OptionValuesModal from "../../modals/optionValuesModal/OptionValuesModal";
import { useEffect, useRef } from "react";
import { SelectionType } from "@cm/domain/valueObjects/selectionType";
import { t, tMap } from "../../../../../../features/i18n/translation";
import deepMergeOverwriteArray from "../../../../../../common/modules/deepMergeOverwriteArray";
import { SelectionLook } from "../../../../../../common/domain/valueObjects/SelectionLook";
import { CategoryAttribute } from "@cm/domain/valueObjects/categoryAttribute";
import { showNotifications } from "../../../../../../common/modules/showNotifications";

interface Props {}

export const CategorySettings: React.FC<Props> = (): JSX.Element => {
  const [state, setState] = useCategoryPageState();
  const optionValuesModal = useRef<any>();
  const [form] = Form.useForm();
  const newSettingNames = useRef<string[]>([]);

  const resolveSettingInput = (setting: TemplateSetting) => {
    switch (setting.inputType) {
      case SettingInputType.Toggle:
        return <ToggleInputSetting setting={setting} form={form} />;

      case SettingInputType.Selection:
        return <SelectionInputSetting setting={setting} form={form} />;

      default:
        return <></>;
    }
  };

  useEffect(() => {
    const objectData = state.currentCategory?.template.settings.reduce(
      (obj: any, item) => {
        obj[item.name] = item;
        return obj;
      },
      {}
    );

    form.setFieldsValue(objectData);
  }, [state.currentCategory]);

  const updateSettingsInSate = (
    settings: TemplateSetting[],
    attributes?: CategoryAttribute[]
  ) => {
    const tempTemplates = state.updatedCategory!.templates.map((template) => {
      if (template.categoryId !== state.currentCategory?.id) {
        return template;
      }

      return {
        ...template,
        attributes: attributes ?? template.attributes,
        settings
      };
    });

    const tempCategory = {
      ...state.updatedCategory,
      template: {
        ...state.updatedCategory!.template,
        attributes: attributes ?? state.updatedCategory!.template.attributes,
        settings: settings
      },
      templates: tempTemplates
    };

    setState({
      currentCategory: { ...tempCategory },
      updatedCategory: { ...tempCategory }
    });
  };

  const handleRemoveSetting = (setting: TemplateSetting) => {
    const settings = state.currentCategory!.template.settings.filter(
      (x) => x.name !== setting.name
    );

    updateSettingsInSate(settings);
  };

  const handleAddNewSetting = (setting: any) => {
    const isExistedSettingName = state.currentCategory!.template.settings.some(
      (x) => x.name === setting.name
    );
    if (isExistedSettingName) {
      showNotifications(
        t(tMap["category.settings.errorAddDuplicate"]),
        "error"
      );
      return;
    }

    let newSetting: TemplateSetting;
    switch (setting.inputType) {
      case SettingInputType.Toggle:
        newSetting = {
          inputType: setting.inputType,
          name: setting.name,
          default: false,
          dataType: "boolean"
        };
        break;

      case SettingInputType.Selection:
        newSetting = {
          inputType: setting.inputType,
          name: setting.name,
          default: null,
          dataType: "text",
          isMultipleValues: false,
          isUnique: false,
          dataSettings: {},
          inputSettings: {
            searchable: false,
            selectionType: SelectionType.Single,
            selectionLook: SelectionLook.Dropdown
          }
        };
        break;

      default:
        throw new Error(
          `Unsupported add setting with input type = ${setting.inputType}`
        );
    }

    newSettingNames.current.push(newSetting.name);
    updateSettingsInSate([
      ...state.currentCategory!.template.settings,
      newSetting
    ]);
  };

  const handleTranslationConfirmed = (
    setting: TemplateSetting,
    labels: Labels[]
  ) => {
    const settings = state.currentCategory!.template.settings.map((x) => {
      if (x.name !== setting.name) {
        return x;
      }

      return {
        ...x,
        labels
      };
    });

    updateSettingsInSate(settings);
  };

  const handleOptionValuesSaved = (setting: TemplateSetting) => {
    const settings = [...state.updatedCategory!.template.settings];
    const index = settings.findIndex((x) => x.name === setting.name);
    settings.splice(index, 1, {
      ...settings[index],
      options: setting.options
    });

    updateSettingsInSate(settings);
  };

  const handleValuesChanged = (values: any) => {
    const settingName = Object.keys(values)[0];
    const settings = [...state.updatedCategory!.template.settings];
    const index = settings.findIndex((x) => x.name === settingName);
    const newSetting = deepMergeOverwriteArray(
      settings[index],
      values[settingName]
    );
    settings.splice(index, 1, newSetting);

    let attributes: CategoryAttribute[] | undefined;
    const isANewSetting = newSettingNames.current.includes(settingName);
    const isChangingDefaultValue =
      Object.keys(values[settingName])[0] === "default";
    if (isANewSetting && isChangingDefaultValue) {
      attributes = state.updatedCategory!.template.attributes.map(
        (attribute) => {
          const isUnsetSetting = attribute.settings[settingName] !== undefined;
          if (isUnsetSetting) {
            return attribute;
          }

          return {
            ...attribute,
            settings: {
              ...attribute.settings,
              [settingName]: values[settingName].default
            }
          };
        }
      );
    }

    updateSettingsInSate(settings, attributes);
  };

  const renderOptionValuesIcon = (setting: TemplateSetting) => {
    if (setting.inputType !== SettingInputType.Selection) {
      return null;
    }

    return (
      <AinIcon
        className="table-icon"
        icon="table-outlined"
        size={20}
        onClick={() => {
          optionValuesModal.current?.openWithData(setting);
        }}
      />
    );
  };

  const collapseItems = state.currentCategory?.template.settings.map(
    (setting) => ({
      key: setting.name,
      label: (
        <div>
          <span className="setting-name">
            {t(tMap["category.settings.settingName"])}
          </span>
          {setting.labels?.find(
            (x) => x.locale === commonConstants.defaultLocale
          )?.value ?? setting.name}

          <span className="actions" onClick={(e) => e.stopPropagation()}>
            <Tag>{setting.dataType}</Tag>
            {renderOptionValuesIcon(setting)}
            <TranslateSettingButton
              setting={setting}
              onTranslationConfirmed={handleTranslationConfirmed}
            />
            <AinIcon
              className="delete-icon"
              icon="delete-outlined-gray"
              size={18}
              onClick={() => handleRemoveSetting(setting)}
            />
          </span>
        </div>
      ),
      children: resolveSettingInput(setting)
    })
  );

  return (
    <div className="category-settings">
      <div className="top-bar">
        <span>
          <span className="count">
            {`${state.currentCategory?.template.settings.length} ${t(
              tMap["category.common.settings"]
            )}`}
          </span>
          <AddSettingButton onAddNewSetting={handleAddNewSetting} />
        </span>
      </div>
      <Form
        name="category-settings"
        form={form}
        onValuesChange={handleValuesChanged}
      >
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          items={collapseItems}
        />
      </Form>
      <OptionValuesModal
        ref={optionValuesModal}
        onSaved={handleOptionValuesSaved}
      />
    </div>
  );
};

export default CategorySettings;
