import type { ColProps, RowProps } from "antd";
import { Col, Row } from "antd";
import React, { CSSProperties, Children, ReactNode } from "react";

export interface IGridProps extends RowProps {
  children: ReactNode[];
  colSpans: number[];
  allowFirstLevelChildren?: string[];
  colCount?: number;
  colStyles?: CSSProperties;
  colGap?: number;
  header?: ReactNode;
}

const AinGrid: React.FC<IGridProps> = ({
  children,
  allowFirstLevelChildren,
  colSpans,
  colStyles,
  colGap,
  header,
}) => {
  const modifiedChildren = Children.map(children, (row, rowIndex) => {
    if (!React.isValidElement(row)) return row;

    // Defaults
    if (allowFirstLevelChildren === undefined) {
      allowFirstLevelChildren = [];
    }
    allowFirstLevelChildren.push("Row");

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore row not properly typed
    const rowName = row.type.displayName ?? row.type;
    if (!allowFirstLevelChildren.includes(rowName)) {
      // console.error(
      //   `[Not supported] First level child must be <Row>. Instead, got ${rowName}. Element at (row: ${rowIndex})`,
      // );
    }

    if (!row.props.children) {
      return row;
    }

    if (row.props.children.length > colSpans.length) {
      // console.error(
      //   `[Not recommended] The <Row> at index ${rowIndex} has more <Col>'s than your \`colSpans\` array. `,
      // );
    }

    const modifiedCols = Children.map(row.props.children, (col, colIndex) => {
      if (!React.isValidElement(col)) return col;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore row not properly typed
      let colName = col.type.displayName ?? col.type;
      if (colName !== "Col") {
        if (colName === undefined) {
          // to get Tag name from antd render function

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore row not properly typed
          const funcBody = col.type.render.toString();
          const regex = /warning_default2\([^,]+,\s*"([^"]+)"/;
          const result = regex.exec(funcBody);
          if (result) {
            [, colName] = result;
          }
        }
        // console.error(
        //   `[Not supported] Row child must be <Col>. Instead, got ${colName}. Element at (row: ${rowIndex} col: ${colIndex})`,
        // );
      }

      const colChild = col as React.ReactElement<ColProps>;

      /** if Col already has a `span` prop, then keep it */
      if (colChild?.props?.span) return col;

      const span = colSpans[colIndex];
      const modifiedProps = {
        // span,
        ...colChild.props,
      };

      if (colStyles || colGap) {
        const style = { ...colStyles };
        /** Simulate col gap by just ignoring first marginLeft */
        if (colIndex !== 0) {
          style.marginLeft = colGap;
        }
        modifiedProps.style = { ...style, ...modifiedProps.style };
      }

      return (
        <Col span={span}>
          <Col {...modifiedProps} />
        </Col>
      );
    });
    return <Row {...row.props}>{modifiedCols}</Row>;
  });

  return (
    <>
      {header}
      {modifiedChildren}
    </>
  );
};

export default AinGrid;
