import { AttributeInputTypeEnum } from "@am/models/enums/attributeInputTypeEnum";
import { AttributeTemplate } from "@am/models/valueObjects/attributeTemplate";

/**
 * Check if the template is multiple values.
 * Some inputType behave differently, so exclude them
 */
export function isMultipleValuesTemplate(
  template: Pick<AttributeTemplate, "isMultipleValues" | "inputType"> | null
): boolean {
  if (!template) return false;

  const { inputType, isMultipleValues } = template;
  const deniedFor =
    inputType === AttributeInputTypeEnum.uploader ||
    inputType === AttributeInputTypeEnum.toggle ||
    inputType === AttributeInputTypeEnum.selection;

  const is = isMultipleValues && !deniedFor;
  return is;
}
