import { CategoryListResponse } from "@cm/domain/valueObjects/categoryListResponse";
import { useUIState } from "@pm/hooks/useUIState";
import { Modal } from "antd";
import { useEffect, useMemo, useState } from "react";
import { tMap } from "../../../../../features/i18n/translation";
import { DataNode } from "antd/es/tree";
import { styled } from "styled-components";
import { categoryApiService } from "@cm/common/services/categoryApiService";
import {
  getAllAttributeNamesSorted,
  getAttributeSettingsMerged,
  useLoadAttributes
} from "@pm/hooks/useLoadAttributes";
import { useAtom } from "jotai";
import { productEntityStateAtom } from "@pm/state/productManagementEntityState";
import { useLocaleOptions } from "../../../../../common/hooks/useLocaleOptions";
import getLabel from "@pm/helpers/getLabel";
import { AinIcon } from "../../../../../common/ui/atoms/AinIcon";
import { themeColors } from "../../../../../common/ui/styles/themeColors";
import CategoryTreeSelect from "../../../../../common/ui/molecules/CategoryTreeSelect";

export interface EditProductCategoryModalProps {
  isOpen: boolean;
  categoryIds: string[];
  updateCategory: (categories: string[]) => void;
  onClose: () => void;
}

interface AttributeChange {
  attributeName: string;
  attributeLabel: string;
  oldCategoryId: string;
  oldCategoryLabel: string;
  newCategoryId: string;
  newCategoryLabel: string;
}

const ModalContent = styled.div`
  .categories-label {
    padding-bottom: 8px;

    &:before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
`;

const OverwriteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  .attribute-name {
    flex: 1;
    white-space: wrap;
    max-width: 250px;
  }
  .link {
    max-width: 200px;
    margin: 0 10px;
    color: ${themeColors.colorPrimary};
  }
`;

const FixedHeight = styled.div`
  max-height: 200px;
  overflow: auto;
`;

const convertToTree = (
  categories: CategoryListResponse[],
  parentId?: string
): DataNode[] => {
  const filteredCategories = categories.filter(
    (category) => category.parentId === parentId
  );

  return filteredCategories.map((category) => ({
    key: category.id,
    value: category.id,
    title: category.name,
    children: convertToTree(categories, category.id)
  }));
};

export default function EditProductCategoryModal(
  props: EditProductCategoryModalProps
) {
  const { isOpen, categoryIds, updateCategory, onClose } = props;

  const {
    uiState: { allCategories },
    loadAllCategories,
    setIsFormUpdated
  } = useUIState();

  useEffect(() => {
    if (isOpen) loadAllCategories();
  }, [isOpen, loadAllCategories]);

  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  useEffect(() => setSelectedCategoryIds(categoryIds), [categoryIds]);

  const { selectedLocale } = useLocaleOptions();
  const [working, setWorking] = useState(false);
  const [productLoader] = useAtom(productEntityStateAtom);
  const { attributesMap, attributeSettingMap } = useLoadAttributes(
    productLoader.data
  );

  const [overwriteConfirmed, setConfirmOverwrite] = useState(false);
  const [overwriteAttributes, setOverwriteAttributes] = useState<
    AttributeChange[]
  >([]);
  const [attributeRemoveConfirmed, setConfirmAttributeRemoval] =
    useState(false);
  const [attributeToRemove, setRemovingAttributes] = useState<string[]>([]);
  const removingAttributes = useMemo(() => {
    return attributeToRemove.map((x) => attributesMap[x]);
  }, [attributeToRemove, attributesMap]);

  const getCategoryLabel = (categoryId: string) => {
    const cat = allCategories?.find((x) => x.id === categoryId);
    return cat?.appearanceName || cat?.name || "<no label>";
  };

  const handleOk = async () => {
    setWorking(true);
    try {
      const fetchedCats = await Promise.all(
        selectedCategoryIds.map((c) => categoryApiService.getCategory(c))
      );

      // determine removed attributes
      const newAttributes = getAllAttributeNamesSorted(fetchedCats);
      const removedAttributes = Object.keys(attributesMap).filter((k) =>
        newAttributes.every((nk) => nk !== k)
      );
      if (removedAttributes && removedAttributes.length > 0) {
        setRemovingAttributes(removedAttributes);
        setConfirmAttributeRemoval(false);
      } else {
        setConfirmAttributeRemoval(true);
      }

      // determine moving category of attribute
      const newAttributeSettings = getAttributeSettingsMerged(fetchedCats);
      const changingSettings: AttributeChange[] = [];
      Object.keys(newAttributeSettings).forEach((a) => {
        if (!attributeSettingMap[a]) return;
        if (
          newAttributeSettings[a].categoryId !==
          attributeSettingMap[a].categoryId
        ) {
          changingSettings.push({
            attributeName: a,
            attributeLabel: getLabel(
              attributesMap[a]?.labels,
              selectedLocale,
              a
            ),
            newCategoryId: newAttributeSettings[a].categoryId,
            newCategoryLabel: getCategoryLabel(
              newAttributeSettings[a].categoryId
            ),
            oldCategoryId: attributeSettingMap[a].categoryId,
            oldCategoryLabel: getCategoryLabel(
              attributeSettingMap[a].categoryId
            )
          });
        }
      });

      if (changingSettings.length > 0) {
        setOverwriteAttributes(changingSettings);
        setConfirmOverwrite(false);
      } else {
        setConfirmOverwrite(true);
      }
    } finally {
      setWorking(false);
    }
  };

  // final submission, trigger automatically
  useEffect(() => {
    if (overwriteConfirmed && attributeRemoveConfirmed) {
      updateCategory(selectedCategoryIds);
      onClose();
      setIsFormUpdated(true);
    }
  }, [
    overwriteConfirmed,
    attributeRemoveConfirmed,
    updateCategory,
    onClose,
    selectedCategoryIds
  ]);

  useEffect(() => {
    if (!isOpen) {
      setConfirmOverwrite(false);
      setConfirmAttributeRemoval(false);
      setOverwriteAttributes([]);
      setRemovingAttributes([]);
    }
  }, [isOpen]);

  return (
    <Modal
      title={tMap["product.edit.categories"]}
      okText={tMap["common.continue"]}
      cancelText={tMap["common.cancel"]}
      open={props.isOpen}
      onCancel={onClose}
      onOk={handleOk}
      confirmLoading={working}
      okButtonProps={{ disabled: selectedCategoryIds.length === 0 }}
    >
      <ModalContent>
        <div className="categories-label">{tMap["common.categories"]}</div>
        <CategoryTreeSelect
          allCategories={allCategories}
          onChange={setSelectedCategoryIds}
          categoryIds={selectedCategoryIds}
          isMultiple
        />
      </ModalContent>

      <Modal
        title={tMap["product.edit.categorySettingOverwrite"]}
        open={overwriteAttributes.length > 0 && !overwriteConfirmed}
        onOk={() => setConfirmOverwrite(true)}
        onCancel={() => setOverwriteAttributes([])}
      >
        These following attributes will use settings from new categories. Please
        be aware of this change.
        {overwriteAttributes.map((o) => (
          <OverwriteContainer key={o.attributeName}>
            <b className="attribute-name">{o.attributeLabel}</b>
            <a
              className="link"
              href={`/categories/${o.oldCategoryId}`}
              target="_blank"
            >
              {o.oldCategoryLabel}
            </a>
            <AinIcon icon="arrow-left" size={15} rotation={180}></AinIcon>
            <a
              className="link"
              href={`/categories/${o.newCategoryId}`}
              target="_blank"
            >
              {o.newCategoryLabel}
            </a>
          </OverwriteContainer>
        ))}
      </Modal>

      <Modal
        title={tMap["product.edit.categoryRemoveConfirm"]}
        open={
          removingAttributes.length > 0 &&
          !attributeRemoveConfirmed &&
          overwriteConfirmed
        }
        onOk={() => setConfirmAttributeRemoval(true)}
        onCancel={() => setRemovingAttributes([])}
      >
        <p>{tMap["product.edit.categoryRemoveAttributeNotice"]}</p>
        <FixedHeight>
          {removingAttributes.map((a) => (
            <div key={a.name}>{getLabel(a.labels, selectedLocale, a.name)}</div>
          ))}
        </FixedHeight>
      </Modal>
    </Modal>
  );
}
