import { Input, Tag } from "antd";
import { FormColumn } from "./formColumnType";
import { t, tMap } from "../../../../../../features/i18n/translation";
import { booleanToText } from "../../../../../../common/ui/molecules/selection/booleanToText";
import { settingsFields } from "../../../../common/businessLogic/settingsFields";
import {
  masterAttributeUiMapping,
  inputTypesUiMetadata,
  dataTypesUiMetadata
} from "../../../../common/helpers/attributeUiMappings";
import {
  AttributeEntity,
  isAttributeEntity
} from "../../../../models/entities/attributeEntity";
import {
  booleanSelectWithText,
  booleanSelectRender,
  booleanSelectWithCustomLabels
} from "./inputTypeRenders/booleanSelectRender";
import { AttributeTemplate } from "../../../../models/valueObjects/attributeTemplate";
import { getAttributeType } from "@am/common/businessLogic/getAttributeType";

export const attributeTypeCol = (
  attributeEntity: AttributeTemplate | AttributeEntity
): FormColumn => ({
  key: "isMasterAttribute",
  label: settingsFields().isMasterAttribute,
  span: 12,
  value: isAttributeEntity(attributeEntity) ? (
    <Tag>
      {masterAttributeUiMapping()[getAttributeType(attributeEntity)]?.label}
    </Tag>
  ) : (
    t(tMap["common.na"])
  ),
  render: (value) => <>{value}</>
});

export const requiredCol = (
  attributeEntity: AttributeTemplate | AttributeEntity
): FormColumn => ({
  key: "isNullable",
  label: settingsFields().isNullable,
  span: 24,
  value: booleanToText(!attributeEntity.isNullable),
  ...booleanSelectWithCustomLabels(t(tMap["common.no"]), t(tMap["common.yes"]))
});

export const valueByLocalesRow = (
  attributeEntity: AttributeTemplate | AttributeEntity
): FormColumn => ({
  key: "isLocaleDependent",
  label: settingsFields().isLocaleDependent,
  span: 12,
  value: booleanToText(attributeEntity.isLocaleDependent),
  ...booleanSelectRender
});
export const valueByChannelsRow = (
  attributeEntity: AttributeTemplate | AttributeEntity
): FormColumn => ({
  key: "isChannelDependent",
  label: settingsFields().isChannelDependent,
  span: 6,
  value: booleanToText(attributeEntity.isChannelDependent),
  ...booleanSelectRender
});
export const inputTypeRow = (
  attributeEntity: AttributeTemplate | AttributeEntity
): FormColumn => ({
  key: "inputType",
  label: settingsFields().inputType,
  value: inputTypesUiMetadata()[attributeEntity.inputType]?.label,
  span: 8,
  isReadOnly: true,
  render: (value) => <Tag>{value}</Tag>
});
export const dataTypeRow = (
  attributeEntity: AttributeTemplate | AttributeEntity
): FormColumn => ({
  key: "dataType",
  label: settingsFields().dataType,
  value: dataTypesUiMetadata()[attributeEntity.dataType]?.label,
  span: 12,
  hide: !!attributeEntity.dataType,
  isReadOnly: true,
  render: (value) => <Tag>{value}</Tag>
});
export const descriptionRow = (
  attributeEntity: AttributeTemplate | AttributeEntity
): FormColumn => ({
  key: "description",
  label: settingsFields().description,
  value: attributeEntity.description,
  span: 24,
  render: (value) => (
    <Input.TextArea
      className="description"
      value={value?.toString()}
      placeholder={t(tMap["common.input"])}
      rows={1}
      cols={70}
    />
  )
});
export const isUniqueCol = (
  attributeEntity: AttributeTemplate | AttributeEntity
): FormColumn => ({
  key: "isUnique",
  label: settingsFields().isUnique,
  value: booleanToText(attributeEntity.isUnique),
  span: 12,
  ...booleanSelectRender
});
export const multipleValuesRow = (
  attributeEntity: AttributeTemplate | AttributeEntity
): FormColumn => ({
  key: "isMultipleValues",
  label: settingsFields().isMultipleValues,
  value: booleanToText(attributeEntity.isMultipleValues),
  span: 6,
  ...booleanSelectRender
});
export const toggleDefaultRow = (
  attributeEntity: AttributeTemplate | AttributeEntity
): FormColumn => ({
  key: "default",
  label: settingsFields().default,
  value: attributeEntity.default ? t(tMap["common.on"]) : t(tMap["common.off"]),
  ...booleanSelectWithCustomLabels(t(tMap["common.on"]), t(tMap["common.off"]))
});
