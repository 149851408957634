import React, { useEffect, useMemo, useState } from "react";
import { Table, TablePaginationConfig, TableProps, Tag } from "antd";
import ProductTableProductCell from "./ProductTableProductCell";
import { ProductEntity } from "@pm/models/ProductEntity";
import { styled } from "styled-components";
import { TableDataType } from "@pm/types/TableDataType";
import {
  backgrounds,
  borders
} from "../../../../../../common/ui/styles/themeColors";
import { useUIStateReadOnly } from "@pm/hooks/useUIState";
import { t, tMap } from "../../../../../../features/i18n/translation";
import { attributeStatusUiMapping } from "@am/common/helpers/attributeUiMappings";
import { AttributeStatusEnum } from "@am/models/enums/attributeStatus";
import { getDate } from "../../../../../../common/modules/dateFormat";
import pagination from "antd/es/pagination";
import { SorterResult } from "antd/es/table/interface";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { productRouteConstants } from "@pm/productRoutes";
import { useAtomState } from "../../../../../../common/hooks/useAtomState";
import {
  ProductListState,
  productListStateAtom
} from "@pm/state/productListState";
import { productManagementListState } from "@pm/state/productManagementListState";
import { productsApiService } from "@pm/api/ProductsApiService";
import { Labels } from "../../../../../../common/domain/valueObjects/Label";

export interface IProductsTableProps {
  dataSource: ProductEntity[];
}

/** Else the table has horizontal scrollbars */
const microAdjustTableWith = "4px";

const StyledProductsTable = styled(Table)`
  height: 100%;
  &.ant-table-wrapper {
    .ant-spin-nested-loading {
      height: 100%;
      .ant-spin-container {
        height: 100%;
      }
    }
    .ant-table-tbody > tr > td {
      padding: 0 16px;
      height: 56px;
    }
    tr.ant-table-expanded-row > td {
      background: ${backgrounds.colorBgLayout};
      .ant-table-wrapper {
        margin-left: 1px;
      }
      .ant-table-container,
      .ant-table {
        border-radius: 0;
        margin-right: -0.5px;
      }
      .ant-table {
        border-top: 1px solid ${borders.colorBorderSecondary};
        border-bottom: 1px solid ${borders.colorBorderSecondary};
      }
    }

    table {
      min-width: calc(100% - ${microAdjustTableWith}) !important;
    }

    > div > div > .ant-table {
      height: calc(100vh - 230px);
      overflow-y: auto;
    }

    .ant-spin-nested-loading > div > .ant-spin {
      inset-inline-start: 32px;
    }

    // copy and overwrite antd style
    .ant-table-tbody
      > tr
      > td
      > .ant-table-expanded-row-fixed
      > .ant-table-wrapper:only-child
      .ant-table {
      margin-inline-start: 50px;
      margin-block: 0;
    }

    .ant-table-cell {
      cursor: pointer;
    }

    .ant-pagination {
      position: absolute;
      bottom: 0;
      height: 30px;
      margin-left: 16px;
    }

    .ant-pagination-simple > li:not(.ant-pagination-options) {
      margin-top: 4px;
    }
  }
`;

const ProductsTableColumns = (): TableProps<TableDataType>["columns"] => {
  const productColumns: TableProps<TableDataType>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 300,
      sorter: {
        multiple: 1,
        compare: (a, b) => (a.name as string).localeCompare(b.name as string)
      },
      render: (value, record, index) => (
        <ProductTableProductCell record={record} />
      )
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 136,
      render: (value) => <>{value}</>
    },
    {
      title: "Categories",
      dataIndex: "category",
      key: "category",
      width: 250,
      render: (_, record) => (
        <span style={{ fontWeight: 500 }}>
          {record.categories.map((cat) => cat.name).join(", ")}
        </span>
      )
    },
    {
      title: t(tMap["common.status"]),
      dataIndex: "status",
      key: "status",
      width: 120,
      filters: [
        ...Object.entries(attributeStatusUiMapping()).map(
          ([status, label]) => ({
            text: <Tag color={label?.color}>{label?.label}</Tag>,
            value: status
          })
        )
      ],
      onFilter: (value, record) => record.status === value,
      render: (value: AttributeStatusEnum) => (
        <Tag color={attributeStatusUiMapping()[value]?.color}>
          {attributeStatusUiMapping()[value]?.label}
        </Tag>
      )
    },
    {
      title: t(tMap["common.lastUpdated"]),
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 168,
      sorter: {
        multiple: 1
      },
      render: (value: AttributeStatusEnum) => <>{getDate(value)}</>
    }
  ];

  return productColumns;
};

const ProductsTable: React.FC<IProductsTableProps> = ({
  dataSource,
  ...props
}) => {
  const navigate = useNavigate();
  const [productsLoader] = useAtom(productManagementListState);
  const { isLoading, data } = productsLoader;
  const { isSidebarOpen } = useUIStateReadOnly();
  const { selectedLocale } =
    useAtomState<ProductListState>(productListStateAtom);

  const { tablePage, setState } =
    useAtomState<ProductListState>(productListStateAtom);
  const [dataSourceWithNesting, setDataSourceWithNesting] = useState<
    TableDataType[]
  >([]);

  const columns: TableProps<TableDataType>["columns"] = useMemo(
    () => ProductsTableColumns(),
    []
  );

  useEffect(() => {
    setDataSourceWithNesting(dataSource.map((x) => ({ ...x, key: x.id })));
  }, [dataSource]);

  const expandedRowRender = (mainProduct: TableDataType) => (
    <Table
      onRow={(record) => ({
        onClick: (event: any) => {
          const selections = window.getSelection();
          if (selections?.type === "Range") return;
          if (!record.mainProductId) return;
          if (
            event.target.className.includes("ant-table-row-expand-icon-cell")
          ) {
            return;
          }

          navigate(
            `/${productRouteConstants.productList}/${record.mainProductId}?view=variants`
          );
        }
      })}
      columns={columns}
      loading={!mainProduct.variants}
      dataSource={mainProduct.variants}
      pagination={false}
      showHeader={false}
      bordered
      showSorterTooltip={false}
      scroll={{ x: 1372 }}
    />
  );

  const handleRowClicked = async (event: any, record: TableDataType) => {
    if (event.target.className.includes("ant-table-row-expand-icon-cell")) {
      return;
    }

    navigate(`/${productRouteConstants.productList}/${record.id}`);
  };

  const handleSortColumn: TableProps<any>["onChange"] = (
    _,
    filters,
    sorters
  ) => {
    const multipleSort: SorterResult<TableDataType>[] = [];

    if (!Array.isArray(sorters)) {
      if (sorters.column) {
        multipleSort.push(sorters);
      }
    } else {
      multipleSort.push(...sorters);
    }
    const sorts = multipleSort.map((s) => ({
      field: s.field?.toString(),
      order: s.order
    }));
    setState("tableFilters", filters);
    setState("tableSorts", sorts);
  };

  const handleExpandRow = async (isExpand: boolean, record: TableDataType) => {
    if (!isExpand) {
      return;
    }

    if (record.variants) {
      return;
    }

    const response = await productsApiService.getProductVariants(
      record.id,
      selectedLocale
    );

    setDataSourceWithNesting((data) =>
      data.map((product) => {
        if (product.id !== record.id) {
          return product;
        }

        return {
          ...product,
          variants: response.items.map((x) => ({
            ...x,
            key: x.id,
            mainProductId: product.id,
            name:
              (x.name as Labels[]).find((l) => l.locale === selectedLocale)
                ?.value ?? ""
          }))
        };
      })
    );
  };

  const paginationOptions: TablePaginationConfig = {
    position: ["bottomLeft"],
    showQuickJumper: true,
    showSizeChanger: true,
    simple: window.innerWidth < 1130 && isSidebarOpen,
    current: tablePage,
    total: data?.total,
    showTotal: (total) => `${t(tMap["common.total"])}: ${total}`,
    onChange: (newPage, newPageSize) => {
      setState("tablePage", newPage);
      setState("tablePageSize", newPageSize);
    },
    ...pagination
  };

  return (
    <StyledProductsTable
      className="StyledProductsTable"
      loading={isLoading}
      columns={columns}
      dataSource={dataSourceWithNesting ?? []}
      onChange={handleSortColumn}
      bordered
      showSorterTooltip={false}
      indentSize={16}
      onRow={(record) => ({
        onClick: (e) => {
          const selections = window.getSelection();
          if (selections?.type === "Range") return;
          handleRowClicked(e, record);
        }
      })}
      pagination={{ ...paginationOptions, defaultCurrent: tablePage }}
      scroll={{ x: 1440 }}
      expandable={{
        expandedRowRender,
        onExpand: handleExpandRow,
        rowExpandable: ({ variantCount }) => !!variantCount
      }}
      {...props}
    />
  );
};

export default ProductsTable;
