export enum AttributeDataTypeEnum {
  text = "text",
  email = "email",
  phone = "phone",
  number = "number",
  date = "dateOnly",
  time = "timeOnly",
  dateTime = "dateTime",
  file = "file",
  object = "object",
  boolean = "boolean"
}
