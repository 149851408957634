import { booleanToText } from "../../../../../../common/ui/molecules/selection/booleanToText";
import { settingsFields } from "../../../../common/businessLogic/settingsFields";
import {
  formSelectionTypeUiMapping,
  formLayoutOrientatoinUiMapping
} from "../../../../common/helpers/attributeUiMappings";
import { AttributeEntity } from "../../../../models/entities/attributeEntity";
import { AttributeInputTypeEnum } from "../../../../models/enums/attributeInputTypeEnum";
import { AttributeTemplate } from "../../../../models/valueObjects/attributeTemplate";
import { t, tMap } from "../../../../../../features/i18n/translation";
import { FormColumn } from "./formColumnType";
import {
  attributeTypeCol,
  dataTypeRow,
  descriptionRow,
  inputTypeRow,
  isUniqueCol,
  multipleValuesRow,
  requiredCol,
  toggleDefaultRow,
  valueByChannelsRow,
  valueByLocalesRow
} from "./formColums";
import { booleanSelectRender } from "./inputTypeRenders/booleanSelectRender";
import { formLayoutSelectRender } from "./inputTypeRenders/formLayoutSelectRender";
import { formSelectionTypeRender } from "./inputTypeRenders/formSelectionTypeRender";
import { resolveSimpleTypeLastRows } from "./rowsResolvers/resolveSimpleTypeLastRows";

const builderDataMap = new Map<
  AttributeInputTypeEnum,
  (
    attribute: AttributeTemplate | AttributeEntity,
    isTopLevel: boolean
  ) => FormColumn[][]
>([
  [
    AttributeInputTypeEnum.simple,
    (
      attributeEntity: AttributeTemplate | AttributeEntity,
      isTopLevel: boolean
    ) => [
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity)]
          : [requiredCol(attributeEntity)]),
        ...(isTopLevel ? [valueByLocalesRow(attributeEntity)] : [])
        // ...(isTopLevel ? [valueByChannelsRow(attributeEntity)] : [])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity)] : [])],
      [
        inputTypeRow(attributeEntity),
        { ...multipleValuesRow(attributeEntity), span: 12 }
      ],
      [dataTypeRow(attributeEntity), isUniqueCol(attributeEntity)],
      ...resolveSimpleTypeLastRows(attributeEntity)
    ]
  ],
  [
    AttributeInputTypeEnum.selection,
    (
      attributeEntity: AttributeTemplate | AttributeEntity,
      isTopLevel: boolean
    ) => [
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity)]
          : [requiredCol(attributeEntity)]),
        ...(isTopLevel ? [valueByLocalesRow(attributeEntity)] : [])
        // ...(isTopLevel ? [valueByChannelsRow(attributeEntity)] : [])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity)] : [])],
      [
        {
          ...inputTypeRow(attributeEntity),
          span: 8
        },
        {
          key: ["inputSettings", "searchable"],
          label: settingsFields().searchable,
          value:
            attributeEntity.inputSettings?.searchable == null
              ? t(tMap["common.na"])
              : booleanToText(
                  attributeEntity.inputSettings?.searchable ?? false
                ),
          span: 8,
          ...booleanSelectRender
        },
        {
          key: ["inputSettings", "selectionType"],
          label: settingsFields().selectionType,
          value:
            formSelectionTypeUiMapping()[
              attributeEntity.inputSettings?.selectionType ?? "single"
            ]?.label,
          span: 8,
          ...formSelectionTypeRender
        }
      ],
      [dataTypeRow(attributeEntity)],
      ...resolveSimpleTypeLastRows(attributeEntity)
    ]
  ],
  [
    AttributeInputTypeEnum.form,
    (
      attributeEntity: AttributeTemplate | AttributeEntity,
      isTopLevel: boolean
    ) => [
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity)]
          : attributeEntity.inputType === AttributeInputTypeEnum.form
          ? []
          : [requiredCol(attributeEntity)]),
        ...(isTopLevel ? [valueByLocalesRow(attributeEntity)] : [])
        // ...(isTopLevel ? [valueByChannelsRow(attributeEntity)] : [])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity)] : [])],
      [
        inputTypeRow(attributeEntity),
        { ...multipleValuesRow(attributeEntity), span: 12 }
        // {
        //   key: ["inputSettings", "layoutOrientation"],
        //   label: settingsFields().childInputLayout,
        //   value:
        //     formLayoutOrientatoinUiMapping()[
        //       attributeEntity.inputSettings?.layoutOrientation ?? "vertical"
        //     ]?.label,
        //   span: 6,
        //   ...formLayoutSelectRender
        // }
      ],
      [dataTypeRow(attributeEntity)]
    ]
  ],
  [
    AttributeInputTypeEnum.textArea,
    (
      attributeEntity: AttributeTemplate | AttributeEntity,
      isTopLevel: boolean
    ) => [
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity)]
          : [requiredCol(attributeEntity)]),
        ...(isTopLevel ? [valueByLocalesRow(attributeEntity)] : [])
        // ...(isTopLevel ? [valueByChannelsRow(attributeEntity)] : [])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity)] : [])],
      [
        inputTypeRow(attributeEntity),
        { ...multipleValuesRow(attributeEntity), span: 12 }
      ],
      [dataTypeRow(attributeEntity)]
      // [
      //   {
      //     key: "minCharacter",
      //     label: settingsFields().minCharacter,
      //     value: null,
      //     span: 12,
      //     hide: !!attributeEntity.dataType,
      //   },
      //   {
      //     key: "maxCharacter",
      //     label: settingsFields().maxCharacter,
      //     value: null,
      //     span: 12,
      //     hide: !!attributeEntity.dataType,
      //   },
      // ],
    ]
  ],
  [
    AttributeInputTypeEnum.toggle,
    (attributeEntity: AttributeTemplate | AttributeEntity, isTopLevel) => [
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity)]
          : [requiredCol(attributeEntity)]),
        ...(isTopLevel ? [valueByLocalesRow(attributeEntity)] : [])
        // ...(isTopLevel ? [valueByChannelsRow(attributeEntity)] : [])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity)] : [])],
      [
        inputTypeRow(attributeEntity)
        // { ...multipleValuesRow(attributeEntity), span: 12 },
      ],
      [dataTypeRow(attributeEntity)],
      [toggleDefaultRow(attributeEntity)]
    ]
  ],
  [
    AttributeInputTypeEnum.uploader,
    (
      attributeEntity: AttributeTemplate | AttributeEntity,
      isTopLevel: boolean
    ) => [
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity)]
          : [requiredCol(attributeEntity)]),
        ...(isTopLevel ? [valueByLocalesRow(attributeEntity)] : [])
        // ...(isTopLevel ? [valueByChannelsRow(attributeEntity)] : [])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity)] : [])],
      [
        inputTypeRow(attributeEntity),
        { ...multipleValuesRow(attributeEntity), span: 12 }
      ],
      [dataTypeRow(attributeEntity)],
      ...resolveSimpleTypeLastRows(attributeEntity)
    ]
  ]
]);

export const getBuilderData = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  isTopLevel: boolean
) => {
  try {
    const mapgetter = builderDataMap.get(attributeEntity.inputType);
    if (!mapgetter) {
      throw new Error(`Unknown input type, got: ${attributeEntity?.inputType}`);
    }
    return mapgetter(attributeEntity, isTopLevel);
  } catch (error) {
    throw new Error(`Unknown input type, got: ${attributeEntity?.inputType}`);
  }
};
