import { Button, Col, Divider, Form, Row, Typography } from "antd";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback
} from "react";
import { get } from "lodash";
import { GetProductResponse, ProductTypeEnum } from "@pm/models/ProductEntity";
import { ProductAttributesMap } from "@pm/types/ProductAttributesMap";
import { SingleText } from "@pm/ui/organisms/dataTypeEditors/SingleText";
import { styled } from "styled-components";
import { debugFlags } from "../../../modules/debugging/debugFlags";
import { NestedFormItem } from "./NestedFormItem";
import { Container } from "./NestedForm.styles";
import { getJsType } from "./getJsType";
import { InputLocaleDependent } from "@pm/ui/organisms/dataTypeEditors/InputLocaleDependent";
import { buildFormStructure, getSettingType } from "./buildFormStructure";
import { CategoryAttribute } from "@cm/domain/valueObjects/categoryAttribute";
import { generateId } from "../../../modules/debugging/generateId";
import { useUIState } from "@pm/hooks/useUIState";
import { useLocaleOptions } from "../../../hooks/useLocaleOptions";
import { t, tMap } from "../../../../features/i18n/translation";
import { updateProductSubscriber } from "@pm/hooks/useUpdateProductPublisher";
import { useSetAtom } from "jotai";

/**
 * Examples
   1. Form+Object
        - Selection+Text
        - Selection+Text (isMultipleValues=true)

    ```ts
    {
      "attributes": {
        travel: [
          {
            location: "",
            material: [""]
          }
        ]
      }
    }
    ```
 */

interface Props {
  type: ProductTypeEnum;
  product: GetProductResponse;
  productAttributesMap: ProductAttributesMap;
  attributeSettingMap: Record<string, CategoryAttribute> | null;
  locale: string | undefined;
  isEdit: boolean;
  handleFinish?: (
    updatedAttributes: Pick<GetProductResponse, "attributes" | "name">,
    isPublishing: boolean
  ) => void;
}

const StyledInput = styled.div`
  margin-bottom: 24px;
`;

/**
 * Else, the order gets mixed up from the antd form.
 */
function keepAttributeOrder(
  updatedFormData: GetProductResponse,
  productAttributesMap: ProductAttributesMap
) {
  const attributes: Record<string, unknown> = {};
  Object.keys(productAttributesMap).forEach((attributeName) => {
    attributes[attributeName] = updatedFormData.attributes[attributeName];
  });
  const result = {
    attributes
  };
  return result;
}

export const NestedForm: React.FC<Props> = ({
  product,
  productAttributesMap,
  attributeSettingMap,
  locale,
  isEdit,
  handleFinish,
  type
}): JSX.Element => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(product);
  const formId = useRef<string>(generateId());
  const {
    uiState: { productVariants },
    setProductVariants,
    setIsFormUpdated
  } = useUIState();
  const { localeOptions } = useLocaleOptions();

  const finalFormData = useMemo(() => {
    const attributeOrderKept = keepAttributeOrder(
      product,
      productAttributesMap
    );
    const updated = {
      ...product,
      attributes: attributeOrderKept.attributes
    };
    return updated as GetProductResponse;
  }, [product, productAttributesMap]);

  /**
   * To be able to add any kind of new item to an array, we need to know the shape of an empty structure.
   * For this, we use `onlyFirstLevel` and build up the structure, regardless of exising data (eg. user has partially filled out the form)
   */
  const emptyProductStructure = useMemo(() => {
    const onlyFirstLevel = Object.keys(productAttributesMap).reduce(
      (acc, key) => ({
        ...acc,
        [key]: null
      }),
      {}
    );
    const emptyProduct = { attributes: onlyFirstLevel };
    const result = buildFormStructure(
      emptyProduct,
      productAttributesMap,
      localeOptions
    );
    return result;
  }, []);

  function addAnything(pathToObject: string[]) {
    const formValues = form.getFieldsValue();
    const dataAtPath = get(formValues, pathToObject);
    const finalEmpty = get(emptyProductStructure, pathToObject);
    /**
     * It is enough to look at the first item, because all elements in the array have the shame shape.
     * And we want to replicate that shape.
     */
    const emptyData = finalEmpty?.[0];
    const { isArray } = getJsType(emptyData);

    if (emptyData == null) {
      dataAtPath.push(null);
    } else if (isArray) {
      dataAtPath.push(emptyData);
    }
    setFormData(formValues);
  }

  async function onFinish(
    updatedFormData: GetProductResponse,
    isPublishing: boolean
  ) {
    if (Object.keys(updatedFormData).length === 0) return;

    const validateResult = await form.validateFields();
    if (validateResult?.errorFields?.length > 0) return;

    const orderKept = keepAttributeOrder(updatedFormData, productAttributesMap);

    const updates = {
      name: updatedFormData.name,
      attributes: orderKept.attributes
    } as GetProductResponse;
    setFormData(updates);
    handleFinish && handleFinish(updates, isPublishing);
  }

  updateProductSubscriber((_, __, isPublishing) => {
    onFinish(form.getFieldsValue(true), isPublishing);
  });

  useEffect(() => {
    setFormData(product);
  }, [product]);

  const handleOnFormBlur = () => {
    if (type !== ProductTypeEnum.variant || !productVariants?.length) {
      return;
    }

    const index = productVariants.findIndex((x) => x.id === product.id);
    const data = form.getFieldsValue();

    productVariants.splice(index, 1, {
      ...productVariants[index],
      name: data.name || productVariants[index].name,
      attributes: data.attributes
    });

    setProductVariants([...productVariants]);
  };

  const handleOnFormChanged = () => {
    setIsFormUpdated(true);
  };

  // useEffect(() => {
  // form.setFieldsValue(formData); // comment this out fixes: text area array not showing "--"
  // }, [formData]);

  return (
    <Container className={isEdit ? "form-edit-mode" : "form-view-mode"}>
      <Form
        name={`product-form-${formId.current}`}
        form={form}
        layout="vertical"
        onValuesChange={handleOnFormChanged}
        onBlur={handleOnFormBlur}
      >
        <StyledInput>
          <InputLocaleDependent
            form={form}
            formItemName={["name"]}
            data={product.name}
            locale={locale}
          >
            <SingleText
              form={form}
              formItemName={[]}
              attributeName={t(tMap["product.details.productName"])}
              isEdit={isEdit}
              isRequired
            />
          </InputLocaleDependent>
        </StyledInput>
        {Object.entries(finalFormData.attributes).map(
          ([attributeName, data], index) => {
            const template = productAttributesMap[attributeName];
            const setting = (attributeSettingMap ?? {})[attributeName];
            const { isObject } = getSettingType(template);

            if (template?.isLocaleDependent && isObject) {
              return (
                <React.Fragment key={index}>
                  <InputLocaleDependent
                    formItemName={["attributes", attributeName]}
                    name={template.labels ?? []}
                    form={form}
                    locale={locale}
                    value={template.labels}
                    data={data}
                  >
                    <NestedFormItem
                      form={form}
                      name={attributeName}
                      parentNames={["attributes"]}
                      locale={locale}
                      data={data}
                      path={["attributes"]}
                      onAdd={addAnything}
                      template={template}
                      setting={setting}
                      isEdit={isEdit}
                    ></NestedFormItem>
                  </InputLocaleDependent>
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={index}>
                <NestedFormItem
                  form={form}
                  name={attributeName}
                  parentNames={["attributes"]}
                  locale={locale}
                  data={data}
                  path={["attributes"]}
                  onAdd={addAnything}
                  template={template}
                  setting={setting}
                  isEdit={isEdit}
                ></NestedFormItem>
              </React.Fragment>
            );
          }
        )}

        <Divider />
        {debugFlags.showDebugPanel ? (
          <>
            <Form.Item>
              <Button htmlType="submit">Finish</Button>
            </Form.Item>
            <Button
              onClick={() => {
                console.log(form.getFieldsValue());
              }}
            >
              Log Form
            </Button>

            <Typography.Title level={4}>
              For dev: Keep until data stable
            </Typography.Title>
            <Row>
              <Row>
                <Col>
                  <Typography.Title level={5}>Original</Typography.Title>
                </Col>
                <Col>
                  <Form.Item noStyle shouldUpdate>
                    {() => (
                      <Typography>
                        <pre>
                          {JSON.stringify(
                            { attributes: formData.attributes },
                            null,
                            2
                          )}
                        </pre>
                      </Typography>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography.Title level={5}>Form</Typography.Title>
                </Col>
                <Form.Item noStyle shouldUpdate>
                  {() => (
                    <Typography>
                      <pre>
                        {JSON.stringify(form.getFieldsValue(), null, 2)}
                      </pre>
                    </Typography>
                  )}
                </Form.Item>
              </Row>
            </Row>
          </>
        ) : null}
      </Form>
    </Container>
  );
};
