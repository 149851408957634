import { Input } from "antd";
import { useEffect } from "react";
import { t, tMap } from "../../../../../features/i18n/translation";
import EditorViewMode from "@pm/ui/atoms/EditorViewMode";
import EditorEditMode from "@pm/ui/atoms/EditorEditMode";
import { Rule } from "antd/es/form";
import BaseEditorProps from "@pm/models/BaseEditorProps";
import getEditerName from "@pm/hooks/getEditerName";
import { requiredButNoValidation } from "./validators/requiredButNoValidation";

// Use when: Input type = Simple field | Data type = text | Multiple values = false

interface Props extends BaseEditorProps {
  value?: string;
  defaultValue?: string;
  pattern?: string;
}

export const SingleText: React.FC<Props> = (props): JSX.Element => {
  const {
    isEdit,
    value,
    isRequired,
    defaultValue,
    pattern,
    form,
    formItemName
  } = props;
  const translatedName = getEditerName(props);

  useEffect(() => {
    form.setFieldValue(formItemName, value ?? defaultValue);
  }, [value, isEdit]);

  if (!isEdit) {
    return (
      <EditorViewMode>
        <div className="name">{translatedName}</div>
        <div>{value || "--"}</div>
      </EditorViewMode>
    );
  }

  const rules: Rule[] = [];
  if (isRequired) {
    rules.push(requiredButNoValidation());
  }
  if (pattern) {
    rules.push({
      pattern: new RegExp("^([^0-9]*)$"),
      message: t(tMap["common.patternInputMessage"])
    });
  }

  return (
    <EditorEditMode
      name={formItemName}
      label={translatedName}
      initialValue={defaultValue}
      labelCol={{ span: 24 }}
      rules={rules}
    >
      <Input placeholder={t(tMap["common.typeHere"])} />
    </EditorEditMode>
  );
};

export default SingleText;
