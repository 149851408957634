import { Form } from "antd";
import styled from "styled-components";

const EditorEditMode = styled(Form.Item)`
  margin-bottom: 16px;

  .ant-form-item-label > label {
    font-size: 14px !important;
    font-weight: 600;
  }
`;

export default EditorEditMode;
