import React, {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useMemo,
  useState
} from "react";
import styled from "styled-components";
import { useCommonStore } from "../../../../../common/domain/state/stores/useCommonStore";
import { Button, Divider, Flex, Select, Table, TableProps } from "antd";
import { useUIStateReadOnly } from "@pm/hooks/useUIState";
import { useProductPublicationState } from "@pm/hooks/usePublicationState";
import { GetChannelResponse } from "../../../../../common/domain/valueObjects/GetChannelResponse";

const Container = styled.div`
  flex: 1;
`;

type Channel = {
  name: string;
  code: string;
};

export const ProductPublicationChannel: React.FC = () => {
  const { isEditMode } = useUIStateReadOnly();

  const { ui } = useCommonStore();
  const { channels } = ui;

  const { publicationState, setChannels } = useProductPublicationState();
  const { channels: productChannels } = publicationState;

  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const channelsSelection = useMemo(() => {
    const activeChannels = productChannels.map<string>((x) => x.code) ?? [];
    return channels
      .filter((x) => !activeChannels.includes(x.code))
      .map((x) => ({
        value: x.code,
        label: x.name
      }));
  }, [productChannels, channels]);

  const handleAddChannel = () => {
    const mappedChannel: GetChannelResponse[] = selectedChannels.flatMap(
      (l) => {
        const channel = channels.find((x) => x.code == l);
        if (channel) return channel;
        return [];
      }
    );
    setChannels([...mappedChannel, ...productChannels]);
    setSelectedChannels([]);
    setShowDropdown(false);
  };

  const handleRemoveChannel = useCallback(
    (code: string) => {
      const ret = productChannels.filter((x) => x.code !== code);
      setChannels(ret);
    },
    [productChannels]
  );

  const optionRenderer = (
    menu: ReactElement<any, string | JSXElementConstructor<any>>
  ) => {
    return (
      <>
        {menu}
        <Divider style={{ margin: "4px 0" }} />
        <Button
          key="add-button"
          type="primary"
          onClick={handleAddChannel}
          style={{ width: "100%" }}
        >
          Add
        </Button>
      </>
    );
  };

  // TODO: add locale to title
  const columns = useMemo(() => {
    const colmuns: TableProps<Channel>["columns"] = [
      {
        title: "Code",
        key: "code",
        dataIndex: "code",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.code.localeCompare(b.code)
      },
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        filters: productChannels.map((x) => ({
          text: x.name,
          value: x.code
        })),
        filterSearch: true,
        onFilter: (value: any, record) => record.code === value
      }
    ];
    if (isEditMode === true) {
      colmuns.push({
        title: "Action",
        key: "action",
        width: 20,
        render: (_, r) => (
          <Button
            type="text"
            danger
            onClick={() => handleRemoveChannel(r.code)}
          >
            Remove
          </Button>
        )
      });
    }
    return colmuns;
  }, [isEditMode, handleRemoveChannel]);

  return (
    <Container>
      <Flex justify="space-between" align="center">
        <b>Channels</b>
        {isEditMode ? (
          <Select
            open={showDropdown}
            onDropdownVisibleChange={setShowDropdown}
            showSearch
            mode="multiple"
            options={channelsSelection}
            style={{ width: 200 }}
            placeholder="Find to add"
            value={selectedChannels}
            dropdownRender={optionRenderer}
            onChange={setSelectedChannels}
            disabled={channelsSelection.length === 0}
            tagRender={() => <></>}
          />
        ) : null}
      </Flex>
      <Table
        rowKey="code"
        columns={columns}
        dataSource={productChannels}
        style={{ marginTop: 10 }}
        pagination={false}
      />
    </Container>
  );
};
