import "./detailsContent.scss";
import AttributesTable from "../attributesTable/AttributesTable";
import { Tabs, TabsProps } from "antd";
import { useCategoryPageState } from "../../../../domain/hooks/useCategoryPageState";
import CategorySettings from "../categorySettings/CategorySettings";
import { t, tMap } from "../../../../../../features/i18n/translation";

interface Props {}

const items: TabsProps["items"] = [
  {
    key: "1",
    label: t(tMap["category.common.attributes"]),
    children: <AttributesTable />
  },
  {
    key: "2",
    label: t(tMap["category.common.settings"]),
    children: <CategorySettings />
  }
];

export const DetailsContent: React.FC<Props> = (): JSX.Element => {
  const [state] = useCategoryPageState();

  if (state.currentCategory?.id !== state.baseCategoryId) {
    return (
      <div className="details-content">
        <AttributesTable />
      </div>
    );
  }

  return (
    <div className="details-content">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default DetailsContent;
