import { ApiListResponse } from "../../../common/types/commonTypes";
import { apiService } from "../api/ApiService";
import { attributesDebugFlags } from "../common/helpers/debugging/attributesDebugFlags";
import { AttributeEntity } from "../models/entities/attributeEntity";
import { productionMockAttributes } from "./productionMockAttributes";

const mockAttributes =
  productionMockAttributes as unknown as ApiListResponse<AttributeEntity>;

export async function useSeedDataBaseWithProductionMocks() {
  if (!attributesDebugFlags.listing.useProductionMocks) return;

  mockAttributes.items.forEach(async (attribute) => {
    const creationPayload = {
      name: attribute.name,
      dataType: attribute.dataType,
      inputType: attribute.inputType
    };
    const response = await apiService.createAttribute(creationPayload);
    const putResponse = await apiService.putAttribute(response.id, attribute);
  });
}
