import { AssetMinimumInfo } from "@pm/models/ProductEntity";
import { Button, Table, TablePaginationConfig } from "antd";
import pagination from "antd/es/pagination";
import React, { Key, useEffect, useState } from "react";
import styled from "styled-components";
import { t, tMap } from "../../../../../features/i18n/translation";
import { VariantListItem } from "./VariantListItem";

const Container = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }

  .custom-cell {
    padding: 8px !important;
  }

  .action-cell {
    padding: 0 !important;
    text-align: center;
  }

  .id-cell {
    width: 220px;
    word-break: break-all;

    @media screen and (max-width: 1100px) {
      width: 100px;
    }
  }

  .ant-table-cell,
  .ant-table-container {
    border-radius: 0 !important;
  }

  .ant-pagination-options {
    margin-inline-start: 8px;
  }

  .ant-table-selection-column {
    padding: 0 !important;

    .ant-checkbox-wrapper {
      display: none;
    }
  }
`;

export interface TableDataType {
  key: Key;
  name: string;
  thumbnail: AssetMinimumInfo | null;
  attributes: { label?: string; value?: string }[];
}

interface Props {
  isEdit: boolean;
  dataSource?: TableDataType[];
  onRowClick: (id: string) => void;
  selectedVariantId?: string;
  onDeleteVariant: (id: string) => void;
}

export const VariantsTable: React.FC<Props> = ({
  isEdit,
  dataSource,
  selectedVariantId,
  onRowClick,
  onDeleteVariant
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  useEffect(() => {
    setSelectedRowKeys(selectedVariantId ? [selectedVariantId] : []);
  }, [selectedVariantId]);

  const paginationProps: TablePaginationConfig = {
    position: ["bottomLeft"],
    showQuickJumper: true,
    showSizeChanger: true,
    ...pagination
  };

  const columns = [
    {
      title: t(tMap["common.id"]),
      dataIndex: "key",
      key: "key",
      onCell: () => ({ className: "id-cell" })
    },
    {
      title: t(tMap["product.variantsTable.variantProduct"]),
      render: (value: TableDataType) => (
        <VariantListItem
          name={value.name}
          thumbnail={value.thumbnail}
          attributes={value.attributes}
        />
      )
    },
    {
      title: t(tMap["common.action"]),
      width: 100,
      hidden: !isEdit,
      dataIndex: "key",
      onCell: () => ({ className: "action-cell" }),
      render: (value: string) => (
        <Button
          danger
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            onDeleteVariant(value);
          }}
        >
          {t(tMap["common.remove"])}
        </Button>
      )
    }
  ];

  return (
    <>
      <Container
        bordered
        pagination={paginationProps}
        columns={columns}
        dataSource={dataSource}
        rowSelection={{ selectedRowKeys, columnWidth: 0 }}
        onRow={(record) => ({
          onClick: () => {
            setSelectedRowKeys([record.key]);
            onRowClick(record.key as string);
          }
        })}
      />
    </>
  );
};
