import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routeConstants } from "../../features/routes/routeConstants";
import { attributesRouteConstants } from "../../apps/attributesManagement/attributeRoutes";

const isDebugMode = true;

function getIsInputActive(): boolean | null {
  const isInputActive = ["INPUT", "TEXTAREA"].includes(
    document.activeElement?.nodeName ?? ""
  );

  return isInputActive;
}

export function getDevElement(devId: string): HTMLElement | null {
  const isInputActive = getIsInputActive();
  if (isInputActive) return null;

  const target = document.querySelector<HTMLElement>(`[dev-id='${devId}']`);
  if (target) return target;

  const otherTarget = document.querySelector<HTMLElement>(`#dev-${devId}`);
  if (otherTarget) return otherTarget;

  const testId = document.querySelector<HTMLElement>(
    `[data-testid='${devId}']`
  );
  return testId;
}

const useDevShortcuts = (): void => {
  const navigate = useNavigate();

  function devNavigate(ev: KeyboardEvent, route: string): void {
    if (ev.ctrlKey) return;

    const isInputActive = getIsInputActive();
    if (isInputActive) return;
    navigate(route);
  }

  useEffect(() => {
    if (!isDebugMode) return;
    if (!window.location.host.includes("localhost:")) return;

    document.addEventListener("keydown", (e: KeyboardEvent) => {
      if (e.ctrlKey) return;

      switch (e.key) {
        case "a": {
          devNavigate(e, attributesRouteConstants.attributes);
          break;
        }
        case "c": {
          console.clear();
          break;
        }
        case "d": {
          devNavigate(e, attributesRouteConstants.attributeDetail);
          break;
        }
        case "h": {
          getDevElement("secondaryAction")?.click();
          break;
        }
        case "o": {
          devNavigate(e, "/");
          break;
        }
        case "q": {
          devNavigate(e, routeConstants.quick);
          break;
        }
        case "r": {
          if (getDevElement("request-a-new-brand")) {
            getDevElement("request-a-new-brand")?.click();
          }
          break;
        }
        case "s": {
          getDevElement("submit-request")?.click();
          break;
        }
        case "v": {
          devNavigate(e, routeConstants.version);
          break;
        }
        case "x": {
          getDevElement("confirm")?.click();
          break;
        }
        // case 'z': {
        //   break;
        // }

        default: {
          break;
        }
      }
    });
  }, []);
};

export default useDevShortcuts;
