import { styled } from "styled-components";
import { themeColors } from "../../../../../common/ui/styles/themeColors";

export const AttributeDetailsPageStyled = styled.div`
  &.is-editing {
    .ant-tree-node-content-wrapper {
      background: #f5f5f5;
    }
  }

  .attribute-name {
    padding: 5px 8px;
    align-self: center;
  }
`;
