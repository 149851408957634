/* eslint-disable react-hooks/rules-of-hooks */
import { Row, Col, Button } from "antd";
import AinGrid from "../../molecules/AinGrid";
import { debugFlags } from "../../../modules/debugging/debugFlags";
import TextWithLabel from "../../molecules/TextWithLabel";
import {
  useAttributesAtomWithImmer,
  useAttributeDetailWithImmer
} from "../../../../apps/attributesManagement/state/atoms/attributeAtomsImmer";
import { useAttributesStore } from "../../../../apps/attributesManagement/state/stores/attributesStore";
import { useCommonStore } from "../../../domain/state/stores/useCommonStore";

export const DebugPanel: React.FC = (): JSX.Element => {
  if (!debugFlags.showDebugPanel) return <></>;

  const { ui, entity } = useAttributesStore();
  const { draftAttribute } = entity;
  const [attributes] = useAttributesAtomWithImmer();
  const currentAttribute = useAttributeDetailWithImmer();
  const { locales } = useCommonStore().ui;

  return (
    <div style={{ padding: 2, position: "fixed", bottom: 20 }}>
      <AinGrid colSpans={[]} colGap={10}>
        <Row>
          {Object.entries(ui).map(([key, value]) => {
            if (typeof value === "function") return <Col key={key}></Col>;
            return (
              <Col key={key}>
                <TextWithLabel label={`${key}:`} direction="horizontal">
                  {String(value)}
                </TextWithLabel>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col># of attributes: {attributes.length}</Col>
          <Col>
            <Button
              onClick={() => {
                console.log(attributes);
              }}
            >
              Log
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            Current attribute {currentAttribute?.name} ({currentAttribute?.id})
            (Template: {currentAttribute?.template?.length})
          </Col>
          <Col>
            <Button
              onClick={() => {
                console.log(currentAttribute);
              }}
            >
              Log
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            Current <strong>draft</strong> {draftAttribute?.name} (
            {draftAttribute?.id}) (Template: {draftAttribute?.template?.length})
          </Col>
          <Col>
            <Button
              onClick={() => {
                console.log(draftAttribute);
              }}
            >
              Log
            </Button>
          </Col>
        </Row>
        <Row>
          {locales.map((locale, index) => {
            return (
              <Col key={index}>
                Code: {locale.code} - Name: {locale.name}
              </Col>
            );
          })}
        </Row>
      </AinGrid>
    </div>
  );
};
