import deepmerge from "deepmerge";

export default function deepMergeOverwriteArray<T>(
  x: Partial<T>,
  y: Partial<T>
): T {
  return deepmerge(x, y, {
    arrayMerge: (_, source) => source
  });
}
