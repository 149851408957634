import {
  GetProductResponse,
  ProductEntity,
  ProductResponse
} from "@pm/models/ProductEntity";
import BaseApiService from "../../../common/services/ApiService";
import { CreateProductRequest } from "@pm/models/CreateProductRequest";
import { ApiListRequest } from "../../../common/types/commonTypes";
import { commonConstants } from "../../../common/constants/commonConstants";
import ChannelMinimunInfo from "../../../common/domain/valueObjects/ChannelMinimumInfo";

const baseUrl = `/product/products`;
const ApiService = new BaseApiService(baseUrl);

type GetProductsParams = ApiListRequest & {
  locale?: string;
  categoryId?: string[];
};

class ProductsApiService {
  public async getProducts(
    params: GetProductsParams
  ): Promise<ProductResponse> {
    const productParams = new URLSearchParams();
    if (params.locale && params.locale !== commonConstants.locale.default) {
      productParams.append("locale", params.locale);
    }
    if (params.categoryId && params.categoryId.length) {
      productParams.append("categoryId", JSON.stringify(params.categoryId));
    }

    const products = await ApiService.getFetcher<ProductResponse>(
      "",
      ApiService.buildSearchParams(params, productParams)
    );

    return Promise.resolve(products);
  }

  public async getProductVariants(
    productId: string,
    locale: string
  ): Promise<ProductResponse> {
    const products = await ApiService.getFetcher<ProductResponse>(
      `/${productId}/variants?locale=${locale}`
    );
    return Promise.resolve(products);
  }

  public async getProduct(id: string): Promise<ProductEntity> {
    return ApiService.getFetcher(`/${id}`);
  }

  public async updateProduct(
    id: string,
    product: GetProductResponse
  ): Promise<GetProductResponse> {
    return ApiService.putFetcher(`/${id}`, product);
  }

  public async unpublishProduct(id: string): Promise<GetProductResponse> {
    return ApiService.postFetcher(`/${id}/unpublish`, {});
  }

  public async discardProduct(id: string): Promise<GetProductResponse> {
    return ApiService.postFetcher(`/${id}/discard`, {});
  }

  public async deleteProduct(id: string): Promise<void> {
    return ApiService.deleteFetcher(`/${id}`);
  }

  public async createProduct(
    data: CreateProductRequest
  ): Promise<ProductEntity> {
    return ApiService.postFetcher("", data);
  }

  public async publishProduct(
    id: string,
    locales: string[],
    channels: ChannelMinimunInfo[]
  ): Promise<GetProductResponse> {
    return ApiService.postFetcher(`/${id}/publish`, {
      locales,
      channels
    });
  }

  public async annotateProduct(id: string): Promise<void> {
    return ApiService.postFetcher(`/${id}/annotate`, {});
  }
}

export const productsApiService = new ProductsApiService();
