import { RouteObject } from "react-router-dom";
import { AttributeDetailsController } from "./ui/pages/attributeDetails/AttributeDetailsController";
import { DetailsConfig } from "./ui/pages/attributeDetails/detailsConfig/DetailsConfig";
import { DetailsOptionValueController } from "./ui/pages/attributeDetails/detailsOptionValue/DetailsOptionValueController";
import { AttributeListing } from "./ui/pages/attributeListing/AttributeListingPage";

export const ATTRIBUTE_BASE_ROUTE = "/attributes";
export const ATTRIBUTE_ID_PARAM = "id";

export const attributesRouteConstants = {
  base: ATTRIBUTE_BASE_ROUTE,
  attributes: "attributes",
  attributeDetail: "attributes/:id",
  attributeDetailConfig: "config",
  attributeDetailOptionValue: "options-value"
};

export interface IAttributeDetailParams {
  // [ATTRIBUTE_ID_PARAM]: string;
  id: string;
  [key: string]: string;
}

const attributesRouter: RouteObject = {
  path: attributesRouteConstants.attributes,
  Component: AttributeListing
};

const detailsOptionValueRouter: RouteObject = {
  path: attributesRouteConstants.attributeDetailOptionValue,
  Component: DetailsOptionValueController
};
const configValueRouter: RouteObject = {
  path: attributesRouteConstants.attributeDetailConfig,
  Component: DetailsConfig
};

const attributeDetailsRouter: RouteObject = {
  path: `${attributesRouteConstants.attributeDetail}/*`,
  Component: AttributeDetailsController,
  children: [configValueRouter, detailsOptionValueRouter]
};

export const attributeRoutes: RouteObject[] = [
  attributesRouter,
  detailsOptionValueRouter,
  configValueRouter,
  attributeDetailsRouter
];
