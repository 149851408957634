import deepmerge from "deepmerge";
import { useCallback, useEffect } from "react";
import { useAttributesStore } from "../../../state/stores/attributesStore";
import { AttributeDetails } from "./AttributeDetailsPage";
import { apiService } from "../../../api/ApiService";
import { useParams } from "react-router-dom";
import { addIdsToTemplates } from "../../../common/helpers/addIdsToTemplates";
import { useUpdateAttributeWithImmer } from "../../../state/atoms/attributeAtomsImmer";
import { AttributeEntity } from "../../../models/entities/attributeEntity";
import { Form } from "antd";
import { debounce } from "../../../../../common/modules/debounce";
import { attributeConstants } from "@am/common/constants/constants";
import { updateAttributeImmer } from "@am/state/updateAttribute";
import { FormProviderProps } from "antd/es/form/context";
import { combineMerge } from "../../../../../common/modules/combineMerge";

function overwritePayload(attributeEntity: AttributeEntity) {
  if (attributeEntity.inputSettings?.selectionType === "multiple") {
    attributeEntity.isMultipleValues = true;
  }
  return attributeEntity;
}

export const AttributeDetailsController: React.FC = (): JSX.Element => {
  const { entity, ui } = useAttributesStore();
  const { draftAttribute, setDraftAttribute } = entity;
  const { isEditing, setIsEditing, lastButtonClick, setLastButtonClick } = ui;
  const { id: attributeName } = useParams();
  const updateAttribute = useUpdateAttributeWithImmer();

  // TODO: use for save draft
  const callPutApi = useCallback(
    async (payload: AttributeEntity | null) => {
      if (!payload) return;

      if (lastButtonClick === "publish") {
        const response = await apiService.putAttribute(payload.id, payload);
        setDraftAttribute(addIdsToTemplates(response));
        updateAttribute(draftAttribute?.id, response);
      } else if (lastButtonClick === "saveDraft") {
        const response = await apiService.putAttributeDraft(
          payload.id,
          payload
        );
        updateAttribute(response.id, response);
      }

      setLastButtonClick(null);
    },
    [
      draftAttribute?.id,
      lastButtonClick,
      setDraftAttribute,
      setLastButtonClick,
      updateAttribute
    ]
  );

  const onFormFinish = debounce<FormProviderProps["onFormFinish"]>(
    async (_, { forms }) => {
      let draft: AttributeEntity | null = draftAttribute;
      Object.entries(forms).forEach(([treeKey, form]) => {
        const denyList = [attributeConstants.forms["add-new-input-popover"]];
        if (denyList.includes(treeKey)) return;

        const { labels, ...updated } = form.getFieldsValue(
          true
        ) as AttributeEntity; // workaround: labels is set separately - todo: use nested antd form properly?
        draft = updateAttributeImmer(draft, updated, {
          keyPath: treeKey
        });
      });
      setDraftAttribute(draft);
    },
    attributeConstants.code.debounceTime,
    true
  );

  useEffect(() => {
    if (!draftAttribute) return;
    if (!isEditing) return;

    const withIds = addIdsToTemplates(draftAttribute);
    setDraftAttribute(withIds);
  }, [isEditing, draftAttribute, setDraftAttribute]);

  useEffect(() => {
    (async () => {
      if (draftAttribute) return;
      const response = await apiService.getAttributeByName(attributeName);
      setDraftAttribute(response);
    })();
  }, [draftAttribute, attributeName, setDraftAttribute]);

  useEffect(() => {
    if (!draftAttribute) return;
    if (!lastButtonClick) return;

    (async function () {
      if (lastButtonClick === "publish") {
        const updated = overwritePayload(draftAttribute);
        const response = await apiService.putAttribute(
          draftAttribute.id,
          updated
        );

        /**
         * From draft keep the ids.
         * From response  get update timestamp
         */
        const deepMerged = deepmerge(draftAttribute, response, {
          arrayMerge: combineMerge
        });
        setDraftAttribute(deepMerged);
        updateAttribute(draftAttribute?.id, response);
        setIsEditing(false);
      }
    })();
    setLastButtonClick(null);
  }, [lastButtonClick]);

  if (!draftAttribute) return <>Loading (AttributeDetailsController.tsx)</>;

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <AttributeDetails attributeEntity={draftAttribute}></AttributeDetails>;
    </Form.Provider>
  );
};
