import { GlobalOutlined } from "@ant-design/icons/lib/icons";
import "./categoryTree.scss";
import { Spin, Tooltip, Tree } from "antd";
import { Key, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routeConstants } from "../../../../../features/routes/routeConstants";
import { DataNode } from "antd/es/tree";
import { useCategoryPageState } from "../../../domain/hooks/useCategoryPageState";
import { t, tMap } from "../../../../../features/i18n/translation";

interface Props {
  treeData?: DataNode[];
}

const treeListId = "category-tree-list";

const findKeyById = (tree: DataNode[], id: string): string | undefined => {
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if ((node!.key as string).endsWith(id)) {
      return node!.key as string;
    }

    if (node.children?.length) {
      const found = findKeyById(node.children, id);

      if (found) {
        return found;
      }
    }
  }
};

export const CategoryTree: React.FC<Props> = ({ treeData }): JSX.Element => {
  const [state] = useCategoryPageState();
  const [isBaseSelected, setIsBaseSelected] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>();
  const [expandedKeys, setExpandedKeys] = useState<Key[]>();
  const { id: categoryId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!categoryId || !treeData) {
      return;
    }

    // In case user clicked on the tree item the app also navigated but not have state isNew in the location
    // then no need do overwrite any state in component.
    if (selectedKeys && !location?.state?.isNew) {
      return;
    }

    if (categoryId === state.baseCategoryId) {
      setIsBaseSelected(true);
      setSelectedKeys(undefined);
      return;
    }

    const foundKey = findKeyById(treeData, categoryId);
    if (foundKey) {
      setIsBaseSelected(false);
      setSelectedKeys([foundKey]);
      setExpandedKeys([foundKey]);
    }
  }, [categoryId, state.baseCategoryId, treeData]);

  const handleBaseCategoryClick = () => {
    setIsBaseSelected(true);
    setSelectedKeys(undefined);

    navigate(`/${routeConstants.categories}/${state.baseCategoryId}`);
  };

  const handleTreeSelected = (keys: Key[]) => {
    if (!keys?.length) {
      return;
    }

    setSelectedKeys(keys);
    setIsBaseSelected(false);

    const categoryIds = (keys[0] as string).split("|");
    const categoryId = categoryIds[categoryIds.length - 1];
    navigate(`/${routeConstants.categories}/${categoryId}`);
  };

  if (!treeData) {
    return <Spin className="loading-category" />;
  }

  return (
    <div className="category-tree">
      <div className="base-category" onClick={handleBaseCategoryClick}>
        <Tooltip
          overlayClassName="base-category-tooltip"
          placement="rightTop"
          color="#fff"
          title={
            <div>
              <div className="title"></div>
              {t(tMap["category.tree.baseTooltip"])}
            </div>
          }
        >
          <GlobalOutlined className="base-category-icon" />
          <div
            className={`base-category-name ${isBaseSelected ? "selected" : ""}`}
          >
            {t(tMap["category.tree.baseCategory"])}
          </div>
        </Tooltip>
      </div>

      <div id={treeListId}>
        <Tree
          selectable
          autoExpandParent
          selectedKeys={selectedKeys}
          expandedKeys={expandedKeys}
          onSelect={handleTreeSelected}
          onExpand={setExpandedKeys}
          treeData={treeData}
        />
      </div>
    </div>
  );
};

export default CategoryTree;
