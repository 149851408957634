import { DefaultOptionType } from "antd/es/select";
import { Labels } from "../../../common/domain/valueObjects/Label";

export default function getLabel(
  labels: Labels[] | null | undefined,
  targetLocale: DefaultOptionType | undefined,
  fallback?: string
) {
  if (!labels || labels.length === 0) return fallback || "";
  if (!targetLocale) return labels[0].value || fallback || "";
  
  const label = labels.find(x => targetLocale.value === x.locale) ?? labels[0];
  return label.value || fallback || "";
}