import { useCallback, useEffect } from "react";
import EditorViewMode from "@pm/ui/atoms/EditorViewMode";
import EditorEditMode from "@pm/ui/atoms/EditorEditMode";
import BaseEditorProps from "@pm/models/BaseEditorProps";
import getEditerName from "@pm/hooks/getEditerName";
import { AssetMinimumInfo } from "@pm/models/ProductEntity";
import { styled } from "styled-components";
import { FileOutlined } from "@ant-design/icons";
import { Button } from "antd";
import {
  downloadFile,
  isImageFileName
} from "../../../../../common/modules/fileHelper";
import { PreviewImage } from "@shared/ui/atoms/PreviewImage";
import { DnDUploader } from "@shared/ui/atoms/DnDUploader";
import { Rule } from "antd/es/form";
import { AssetType } from "@shared/entities/AssetType";
import { requiredButNoValidation } from "./validators/requiredButNoValidation";

// Use when: Input type = File uploader
const EditMode = styled(EditorEditMode)`
  .ant-upload-drag {
    height: 130px !important;
  }
`;

const FilesDisplay = styled.div`
  margin-bottom: -10px;

  .ant-upload-drag {
    height: 130px !important;
  }

  .file {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;

    .filename {
      margin-top: 0px;
      color: #13c2c2;
      padding-left: 0;
      padding-right: 0;
      outline: initial;
      font-weight: 500;

      > span {
        width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .image {
      padding: 6px;
      width: 120px;
      height: 120px;
      position: relative;
      border: 1px solid #00000026;
      border-radius: 6px;

      .ant-image-img,
      .ant-image {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        position: absolute;
        object-fit: contain;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .anticon-eye {
        display: none;
      }
    }

    .anticon {
      display: block;
      padding-top: 35px;
      font-size: 50px;
      text-align: center;
      width: 120px;
      height: 120px;
      color: #878787;
      border: 1px solid #00000026;
      border-radius: 6px;
    }
  }
`;

interface Props extends BaseEditorProps {
  value?: AssetMinimumInfo | AssetMinimumInfo[];
  defaultValue?: AssetMinimumInfo | AssetMinimumInfo[];
  isMultipleValues?: boolean;
}

export const FileUploader: React.FC<Props> = (props): JSX.Element => {
  const {
    isEdit,
    value,
    defaultValue,
    form,
    formItemName,
    attributeName,
    isMultipleValues,
    isRequired
  } = props;
  const translatedName = getEditerName(props);

  useEffect(() => {
    form.setFieldValue(formItemName, value ?? defaultValue);
  }, [value, isEdit]);

  const updateFiles = useCallback((v: any) => {
    form.setFieldValue(formItemName, v ?? defaultValue);
  }, [form]);

  const renderSingleFile = (file: AssetMinimumInfo) => {
    return (
      <span className="file" key={file.id}>
        {isImageFileName(file.fileName) ? (
          <div className="image">
            <PreviewImage src={file.fileLink} alt={file.fileName} />
          </div>
        ) : (
          <FileOutlined />
        )}
        <Button
          className="filename"
          type="link"
          title={file.fileName}
          onClick={() => downloadFile(file.fileLink, file.fileName)}
        >
          {file.fileName}
        </Button>
      </span>
    );
  };

  const renderViewValue = () => {
    if (!value) {
      return "--";
    }

    if (!Array.isArray(value)) {
      return renderSingleFile(value);
    }

    if (!value.length) {
      return "--";
    }

    return value.map(renderSingleFile);
  };

  if (!isEdit) {
    return (
      <EditorViewMode>
        <div className="name">{translatedName}</div>
        <FilesDisplay>{renderViewValue()}</FilesDisplay>
      </EditorViewMode>
    );
  }

  const rules: Rule[] = [];
  if (isRequired) {
    rules.push(requiredButNoValidation());
  }

  return (
    <EditMode
      name={formItemName}
      label={translatedName}
      initialValue={defaultValue}
      labelCol={{ span: 24 }}
      rules={rules}
    >
      <DnDUploader
        id={`file-uploader-${attributeName}`}
        assetType={AssetType.productImage}
        multiple={isMultipleValues}
        files={value && (Array.isArray(value) ? value : [value])}
        listType="picture"
        iconRender={() => <FileOutlined />}
        onChange={updateFiles}/>
    </EditMode>
  );
};

export default FileUploader;
