/**
 * Choose data test selector. The arg will be returned.
 * Convience function for correct spelling
 */
const dataTestSelectors = {
  "add-new-object-field-button": "add-new-object-field-button",
  "add-new-input-popover": "add-new-input-popover",
  "attribute-name": "attribute-name",
  "attribute-setting": "attribute-setting",
  "button-add": "button-add",
  "button-back": "button-back",
  "button-import": "button-import",
  "button-remove": "button-remove",
  "button-refresh": "button-refresh",
  "category-name": "category-name",
  "confirm-modal": "confirm-modal",
  "create-new-button": "create-new-button",
  "create-new-attribute-modal": "create-new-attribute-modal",
  "data-type": "data-type",
  "dataSettings|numberType": "dataSettings|numberType",
  "dataSettings|pattern": "dataSettings|pattern",
  "details-options-value": "details-options-value",
  "edit-button": "edit-button",
  "edit-category-button": "edit-category-button",
  "delete-button": "delete-button",
  "input-option": "input-option",
  "input-type": "input-type",
  "inputSettings|allowedFileTypes": "inputSettings|allowedFileTypes",
  "inputSettings|searchable": "inputSettings|searchable",
  "inputSettings|selectionType": "inputSettings|selectionType",
  isChannelDependent: "isChannelDependent",
  isMasterAttribute: "isMasterAttribute",
  isLocaleDependent: "isLocaleDependent",
  "menu-config": "menu-config",
  "menu-optionValue": "menu-optionValue",
  "modal-button-import": "modal-button-import",
  name: "name",
  "object-field-name": "object-field-name",
  "option-no": "option-no",
  "option-yes": "option-yes",
  "options-value-header": "options-value-header",
  "publish-button": "publish-button",
  select: "select",
  "select-title": "select-title",
  "select-locale": "select-locale",
  "table-options-value": "table-options-value",
  "text-value": "text-value"
} as const;

export type DataTestSelectors = keyof typeof dataTestSelectors;
export type PickDataTestSelectors<T extends DataTestSelectors> = Pick<
  typeof dMap,
  T
>;

export const dMap = dataTestSelectors;
