import { useSetAtom } from "jotai";
import { atomWithListeners } from "../../../../common/hooks/state/atomWithListeners";

const [categoryUpsertAtom, useCategoryUpsertListener] =
  atomWithListeners(undefined);

export const useDispatchCategoryUpsert = () => {
  return {
    dispatchCategoryUpsert: useSetAtom(categoryUpsertAtom),
  };
};

export { useCategoryUpsertListener };
