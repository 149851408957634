import { GlobalOutlined } from "@ant-design/icons/lib/icons";
import { Spin, Tree } from "antd";
import { useEffect, useState } from "react";
import { DataNode } from "antd/es/tree";
import { t, tMap } from "../../../../features/i18n/translation";
import { CategoryListResponse } from "@cm/domain/valueObjects/categoryListResponse";
import { categoryApiService } from "@cm/common/services/categoryApiService";
import Search from "antd/es/input/Search";
import styled, { css } from "styled-components";
import { useUIState } from "@pm/hooks/useUIState";
import { themeConstants } from "../../../../common/ui/styles/themeConstants";
import { useAtomState } from "../../../../common/hooks/useAtomState";
import {
  ProductListState,
  productListStateAtom
} from "@pm/state/productListState";

const heightAboveTree =
  themeConstants.headerHeight + themeConstants.menubarHeight + 74 + 42; // 74: search box height, 42: all products height

const LoadingSpin = styled(Spin)`
  display: block;
  text-align: center;
  padding-top: 16px;
`;

const Container = styled.div`
  padding: 16px 10px;

  .tree {
    margin-top: 10px;
    margin-left: -6px;
    height: calc(100vh - ${heightAboveTree}px);
    overflow: auto;

    .ant-tree-node-content-wrapper {
      padding-left: 0;
    }

    .ant-tree-node-selected {
      background-color: #dbfff7;
    }

    .ant-tree-node-content-wrapper {
      flex-grow: 1;
    }

    .ant-tree-treenode {
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
`;

const SearchCategory = styled(Search)<{ hidden: boolean }>`
  margin-bottom: 16px;

  ${(props) => {
    if (props.hidden) {
      return css`
        display: none;
      `;
    }
  }}
`;

const AllProducts = styled.div<{ selected: boolean }>`
  margin-bottom: 8px;

  .icon {
    margin-right: 10px;
  }

  .label {
    cursor: pointer;
    display: inline-block;
    width: calc(100% - 24px);
    padding: 2px;
    border-radius: 6px;

    ${(props) =>
      props.selected
        ? css`
            background-color: #dbfff7;
          `
        : css`
            &:hover {
              background-color: #0000000a;
            }
          `}
  }
`;

interface Props {}

const filterTreeByKeyword = (
  tree: DataNode[],
  keyword: string
): Set<string> => {
  const result = new Set<string>();

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if ((node!.title as string).toLowerCase().indexOf(keyword) > -1) {
      const keys = node.key.toString().split("|");
      keys.forEach((key) => {
        result.add(key);
      });
    }

    if (node.children?.length) {
      const filteredKeys = filterTreeByKeyword(node.children, keyword);
      filteredKeys.forEach((key) => {
        result.add(key);
      });
    }
  }

  return result;
};

export const CategoryTree: React.FC<Props> = (): JSX.Element => {
  const [treeData, setTreeData] = useState<DataNode[] | undefined>();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isAllProductsSelected, setIsAllProductsSelected] =
    useState<boolean>(true);
  const { uiState, loadAllCategories } = useUIState();
  const { setState } = useAtomState<ProductListState>(productListStateAtom);
  const { allCategories } = uiState;

  const convertToTree = (
    categories: CategoryListResponse[],
    parentId?: string,
    parentPath?: string
  ): DataNode[] => {
    const filteredCategories = categories.filter(
      (category) => category.parentId === parentId
    );
    return filteredCategories.map((category) => ({
      key: `${parentPath}|${category.id}`,
      title: category.name,
      children: convertToTree(
        categories,
        category.id,
        `${parentPath}|${category.id}`
      )
    }));
  };

  const loadCategoryTree = async () => {
    if (!allCategories) return;
    const nonBaseCategories: CategoryListResponse[] = [];
    let treeDataTemp: DataNode[] = [];
    let baseCategoryId: string | undefined;

    if (allCategories.length) {
      allCategories.forEach((category) => {
        if (category.isBaseCategory) {
          baseCategoryId = category.id;
        } else {
          nonBaseCategories.push(category);
        }
      });

      treeDataTemp = convertToTree(
        nonBaseCategories,
        baseCategoryId,
        baseCategoryId
      );
    }
    setTreeData(treeDataTemp);
  };

  useEffect(() => {
    loadAllCategories();
  }, []);
  useEffect(() => {
    loadCategoryTree();
  }, [allCategories]);

  const resolveCategoryIds = (keys: string[]) =>
    keys.length
      ? keys.map((x) => {
          const ids = x.split("|");
          return ids[ids.length - 1];
        })
      : [];

  const handleAllProductsClicked = () => {
    setIsAllProductsSelected(true);

    if (treeData) {
      setSelectedCategories([]);
      setState("selectedCategoryIds", []);
    }
  };

  const handleCategoryChecked = (keys: any) => {
    setSelectedCategories(keys);
    setState("selectedCategoryIds", resolveCategoryIds(keys));

    if (isAllProductsSelected) {
      setIsAllProductsSelected(false);
    }
  };

  const handleCategoryClicked = (event: any) => {
    const checkBoxClassName = "ant-tree-checkbox";
    const treeNodeClassName = "ant-tree-treenode";

    // expecting the DOM tree structure: treeNodeClassName -> checkBoxClassName + event.target
    let treeNodeElement = event.target.parentNode;

    // sometimes the DOM tree structure: treeNodeClassName -> checkBoxClassName + ("ant-tree-node-content-wrapper" -> event.target)
    // so we need get one more parentNode to have the element of tree node.
    if (!treeNodeElement.className.includes(treeNodeClassName)) {
      treeNodeElement = treeNodeElement.parentNode;
    }

    const checkboxElement =
      treeNodeElement.getElementsByClassName(checkBoxClassName);

    if (!checkboxElement.length) {
      return;
    }

    checkboxElement[0].click();
  };

  const handleSearch = (searchKeyword: string) => {
    if (!uiState.allCategories) {
      return;
    }

    const baseCategoryId = uiState.allCategories.find(
      (x) => x.isBaseCategory
    )?.id;
    let treeDataTemp = convertToTree(
      uiState.allCategories,
      baseCategoryId,
      baseCategoryId
    );
    const filteredKeys = Array.from(
      filterTreeByKeyword(treeDataTemp, searchKeyword.toLowerCase())
    );
    const filteredCategories = uiState.allCategories.filter(
      (x) =>
        !x.isBaseCategory &&
        (searchKeyword ? filteredKeys.includes(x.id) : true)
    );

    treeDataTemp = convertToTree(
      filteredCategories,
      baseCategoryId,
      baseCategoryId
    );
    setTreeData(treeDataTemp);
    setIsAllProductsSelected(false);
    setSelectedCategories([]);
  };

  if (!treeData) {
    return <LoadingSpin />;
  }

  return (
    <Container>
      <SearchCategory
        placeholder={t(tMap["product.tree.searchPlaceholder"])}
        onSearch={handleSearch}
        hidden={!uiState.isSidebarOpen}
      />
      <AllProducts
        onClick={handleAllProductsClicked}
        selected={isAllProductsSelected}
      >
        <GlobalOutlined className="icon" />
        <span className="label">{t(tMap["product.tree.allProducts"])}</span>
      </AllProducts>

      <div className="tree">
        <Tree
          checkable
          onCheck={handleCategoryChecked}
          onClick={handleCategoryClicked}
          checkedKeys={selectedCategories}
          treeData={treeData}
        />
      </div>
    </Container>
  );
};

export default CategoryTree;
