import { styled } from "styled-components";
import { themeColors } from "../../../../../../common/ui/styles/themeColors";

export const DetailsHeaderStyled = styled.section`
  background: ${themeColors.grey3};
  border-bottom: 1px solid ${themeColors.colorBorder};
  padding: 16px;

  .header-breadcrumb {
    margin-bottom: 12px;
    padding: 0 8px;
    .header-breadcrumb-app-title {
      color: ${themeColors.colorTextDescription};
    }
  }

  .header-details-name-container {
    margin-bottom: 12px;
    padding: 0 12px;
    .details-master-attribute {
      margin-right: 8px;
      vertical-align: "middle";
    }

    .header-details-name {
      margin: 0;
      color: ${themeColors.colorTextSecondary};
    }
  }

  .header-details-info-and-actions {
    padding: 0 12px;
    a {
      color: ${themeColors.cyan6};
    }
    .details-info-row {
      margin-bottom: 8px;
    }
  }
`;
