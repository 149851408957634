import { Form, FormInstance, Select } from "antd";
import "./selectionFormItem.scss";
import { useEffect, useRef, useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import { t, tMap } from "../../../../../../features/i18n/translation";

interface Props {
  formItemName: string[];
  options: DefaultOptionType[];
  value: string | undefined | null;
  form: FormInstance;
  isMultiple?: boolean;
}

export const SelectionFormItem: React.FC<Props> = ({
  form,
  formItemName,
  value,
  options,
  isMultiple
}): JSX.Element => {
  const [editing, setEditing] = useState<boolean>(false);
  const [updatedValue, setUpdatedValue] = useState<any>(value);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    form.setFieldValue(formItemName, value ?? undefined);
  }, [value]);

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  if (!editing) {
    const findLabel = (v: string) =>
      options.find((x) => x.value === v)?.label ?? t(tMap["category.common.na"]);

    return (
      <span className="selection-form-item" onClick={() => setEditing(true)}>
        {Array.isArray(updatedValue)
          ? updatedValue?.map(findLabel).join(", ") ?? t(tMap["category.common.na"])
          : findLabel(updatedValue)}
      </span>
    );
  }

  const handleOnBlur = async () => {
    setEditing(false);

    let newValue = await form.validateFields();
    formItemName.forEach((x) => {
      newValue = newValue[x];
    });

    setUpdatedValue(newValue);
  };

  return (
    <Form.Item className="selection-form-item" name={formItemName}>
      <Select
        ref={inputRef}
        options={options}
        onBlur={handleOnBlur}
        mode={isMultiple ? "multiple" : undefined}
      />
    </Form.Item>
  );
};

export default SelectionFormItem;
