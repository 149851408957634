import { productEntityStateAtom } from "@pm/state/productManagementEntityState";
import { useAtom, useSetAtom } from "jotai";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { borders } from "../../../../../common/ui/styles/themeColors";
import { ProductDetailForm } from "@pm/ui/pages/productDetailsPage/productDetailForm/ProductDetailForm";
import { useUIState, useUIStateReadOnly } from "@pm/hooks/useUIState";
import { ThumbnailImage } from "../thumbnailImage/ThumbnailImage";
import { AssetMinimumInfo, ProductTypeEnum } from "@pm/models/ProductEntity";
import { Divider } from "antd";
import ExternalAnnotation from "@pm/ui/molecules/ExternalAnnotation";
import { productsApiService } from "@pm/api/ProductsApiService";
import { t, tMap } from "../../../../../features/i18n/translation";
import { useUpdateProductPublisher } from "@pm/hooks/useUpdateProductPublisher";

const thumbnailSectionWidth = "300px";

const Container = styled.div`
  display: flex;
  padding: 0 16px;

  .thumbnail-section {
    width: ${thumbnailSectionWidth};
  }

  .main-section {
    flex: 1;
    border-left: 1px solid ${borders.colorBorderSecondary};
  }

  .divider {
    padding-right: 16px;

    .ant-divider {
      margin-top: 8px;
    }
  }
`;

export const ProductInformation: React.FC = () => {
  const { isEditMode } = useUIStateReadOnly();
  const [productLoader] = useAtom(productEntityStateAtom);
  const { data } = productLoader;
  const [thumbnail, setThumbnail] = useState<AssetMinimumInfo | null>(null);
  const updateProductPublisher = useUpdateProductPublisher();
  const { setIsFormUpdated } = useUIState();

  useEffect(() => {
    setThumbnail(data?.thumbnail ?? null);
  }, [data?.thumbnail]);

  const handleSetAnnotate = useCallback(async () => {
    if (!data || !data.id) {
      alert(t(tMap["common.internalError"]));
      return;
    }
    // call annotation, but have to save them first
    await updateProductPublisher(false);
    await productsApiService.annotateProduct(data?.id);
    await productLoader.refetch();
  }, [data, productLoader, updateProductPublisher]);

  const handleOnUploadFinished = (image: AssetMinimumInfo) => {
    setThumbnail(image);
    setIsFormUpdated(true);
  };

  if (!data) {
    return null;
  }

  return (
    <Container>
      <section className="thumbnail-section">
        <ThumbnailImage
          isEdit={isEditMode}
          image={data.thumbnail}
          onUploadFinished={handleOnUploadFinished}
        />
        <div className="divider">
          <Divider />
        </div>
        {isEditMode ? (
          <ExternalAnnotation
            thumbnail={thumbnail}
            onAnnotate={handleSetAnnotate}
          />
        ) : null}
      </section>
      <section className="main-section">
        <ProductDetailForm
          type={ProductTypeEnum.main}
          product={data}
          isEdit={isEditMode}
          thumbnail={thumbnail}
        />
      </section>
    </Container>
  );
};
