/**
 * Map of data-* attributes used in the app
 */
const dataHtmlAttribtueMap = {
  id: "id",
  parentKeyPath: "parentKeyPath"
} as const;
export const dHtmlMap = dataHtmlAttribtueMap;

export type DataHtmlAttribtueMap = keyof typeof dataHtmlAttribtueMap;
export type PickDataHtmlAttribtueMap<T extends DataHtmlAttribtueMap> = Pick<
  typeof dHtmlMap,
  T
>;

// export function htmlDataAttributeMapping(key: DataHtmlAttribtueMap, value: string) {
/**
 * Provide a way to use the dataHtmlAttribtueMap as a map.
 * The 2nd arg `value` will just be returned, so the underyling data-* gets its value
 * @example
 * ```ts
 * <div
 *                                   | Can use intellisense to
 *                                   | find all places, where
 *                                   | `data-id` is used
 *                                   v
 *   data-id={dHtmlMapping(dHtmlMap["id"], getKeyLastKey(treeKey))}
 * ></div>
 * ```
 */
export function dHtmlMapFunc(key: DataHtmlAttribtueMap, value: string) {
  return value;
}
