import { atom } from "jotai";
import { CategoryListResponse } from "../valueObjects/categoryListResponse";
import { CategoryEntity } from "../entities/categoryEntity";

export interface CategoryPageState {
  isOpenTree: boolean;
  isEditing: boolean;
  searchKeyword?: string;
  baseCategoryId?: string; // TODO: should move to categoryPage as a ref
  allCategories?: CategoryListResponse[];
  currentCategory?: CategoryEntity | undefined | null;
  updatedCategory?: CategoryEntity;
}

export const categoryPageAtom = atom<CategoryPageState>({
  isOpenTree: true,
  isEditing: false
});

export default categoryPageAtom;
