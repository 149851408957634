import { translationObject } from "./translations";

export const tMap = translationObject;

export function t(input: string) {
  // find value
  // if dupe, then throw error, should use common
  const translations = Object.entries(tMap).find(
    ([_, value]) => input === value
  );
  if (!translations) return "no i18n found";

  // then inverse map, to get translations
  const finalKey = translations[0] as keyof typeof tMap;
  const result = tMap[finalKey];
  return result;
}
