import { DefaultOptionType } from "antd/es/select";
import { cloneDeep } from "lodash";
import { Labels } from "../../../common/domain/valueObjects/Label";
import { getJsType } from "../../../common/ui/organisms/form/getJsType";

/**
 * `value` may not have all currently available locales, so we enhance the
 *
 * @example
 * ```ts
 *  labels = [
 *    { locale: "en-US", value: "Air Jordan 1 Low" },
 *    { locale: "fr-FR", value: "bonjour" } // Note: this locale is not available in `localeOptions`, eg. because the user removed the localeSupport for this locale
 *  ];
 *  localeOptions = [
 *    { label: "Deutschland (Deutsch)",   value: "de-DE" },
 *    { label: "United States (English)", value: "en-US" },
 *    { label: "Việt Nam (Tiếng Việt)",   value: "vi-VN" }
 *  ];
 *  -->
 *  valueWithOtherLocales = [
 *    { locale: "de-DE", value: null },
 *    { locale: "en-US", value: "Air Jordan 1 Low" },
 *    { locale: "vi-VN", value: null },
 *    { locale: "fr-FR", value: "bonjour" }
 *  ]
 * ```
 */
export function mergeWithOtherLocales(
  /**
   * Data, that comes from the API.
   * Outdated data should be kept (reason: else data loss)
   */
  labels: Labels[] | null,
  /**
   * The current locales, that are "active" (eg. not deactivated by the user)
   * Merge these locales with the data from `labels`
   */
  localeOptions: DefaultOptionType[]
): Labels[] {
  const { isPrimitive } = getJsType(labels);
  if (isPrimitive) {
    // @ts-expect-error `null` for the form, else it adds this value here to the final payload
    return localeOptions.map((locale) => ({
      locale: String(locale.value),
      value: null
    }));
  }

  let labelsTemp = cloneDeep(Array.isArray(labels) ? labels : []);
  if (Object.keys(labels ?? {}).length === 0) {
    labelsTemp = [];
  }

  const enhanced = localeOptions.map((locale) => {
    const targetLocaleIndex = labelsTemp.findIndex(
      (label) => label.locale === locale.value
    );
    let localizedValue: string | Record<string, unknown> = "";
    if (targetLocaleIndex > -1) {
      /**
       * .splice to remove the item from the labels, and later add the remaining labels to the result.
       * We do this, because labels from the API may have outdated locales that are currently available.
       */
      localizedValue = labelsTemp.splice(targetLocaleIndex, 1)[0].value;
    }

    const result: Labels = {
      locale: String(locale.value),
      // @ts-expect-error `null` for the form, else it adds this value here to the final payload
      value: localizedValue || null
    };
    return result;
  });
  enhanced.push(...labelsTemp);
  return enhanced;
}
