import {
  CreateLocaleRequest,
  CreateLocaleResponse
} from "../../domain/entities/locales/LocalesEntity";
import { GetLocaleResponse } from "../../domain/valueObjects/GetLocaleResponse";
import { ApiListResponse, Id } from "../../types/commonTypes";
import BaseApiService from "../ApiService";

const baseUrl = `/settings/locales`;
const ApiService = new BaseApiService(baseUrl);

export class LocalesApiService {
  public async getLocales(): Promise<ApiListResponse<GetLocaleResponse>> {
    const response = (await ApiService.getFetcher(
      ""
    )) as ApiListResponse<GetLocaleResponse>;
    const sortedItems = response?.items.sort((itemA, itemB) =>
      itemA.name.localeCompare(itemB.name)
    );
    return {
      ...response,
      items: sortedItems
    };
  }

  public postLocales(body: CreateLocaleRequest): Promise<CreateLocaleResponse> {
    return ApiService.postFetcher("", body);
  }

  public deleteLocale(localeId: Id): Promise<CreateLocaleResponse> {
    return ApiService.deleteFetcher(`/${localeId}`);
  }
}

export const localeApiService = new LocalesApiService();
