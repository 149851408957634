import { CategoryListResponse } from "@cm/domain/valueObjects/categoryListResponse";
import { atom } from "jotai";
import { ProductEntity } from "@pm/models/ProductEntity";
import { ProductAttributesMap } from "@pm/types/ProductAttributesMap";
import { CategoryAttribute } from "@cm/domain/valueObjects/categoryAttribute";
import { CategoryEntity } from "@cm/domain/entities/categoryEntity";

export interface CategoryAttributeWithOriginal extends CategoryAttribute {
  categoryId: string;
}

// TODO: should be refactored by this suggestion https://github.com/ainavio/foundation-cloud/pull/459#discussion_r1442464783
export interface ProductManagementUIState {
  isEditMode: boolean;
  isSidebarOpen: boolean;
  allCategories?: CategoryListResponse[];
  productVariants?: ProductEntity[];
  categoryList: CategoryEntity[]; // this list should be ordered
  overwriteCategoryIds: string[] | null;
  attributesMap: ProductAttributesMap;
  isFormUpdated: boolean;
  attributeSettingMap: Record<string, CategoryAttributeWithOriginal>;
}

export const uiStateAtom = atom({
  isEditMode: false,
  isSidebarOpen: window.innerWidth > 1130,
  categoryList: [],
  overwriteCategoryIds: null,
  attributesMap: {},
  isFormUpdated: false,
  attributeSettingMap: {}
} as ProductManagementUIState);
