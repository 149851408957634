import { PrimitiveAtom, useAtom } from "jotai";
import { produce } from "immer";

export const useAtomState = <Type>(primitiveAtom: PrimitiveAtom<Type>) => {
  const [state, setState] = useAtom(primitiveAtom);

  const produceSetState = (name: keyof Type, value: any) => {
    setState(
      produce((x: any) => {
        x[name] = value;
      })
    );
  };

  return {
    ...state,
    setState: produceSetState
  };
};
