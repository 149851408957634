import { Flex } from "antd";
import styled from "styled-components";

export const ThumbnailWrapper = styled(Flex)`
  padding: 16px 16px 0 0;

  .filename {
    margin-top: -5px;
    color: #13c2c2;
    padding-left: 0;
    padding-right: 0;
    outline: initial;
    font-weight: 500;
    display: block;
    width: 100%;
    text-align: left;

    > span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .preview-image {
    .ant-image {
      aspect-ratio: 1;
      width: 100%;
    }

    .ant-image-img {
      height: 100%;
      position: absolute;
      object-fit: contain;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .anticon-picture {
    font-size: 80px;
    color: #818181;
  }

  .ant-upload-list-item-name {
    height: 22px;
  }
`;

export const ThumbnailImagePlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  aspect-ratio: 1;
  border: 1px solid #00000040;
`;
