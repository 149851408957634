import { LocaleValues } from "../types/commonTypes";

type CommonConstants = {
  api: {
    version: string;
    baseUrl: string;
  };
  defaultLocale: LocaleValues;
  locale: {
    default: LocaleValues;
    separator: "-";
  };
  tablePageSize: number;
  isTesting: boolean;
  fileApplication: string;
  [name: string]: any;
};

export const commonConstants: CommonConstants = {
  api: {
    version: "v1",
    baseUrl: import.meta.env.VITE_API_BASE_URL ?? "http://localhost:22330"
  },
  tablePageSize: 10,
  defaultLocale: "en-US",
  locale: {
    default: "en-US",
    separator: "-"
  },
  fileApplication: "PIM",
  isTesting: !!(window as any).Cypress
};
