import { Col, FormInstance, Row, Tag } from "antd";
import "./selectionInputSetting.scss";
import { TemplateSetting } from "../../../../domain/valueObjects/templateSetting";
import BooleanFormItem from "@cm/ui/molecules/formItems/booleanFormItem/BooleanFormItem";
import { SelectionType } from "@cm/domain/valueObjects/selectionType";
import TextFormItem from "@cm/ui/molecules/formItems/textFormItem/TextFormItem";
import SelectionFormItem from "@cm/ui/molecules/formItems/selectionFormItem/SelectionFormItem";
import { commonConstants } from "../../../../../../common/constants/commonConstants";
import { t, tMap } from "../../../../../../features/i18n/translation";
import { SelectionLook } from "../../../../../../common/domain/valueObjects/SelectionLook";

interface Props {
  setting: TemplateSetting;
  form: FormInstance;
}

const selectionTypeOptions = Object.entries(SelectionType).map(
  ([label, value]) => ({ label, value })
);
const selectionLookOptions = Object.entries(SelectionLook).map(
  ([label, value]) => ({ label, value })
);

export const SelectionInputSetting: React.FC<Props> = ({
  setting,
  form
}): JSX.Element => {
  const defaultValueOptions =
    setting.options?.map((o) => ({
      label:
        o.displayValues.find((x) => x.locale === commonConstants.defaultLocale)
          ?.value ?? o.key,
      value: o.key
    })) ?? [];

  return (
    <Row className="selection-input-setting">
      <Col xl={8} lg={12}>
        <span className="label">{t(tMap["category.settings.inputType"])}</span>
        <Tag>{setting.inputType}</Tag>
      </Col>
      <Col xl={8} lg={12}>
        <span className="label">
          {t(tMap["category.settings.selectionType"])}
        </span>
        <SelectionFormItem
          form={form}
          formItemName={[setting.name, "inputSettings", "selectionType"]}
          value={setting.inputSettings?.selectionType}
          options={selectionTypeOptions}
        />
      </Col>
      <Col lg={24} xl={8}>
        <span className="label">{t(tMap["category.settings.dataType"])}</span>
        <Tag>{setting.dataType}</Tag>
      </Col>
      <Col lg={24}>
        <span className="label">
          {t(tMap["category.settings.defaultValue"])}
        </span>
        <SelectionFormItem
          form={form}
          formItemName={[setting.name, "default"]}
          value={setting.default}
          options={defaultValueOptions}
          isMultiple={setting.isMultipleValues}
        />
      </Col>
    </Row>
  );
};

export default SelectionInputSetting;
